import moduleStyle from './style/index.module.scss'
import { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Input, Radio, InputNumber } from 'antd'
import PidTree from './pidTree'
import { menuAdd, menuUpdate } from '@/api/list/system'
function Index(props) {
  const [form] = Form.useForm()
  const [isFrame, setisFrame] = useState('0')
  const [type, setType] = useState('0')
  const [pid, setPid] = useState('')
  useEffect(() => {
    setType('0')
    setisFrame('0')
    setPid('')
    if (props.initialValues) {
      let initialValues = JSON.parse(JSON.stringify(props.initialValues))
      setType(String(initialValues['type']))
      setisFrame(String(initialValues['isFrame']))
      setPid(initialValues['pid'] ? initialValues['pid'] : '')
      //转成string类型
      let intValues = ['type', 'pid', 'menuSort', 'isFrame', 'cache', 'hidden']
      for (let i in initialValues) {
        if (intValues.includes(i)) {
          initialValues[i] = String(initialValues[i])
        }
      }

      if (+initialValues.type === 2) {
        initialValues.btnName = initialValues.title
        initialValues.title = ''
      }

      form.setFieldsValue({ ...initialValues })
    } else {
      form.setFieldsValue({
        type: '0',
        isFrame: '0',
        hidden: '0',
        pid: '',
        title: '',
        name: '',
        component: '',
        menuSort: 999,
        path: '',
        cache: 0,
        permission: ''
      })
    }
  }, [form, props.visble, props.initialValues])
  const handleCancel = () => {
    form.resetFields()
    props.setVisble(false)
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        let params = values
        //转成int类型
        let intValues = [
          'type',
          'pid',
          'menuSort',
          'isFrame',
          'cache',
          'hidden'
        ]
        for (let i in params) {
          if (intValues.includes(i)) {
            params[i] = Number(params[i])
          }
        }
        if (params.btnName) {
          params.title = params.btnName
          delete params.btnName
        }

        params.pid = pid
        let submitFun = null
        if (props.initialValues) {
          submitFun = menuUpdate
          params.id = props.initialValues.id
        } else {
          submitFun = menuAdd
        }
        submitFun(params).then((res) => {
          if (+res.code === 0) {
            props.setVisble(false)
            props.onFinish()
          }
        })
      })
      .catch(() => {})
  }

  const isFrameChange = (e) => {
    setisFrame(e.target.value)
  }

  const typeChange = (e) => {
    setType(e.target.value)
  }

  return (
    <Modal
      width={800}
      forceRender
      title={props.initialValues ? '编辑菜单' : '新增菜单'}
      cancelText="取消"
      okText="确认"
      visible={props.visble}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <div className={moduleStyle.edit}>
        <Form layout={'horizontal'} form={form}>
          <Row>
            <Col span={12}>
              <Form.Item label="菜单类型" name="type" labelCol={{ span: 6 }}>
                <Radio.Group onChange={typeChange}>
                  <Radio.Button value="0">目录</Radio.Button>
                  <Radio.Button value="1">菜单</Radio.Button>
                  <Radio.Button value="2">按钮</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {type !== '2' ? (
            <>
              <Row justify={'space-between'}>
                <Col span={12}>
                  <Form.Item
                    label="外链菜单"
                    name="isFrame"
                    labelCol={{ span: 6 }}
                  >
                    <Radio.Group onChange={isFrameChange}>
                      <Radio.Button value="1">是</Radio.Button>
                      <Radio.Button value="0">否</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="菜单可见"
                    name="hidden"
                    labelCol={{ span: 6 }}
                  >
                    <Radio.Group>
                      <Radio.Button value="0">是</Radio.Button>
                      <Radio.Button value="1">否</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={'space-between'}>
                <Col span={12}>
                  <Form.Item
                    label="菜单标题"
                    name="title"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: '请输入菜单标题'
                      }
                    ]}
                  >
                    <Input placeholder="菜单标题" />
                  </Form.Item>
                </Col>
                {type === '1' && (
                  <Col span={12}>
                    <Form.Item
                      label="权限标识"
                      name="permission"
                      labelCol={{ span: 6 }}
                    >
                      <Input placeholder="权限标识" />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row justify={'space-between'}>
                <Col span={12}>
                  <Form.Item
                    label="路由地址"
                    name="path"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: '请输入路由地址'
                      }
                    ]}
                  >
                    <Input placeholder="路由地址" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="菜单排序"
                    name="menuSort"
                    labelCol={{ span: 6 }}
                  >
                    <InputNumber min={0} max={999} />
                  </Form.Item>
                </Col>
              </Row>

              {isFrame !== '1' && type === '1' && (
                <Row justify={'space-between'}>
                  <Col span={12}>
                    <Form.Item
                      label="组件名称"
                      name="name"
                      labelCol={{ span: 6 }}
                    >
                      <Input placeholder="组件名称" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="组件路径"
                      name="component"
                      labelCol={{ span: 6 }}
                    >
                      <Input placeholder="组件路径" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <Row justify={'space-between'}>
                <Col span={12}>
                  <Form.Item
                    label="按钮名称"
                    name="btnName"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: '请输入按钮名称'
                      }
                    ]}
                  >
                    <Input placeholder="按钮名称" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="权限标识"
                    name="permission"
                    labelCol={{ span: 6 }}
                  >
                    <Input placeholder="权限标识" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Form.Item label="上级目录" labelCol={{ span: 3 }}>
            <PidTree pid={pid} setPid={setPid} visble={props.visble} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
export default Index
