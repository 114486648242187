import { useEffect } from 'react'
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom'

import moduleStyle from './style/index.module.scss'
import ServiceData from './component/serviceData'
import ResourceTrack from './component/resourceTrack'
import AccountTrack from './component/accountTrack'
import Banner from '@/components/banner'

const tabList = [
  {
    name: '查看服务数据',
    active: true,
    component: ServiceData,
    path: '/dashBoard/serviceData',
    icon: require('./img/img_1.png'),
    activeIcon: require('./img/img_active_1.png')
  },
  {
    name: '查看资源追踪',
    active: false,
    component: ResourceTrack,
    path: '/dashBoard/resourceTrack',
    icon: require('./img/img_2.png'),
    activeIcon: require('./img/img_active_2.png')
  },
  {
    name: '查看账号追踪',
    active: false,
    component: AccountTrack,
    path: '/dashBoard/accountTrack',
    icon: require('./img/img_3.png'),
    activeIcon: require('./img/img_active_3.png')
  }
]

function Redirect({ to }) {
  let navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}
const banner = require('./img/banner.png')
function Index() {
  let selectedKeys = useLocation().pathname
  return (
    <div className={moduleStyle.dashBoardWrapper}>
      <div className="head-box">
        <Banner banner={banner} label="DashBoard看板" />
        {/*<div className="center-tit">*/}
        {/*  <div className="title">DashBoard 看板</div>*/}
        {/*</div>*/}
        <div className="tab-box">
          <div className="tab-box-center">
            {tabList.map((item, index) => (
              <Link to={item.path} key={index}>
                <div
                  key={item.name}
                  className={`link-item ${
                    item.path === selectedKeys ? 'active' : ''
                  }`}
                >
                  <img
                    src={
                      item.path === selectedKeys ? item.activeIcon : item.icon
                    }
                    alt=""
                  />
                  <span className="itemName">{item.name}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="routes-wrapper">
        <Routes>
          <Route path="*" element={<Redirect to="/dashBoard/serviceData" />} />
          {tabList.map((item, index) => (
            <Route key={index} path={item.path} element={<item.component />} />
          ))}
        </Routes>
      </div>
    </div>
  )
}

export default Index
