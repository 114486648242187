import Count from './compenent/count'
import style from './index.module.scss'
function Index() {
  return (
    <div>
      <div className={style.cdhWrapper}></div>
      <Count />
    </div>
  )
}
export default Index
