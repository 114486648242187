import DocHtml from '@/pages/doc/docHtml'
import DocFeishu from '@/pages/doc/components/docContainer/docElement.jsx'
const resourceCollectionDoc = [
  {
    path: '/development-platform/api/doc-html/:id',
    name: '关键词搜索视频采集异步接口',
    component: DocHtml,
    layout: 'AsideLayout',
    dynamic: true
  },
  {
    path: '/development-platform/api/docFS/:id',
    name: '关键词搜索视频采集异步接口',
    component: DocFeishu,
    layout: 'AsideLayout',
    dynamic: true
  }
]

export default resourceCollectionDoc
