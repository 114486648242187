import moduleStyle from './style/index.module.scss'
import { useState /* useEffect */ } from 'react'
import { Input, Button, Timeline } from 'antd'
import { getResourceTrace } from '@/api/list/resourceTrace'
import { timeToMillisecond } from '@/untils/time'

import publish from './component/publish'
import update from './component/update'
import download from './component/download'
import trans from './component/trans'
import comment from './component/comment'

//初始化时间轴数据
const initList = (data) => {
  let arr = [
    {
      timeTitle: '视频发布阶段',
      time: data.collect.publishTime,
      dot: require('./img/pub.png'),
      component: publish,
      data: data
    },
    {
      timeTitle: '爬虫采集入库',
      time: data.collect.updateTime,
      dot: require('./img/update.png'),
      component: update,
      data: data
    },
    {
      timeTitle: '视频下载',
      time: data.download.dlEndTime,
      dot: require('./img/download.png'),
      component: download,
      data: data
    },
    {
      timeTitle: '视频转码',
      time: data.trans.tsTime,
      dot: require('./img/trans.png'),
      component: trans,
      data: data
    }
  ]

  const comments = data.collect.comments || []
  // const comments = [
  //   {
  //     videoId: "89",
  //     siteName: "浩轩.田",
  //     crawlTime: "2022-01-20 10:27:44",
  //     dcSiteId: 13,
  //     cid: "89",
  //     commentAuthor: {
  //       authorId: "89",
  //       authorName: "浩轩.田",
  //       avatarUrl: "www.xn---xn--wou-lm2sw8m.org",
  //     },
  //     readNum: 259,
  //     repeatNum: 69,
  //     likeNum: 314,
  //     publishTime: "2022-01-20 10:27:44",
  //     awemeId: "89",
  //     content: "i5jvcr",
  //     emotion: 9,
  //   },
  // ];

  if (comments) {
    let commentList = comments.map((item) => {
      return {
        timeTitle: '评论互动',
        time: item.publishTime,
        dot: require('./img/comment.png'),
        component: comment,
        data: item
      }
    })

    const newArr = [...arr, ...commentList]
    newArr.sort((a, b) => {
      return timeToMillisecond(a.time) - timeToMillisecond(b.time)
    })
    arr = newArr
  }

  const filterArr = arr.filter((item) => {
    return item.time
  })

  return filterArr
}

//换行展示时间
const brTime = (t) => {
  if (t) {
    let arr = t.split(' ')
    return (
      <div style={{ color: '#919099', marginRight: '25px' }}>
        {arr[0]}
        <br />
        {arr[1]}
      </div>
    )
  }
}

function Index() {
  const [keyWord, setKeyWord] = useState('') //  10013-335303402-275329637
  const [list, setList] = useState([])

  const getList = () => {
    if (keyWord) {
      getResourceTrace(keyWord).then((res) => {
        if (res.data) {
          setList(initList(res.data))
        } else {
          setList([])
        }
      })
    } else {
      setList([])
    }
  }

  return (
    <div className={moduleStyle.resourceTracking}>
      <div className="search-head">
        <Input
          value={keyWord}
          placeholder="搜索资源ID"
          onChange={(e) => {
            setKeyWord(e.target.value)
          }}
          onPressEnter={getList}
        />
        <Button type="primary" onClick={getList}>
          搜索
        </Button>
      </div>

      <div className="time-line-box">
        <Timeline mode="left">
          {list.map((item, index) => (
            <Timeline.Item
              label={brTime(item.time)}
              key={index}
              dot={<img src={item.dot} alt="" />}
            >
              <item.component data={item.data} />
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  )
}
export default Index
