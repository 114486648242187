import baseStyle from './base.module.scss'
import style from './textBlockTemplate.module.scss'
import codeBlockStyle from './codeBlock.module.scss'
import Highlight from 'react-highlight'
import 'highlight.js/styles/atom-one-light.css'
import { MAP_CODE_NUMBER_TO_LANGUAGE } from '../constant'

export default function CodeBlock(props) {
  const { blockData } = props
  const blockClassName = () => {
    return [
      style[`${blockData.block_type_name}`],
      style[`docx-${blockData.block_type_name}-block`],
      codeBlockStyle['code-block']
    ].join(' ')
  }

  const textClassName = (style) => {
    if (!style) return ''
    const styleArray = []
    if (style.background_color) {
      styleArray.push(baseStyle[`background-color-${style.background_color}`])
    }
    if (style.text_color) {
      styleArray.push(baseStyle[`text-color-${style.text_color}`])
    }
    if (style.strikethrough) {
      styleArray.push(baseStyle['text-strikethrough'])
    }
    if (style.italic) {
      styleArray.push(baseStyle['text-italic'])
    }
    if (style.underline) {
      styleArray.push(baseStyle[`text-underline`])
    }
    if (style.inline_code) {
      styleArray.push(baseStyle[`text-inline-code`])
    }
    if (style.link) {
      styleArray.push(baseStyle[`text-link`])
    }
    return styleArray.join(' ')
  }

  const highlightClassName = () => {
    let langCode = 'language-javascript'

    const styleArray = []
    if (
      MAP_CODE_NUMBER_TO_LANGUAGE.has(
        blockData[blockData.block_type_name].style.language
      )
    ) {
      langCode = `language-${MAP_CODE_NUMBER_TO_LANGUAGE.get(
        blockData[blockData.block_type_name].style.language
      )}`
    }
    styleArray.push(langCode)

    return styleArray.join(' ')
  }

  // 文本点击事件
  const handleTextClick = (element) => {
    // 判断是否为link
    if (element?.text_run?.text_element_style.link) {
      window.open(
        decodeURIComponent(element?.text_run?.text_element_style.link.url)
      )
    }
  }
  // 文本渲染
  const elements = blockData[blockData.block_type_name].elements
  const text = elements.map((element, index) => {
    return (
      <span
        key={index}
        className={textClassName(element?.text_run?.text_element_style)}
        onClick={() => handleTextClick(element)}
      >
        {element?.text_run?.content}
      </span>
    )
  })

  const textBlock = (
    <div
      id={blockData.block_id}
      className={blockClassName()}
      data-block-type={blockData.block_type_name}
      data-block-id={blockData.block_type}
      data-record-id={blockData.block_id}
      key={blockData.block_id}
    >
      <Highlight className={highlightClassName()}>{text}</Highlight>
    </div>
  )

  return textBlock
}
