import { useState } from 'react'
import moduleStyle from './index.module.scss'

//发布平台
const initPlatform = (val, name) => {
  let platform = [
    {
      value: '10000',
      label: '抖音',
      url: require('./img/douyin.png')
    },
    {
      value: '10025',
      label: '快手',
      url: require('./img/kuaishou.png')
    },
    {
      value: '10001',
      label: '西瓜',
      url: require('./img/xigua.png')
    },
    {
      value: '10103',
      label: '哔哩哔哩',
      url: require('./img/bilibili.png')
    },
    {
      value: '10017',
      label: '微博',
      url: require('./img/weibo.png')
    },
    {
      value: '10010',
      label: '腾讯',
      url: require('./img/tengxun.png')
    },
    {
      value: '10012',
      label: '好看',
      url: require('./img/haokan.png')
    },
    {
      value: '10011',
      label: '优酷',
      url: require('./img/youku.png')
    },
    {
      value: '10014',
      label: '土豆',
      url: require('./img/tudou.png')
    },
    {
      value: '10020',
      label: '秒拍',
      url: require('./img/miaopai.png')
    },
    {
      value: '10013',
      label: '搜狐',
      url: require('./img/souhu.png')
    },
    {
      value: '10015',
      label: '新浪',
      url: require('./img/xinlang.png')
    },
    {
      value: '10024',
      label: '头条',
      url: require('./img/toutiao.png')
    },
    {
      value: '10016',
      label: '今日十大',
      url: require('./img/jinrishida.png')
    },
    {
      value: '10018',
      label: '爆米花',
      url: require('./img/baomihua.png')
    },
    {
      value: '10019',
      label: '凤凰网',
      url: require('./img/fenghuang.png')
    },
    {
      value: '10022',
      label: '网易视频',
      url: require('./img/wangyi.png')
    },
    {
      value: '10023',
      label: '央视网',
      url: require('./img/yangshi.png')
    },
    {
      value: '10100',
      label: 'YouTube',
      url: require('./img/youtube.png')
    },
    {
      value: '10026',
      label: 'Facebook',
      url: require('./img/facebook.png')
    },
    {
      value: '10027',
      label: 'Twitter',
      url: require('./img/twitter.png')
    }
  ]
  const activeObj = platform.find((item) => {
    return item.value === String(val)
  })

  return <img src={activeObj ? activeObj.url : ''} alt={name} />
}

function Index(props) {
  const [data] = useState(props.data)

  return (
    <div className={moduleStyle.publish}>
      <div className="title">视频发布阶段</div>
      <a href={data.collect.webUrl} target="_blank" rel="noopener noreferrer">
        <div className="link-box">
          {initPlatform(data.collect.dcSiteId, data.collect.siteName)}
          <span>{data.collect.title}</span>
        </div>
      </a>
    </div>
  )
}
export default Index
