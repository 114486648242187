import Block from '../block'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'

export default function TableCellBlock(props) {
  const { blockData } = props

  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)
  const children = blockData.children.map((childId, index) => {
    return (
      <div key={childId}>
        <Block key={childId} blockData={blockItemMap[childId]}></Block>
      </div>
    )
  })
  return children
}
