let list = {
  read: require('./img/read.png'),
  repeat: require('./img/repeat.png'),
  like: require('./img/like.png'),
  comment: require('./img/comment.png')
}

const initNumber = (num) => {
  return num > 9999 ? (num / 10000).toFixed(1) + '万' : num | 0
}

function Index(props) {
  let url = list[props.type]
  return (
    <>
      <img src={url} alt="" />
      <span className="total" style={{ marginLeft: '6px' }}>
        {initNumber(props.total)}
      </span>
    </>
  )
}

export default Index
