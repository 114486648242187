let jsonFile = require('./untitled.json')
jsonFile = JSON.parse(JSON.stringify(jsonFile))
let jsonObj = {
  '': '未命中',
  importVideo: '导入视频数据',
  keyMonitorAccount: '重点监控账号',
  event: '事件数据',
  keyword: '关键字',
  coverPreferred: '封面优先',
  cover: '封面普通',
  authorClassified: '作者分级',
  discard: '不下载'
}
jsonFile.RECORDS.forEach((item) => {
  jsonObj[item.id] = item.name
})

export default function manufactureMap() {
  return jsonObj
}
