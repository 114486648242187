import http from './../axios'

/**
 * 获取资源【采集】统计
 */
export const getResourceTrace = (id, commentSize = 10) => {
  return http().get(
    `/media_service/resource/resourceTracingAnalysis?dcResourceId=${id}&commentSize=${commentSize}`
  )
}
