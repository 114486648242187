import axios from 'axios'

/**
 * @name initPlatform
 * @desc 获取平台图片
 * @param {string} val 平台id
 * @param {string} name 平台名称
 * @param {string} className 图片类名
 */
export const initPlatform = (val, name, className) => {
  let platform = [
    {
      value: '10000',
      label: '抖音',
      url: require('@/assets/img/platform/douyin.png')
    },
    {
      value: '10025',
      label: '快手',
      url: require('@/assets/img/platform/kuaishou.png')
    },
    {
      value: '10001',
      label: '西瓜',
      url: require('@/assets/img/platform/xigua.png')
    },
    {
      value: '10103',
      label: '哔哩哔哩',
      url: require('@/assets/img/platform/bilibili.png')
    },
    {
      value: '10017',
      label: '微博',
      url: require('@/assets/img/platform/weibo.png')
    },
    {
      value: '10010',
      label: '腾讯',
      url: require('@/assets/img/platform/tengxun.png')
    },
    {
      value: '10012',
      label: '好看',
      url: require('@/assets/img/platform/haokan.png')
    },
    {
      value: '10011',
      label: '优酷',
      url: require('@/assets/img/platform/youku.png')
    },
    {
      value: '10014',
      label: '土豆',
      url: require('@/assets/img/platform/tudou.png')
    },
    {
      value: '10020',
      label: '秒拍',
      url: require('@/assets/img/platform/miaopai.png')
    },
    {
      value: '10013',
      label: '搜狐',
      url: require('@/assets/img/platform/souhu.png')
    },
    {
      value: '10015',
      label: '新浪',
      url: require('@/assets/img/platform/xinlang.png')
    },
    {
      value: '10024',
      label: '头条',
      url: require('@/assets/img/platform/toutiao.png')
    },
    {
      value: '10016',
      label: '今日十大',
      url: require('@/assets/img/platform/jinrishida.png')
    },
    {
      value: '10018',
      label: '爆米花',
      url: require('@/assets/img/platform/baomihua.png')
    },
    {
      value: '10019',
      label: '凤凰网',
      url: require('@/assets/img/platform/fenghuang.png')
    },
    {
      value: '10022',
      label: '网易视频',
      url: require('@/assets/img/platform/wangyi.png')
    },
    {
      value: '10023',
      label: '央视网',
      url: require('@/assets/img/platform/yangshi.png')
    },
    {
      value: '10100',
      label: 'YouTube',
      url: require('@/assets/img/platform/youtube.png')
    },
    {
      value: '10026',
      label: 'Facebook',
      url: require('@/assets/img/platform/facebook.png')
    },
    {
      value: '10027',
      label: 'Twitter',
      url: require('@/assets/img/platform/twitter.png')
    }
  ]
  const activeObj = platform.find((item) => {
    return item.value === String(val)
  })

  return (
    <img
      className={className}
      src={activeObj ? activeObj.url : ''}
      alt={name}
    />
  )
}

/**
 * @name copyFormattedText
 * @desc 内容复制
 * @param {string} text
 */
export const copyFormattedText = (text) => {
  const dummyElement = document.createElement('textarea')
  dummyElement.value = text
  document.body.appendChild(dummyElement)
  dummyElement.select()
  document.execCommand('copy')
  document.body.removeChild(dummyElement)
}

/**
 * @name downloadVideo
 * @desc 文件下载
 * @param {string} url 文件路径
 * @param {string} filename 文件名称
 */
export const downloadVideo = (url, filename) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      })

      // 创建下载链接
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename

      // 触发下载
      downloadLink.click()

      // 释放创建的 URL 对象
      URL.revokeObjectURL(downloadLink.href)
    })
    .catch((error) => {
      console.error('Error downloading video:', error)
    })
}
