import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import "antd/dist/antd.less"; // 引入antd官方提供的 less 样式入口文件
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import RouteView from './router'
import { RecoilRoot } from 'recoil'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
// import theme from './theme'
function App() {
  return (
    <RecoilRoot>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#DA2428'
          }
        }}
      >
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<RouteView />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ConfigProvider>
    </RecoilRoot>
  )
}

export default App
