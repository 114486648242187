import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import moduleStyle from './style/index.module.scss'
import { Button, Table, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { getBuildAll } from '@/api/list/system'

import { menuDel } from '@/api/list/system'
const App = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getList,
    columns
  }))
  const edit = (record) => {
    props.onEdit(record)
  }

  const delConfirm = (record) => {
    menuDel({ ids: [record.id] }).then((res) => {
      if (+res.code === 0) {
        getList()
      }
    })
  }

  const columns = [
    {
      title: '菜单标题',
      dataIndex: 'title'
    },
    {
      title: '排序',
      dataIndex: 'menuSort'
    },
    {
      title: '权限标识',
      dataIndex: 'permission'
    },
    {
      title: '组件路径',
      dataIndex: 'component'
    },
    {
      title: '外链',
      dataIndex: 'isFrame',
      render: (val) => {
        return +val === 1 ? '是' : '否'
      }
    },
    {
      title: '可见',
      dataIndex: 'hidden',
      render: (val) => {
        return +val === 1 ? '否' : '是'
      }
    },
    {
      title: '创建日期',
      dataIndex: 'createTime'
    },
    {
      title: '操作',
      dataIndex: 'noDataIndex',
      align: 'center',
      render: (val, record) => {
        return (
          <>
            <Button onClick={() => edit(record)}>
              <EditOutlined />
            </Button>
            <span className="fn-ml4">
              <Popconfirm
                arrowPointAtCenter
                placement="topRight"
                title={() => (
                  <div className="del-popconfirm">
                    确定删除吗,如果存在下级节点则一并删除，此操作不能撤销!
                  </div>
                )}
                onConfirm={() => delConfirm(record)}
                okText="确定"
                cancelText="取消"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </span>
          </>
        )
      }
    }
  ]

  const [list, setList] = useState([])
  const getList = () => {
    getBuildAll().then((res) => {
      if (res.code === 0) {
        setList(res.data)
      }
    })
  }
  useEffect(() => {
    getList()
  }, [])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setRowSelection(selectedRows)
    }
  }

  return (
    <div className={moduleStyle.tableWrap}>
      <Table
        rowKey="id"
        columns={columns}
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        dataSource={list}
        pagination={false}
      />
    </div>
  )
})

export default App
