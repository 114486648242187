import moduleStyle from './index.module.scss'
import { Input, Select, message, Tooltip, InputNumber } from 'antd'
import { ReloadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import sourceVerifyStore from '@/store/sourceVerify'
import { cloneDeep } from 'lodash'
import cn from 'classnames'
import { checkExpression } from '@/api/list/tagVerify'
import { useState } from 'react'

function FilterWord() {
  const { copyData, setCopyData } = sourceVerifyStore()
  const [validateExpression, setValidateExpression] = useState({
    allExcluExpr: null,
    ambiguousExpr: null
  })

  const changeMatchLocation = (value, field) => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo[field].matchLocation = value
    setCopyData(snapTagInfo)
  }

  const changeOrder = (value, field) => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo[field].inOrder = value
    setCopyData(snapTagInfo)
  }

  const changeWord = (e, field) => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo[field].expr = e.target.value
    setCopyData(snapTagInfo)
    setValidateExpression((state) => ({ ...state, [field]: null }))
  }

  const validateExpressionFun = (field) => {
    if (!copyData?.[field]?.expr) {
      message.warning('请输入表达式')
      return
    }
    checkExpression({ text: copyData[field].expr }).then((res) => {
      if (res.data.isValid === 1) {
        setValidateExpression((state) => ({ ...state, [field]: true }))
      } else {
        setValidateExpression((state) => ({ ...state, [field]: false }))
      }
    })
  }

  const changeGap = (value, field) => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo[field].matchGap = value
    setCopyData(snapTagInfo)
  }

  return (
    <div className={moduleStyle.filterWrap}>
      <div className={moduleStyle.title}>整体过滤</div>
      <div>
        <div className={moduleStyle.blockTitle}>
          <div>
            排除词
            <span className={moduleStyle.questionTip}>
              <Tooltip
                placement="top"
                title="逻辑表达式支持”and“、”or“、”（）“ 例如：（北京 and 天津）or （上海 and 重庆）"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          </div>
          <div className={moduleStyle.filterArea}>
            <div className={moduleStyle.filterItem}>
              <div className={moduleStyle.filterTitle}>匹配位置</div>
              <Select
                style={{ width: 100 }}
                defaultValue={2}
                value={copyData?.allExcluExpr?.matchLocation}
                options={[
                  { value: 2, label: '整句' },
                  { value: 1, label: '半句' },
                  { value: 0, label: '紧挨' },
                  { value: 3, label: '段落' },
                  { value: 4, label: '不限' },
                  { value: 5, label: '自定义' }
                ]}
                onChange={(value) => changeMatchLocation(value, 'allExcluExpr')}
              />
              {copyData?.allExcluExpr?.matchLocation === 5 ? (
                <div className={moduleStyle.filterItem}>
                  <InputNumber
                    value={copyData?.allExcluExpr?.matchGap}
                    min={1}
                    placeholder="自定义距离"
                    onChange={(value) => changeGap(value, 'allExcluExpr')}
                  />
                </div>
              ) : null}
            </div>
            <div className={moduleStyle.filterItem}>
              <div className={moduleStyle.filterTitle}>匹配顺序</div>
              <Select
                defaultValue={1}
                options={[
                  { value: 1, label: '有序' },
                  { value: 0, label: '无序' }
                ]}
                value={copyData?.allExcluExpr?.inOrder}
                onChange={(value) => changeOrder(value, 'allExcluExpr')}
              />
            </div>
          </div>
        </div>
        <div className={moduleStyle.inputArea}>
          <Input.TextArea
            rows={5}
            placeholder="请输入排除词"
            value={copyData?.allExcluExpr?.expr}
            onChange={(e) => changeWord(e, 'allExcluExpr')}
          ></Input.TextArea>
          {validateExpression.allExcluExpr === null ? (
            <div
              onClick={() => validateExpressionFun('allExcluExpr')}
              className={moduleStyle.validateExpression}
            >
              验证表达式
            </div>
          ) : null}
          {validateExpression.allExcluExpr === true ? (
            <div
              onClick={() => validateExpressionFun('allExcluExpr')}
              className={cn(
                moduleStyle.validateExpression,
                moduleStyle.correct
              )}
            >
              未发现错误
              <ReloadOutlined />
            </div>
          ) : null}
          {validateExpression.allExcluExpr === false ? (
            <div
              onClick={() => validateExpressionFun('allExcluExpr')}
              className={cn(moduleStyle.validateExpression, moduleStyle.wrong)}
            >
              表达式错误
              <ReloadOutlined />
            </div>
          ) : null}
        </div>
      </div>

      <div>
        <div className={moduleStyle.blockTitle}>
          <div>
            歧义词
            <span className={moduleStyle.questionTip}>
              <Tooltip
                placement="top"
                title="逻辑表达式支持”and“、”or“、”（）“ 例如：（北京 and 天津）or （上海 and 重庆）"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          </div>
          <div className={moduleStyle.filterArea}>
            <div className={moduleStyle.filterItem}>
              <div className={moduleStyle.filterTitle}>匹配位置</div>
              <Select
                defaultValue={2}
                style={{ width: 100 }}
                value={copyData?.ambiguousExpr?.matchLocation}
                options={[
                  { value: 2, label: '整句' },
                  { value: 1, label: '半句' },
                  { value: 0, label: '紧挨' },
                  { value: 3, label: '段落' },
                  { value: 4, label: '不限' },
                  { value: 5, label: '自定义' }
                ]}
                onChange={(value) =>
                  changeMatchLocation(value, 'ambiguousExpr')
                }
              />
              {copyData?.ambiguousExpr?.matchLocation === 5 ? (
                <div className={moduleStyle.filterItem}>
                  <InputNumber
                    value={copyData?.ambiguousExpr?.matchGap}
                    min={1}
                    placeholder="自定义距离"
                    onChange={(value) => changeGap(value, 'ambiguousExpr')}
                  />
                </div>
              ) : null}
            </div>
            <div className={moduleStyle.filterItem}>
              <div className={moduleStyle.filterTitle}>匹配顺序</div>
              <Select
                defaultValue={1}
                options={[
                  { value: 1, label: '有序' },
                  { value: 0, label: '无序' }
                ]}
                value={copyData?.ambiguousExpr?.inOrder}
                onChange={(value) => changeOrder(value, 'ambiguousExpr')}
              />
            </div>
          </div>
        </div>
        <div className={moduleStyle.inputArea}>
          <Input.TextArea
            rows={5}
            placeholder="请输入歧义词"
            value={copyData?.ambiguousExpr?.expr}
            onChange={(e) => changeWord(e, 'ambiguousExpr')}
          ></Input.TextArea>
          {validateExpression.ambiguousExpr === null ? (
            <div
              onClick={() => validateExpressionFun('ambiguousExpr')}
              className={moduleStyle.validateExpression}
            >
              验证表达式
            </div>
          ) : null}
          {validateExpression.ambiguousExpr === true ? (
            <div
              onClick={() => validateExpressionFun('ambiguousExpr')}
              className={cn(
                moduleStyle.validateExpression,
                moduleStyle.correct
              )}
            >
              未发现错误
              <ReloadOutlined />
            </div>
          ) : null}
          {validateExpression.ambiguousExpr === false ? (
            <div
              onClick={() => validateExpressionFun('ambiguousExpr')}
              className={cn(moduleStyle.validateExpression, moduleStyle.wrong)}
            >
              表达式错误
              <ReloadOutlined />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FilterWord
