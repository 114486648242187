import styles from './index.module.scss'

function Index() {
  return (
    <div className={styles.wrapperBox}>
      <div className="link-item-box link-item-left">
        <div>
          <div className="link-name">大数据</div>
          <div className="link-btn">查看详情 ➟</div>
        </div>
        {/*<div>*/}
        {/*  <img src={item.ico} alt="" />*/}
        {/*</div>*/}
      </div>
      <div className="link-item-box  link-item-right">
        <div>
          <div className="link-name">监控预警</div>
          <div className="link-btn">查看详情 ➟</div>
        </div>
        {/*<div>*/}
        {/*  <img src={item.ico} alt="" />*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Index
