import moduleStyle from './style/index.module.scss'

function index() {
  return (
    <div className={moduleStyle.wrapperBox}>
      <div className="info-box">
        Copyright 2024 people-AI Rights Reserved 人民中科智能技术有限公司
        <br />
        白泽跨模态大模型算法 网信算备110115923323001240019号
      </div>
    </div>
  )
}

export default index
