import blockStyle from './quoteContainerBlock.module.scss'
import Block from '../block'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'

export default function QuoteBlock(props) {
  const { blockData } = props

  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)
  const children = blockData.children.map((childId, index) => {
    return <Block key={childId} blockData={blockItemMap[childId]}></Block>
  })
  return <div className={blockStyle.quoteContainer}>{children}</div>
}
