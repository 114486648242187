import moduleStyle from './style/index.module.scss'
import { Tree } from 'antd'
import { useState, useEffect } from 'react'
import { getBuildAll } from '@/api/list/system'
const deepArr = (list) => {
  const deepFun = (arr) => {
    arr.forEach((item) => {
      item.key = item.id ? item.id : 'key' + Math.random()
      if (item.children) {
        deepFun(item.children)
      }
    })
  }

  deepFun(list)
  return list
}

//父级控制子级
const father_control_children = (checkedKeys, e) => {
  const keys = []
  const deepFun = (arr) => {
    arr.forEach((item) => {
      keys.push(item.key)
      if (item.children) {
        deepFun(item.children)
      }
    })
  }
  deepFun(e.node.children || [])
  if (e.checked) {
    return [...new Set([...checkedKeys, ...keys])]
  } else {
    return checkedKeys.filter((item) => {
      return !keys.includes(item)
    })
  }
}

const App = (props) => {
  const [treeData, setTreeData] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  //刷新上级树
  useEffect(() => {
    if (props.visble) {
      getBuildAll().then((res) => {
        const resData = deepArr(res.data)
        setTreeData(resData)
        setCheckedKeys(props.menuIds)
      })
    }
  }, [props.visble, props.menuIds])

  const onCheck = (val, e) => {
    const keys = father_control_children(val.checked, e)
    setCheckedKeys(keys)
    props.setMenuIds(keys)
  }

  return (
    <div className={moduleStyle.menuTreeWrap}>
      {treeData.length > 0 && (
        <Tree
          defaultExpandAll
          checkStrictly
          checkable
          checkedKeys={checkedKeys}
          onCheck={onCheck}
          treeData={treeData}
        />
      )}
    </div>
  )
}

export default App
