import moduleStyle from './index.module.scss'
import { useLocation } from 'react-router-dom'
import {
  Tree,
  Dropdown,
  Input,
  Button,
  Select,
  Modal,
  message,
  Popconfirm,
  Spin,
  Tooltip
} from 'antd'
import { useState, useEffect } from 'react'
import {
  SearchOutlined,
  PlusCircleOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons'
import {
  getLableList,
  getLableInfo,
  addLabel,
  updateLabel,
  addLabelClassify,
  deleteLabelClassify,
  updateLabelClassify,
  deleteLabel,
  closeLabel,
  openLabel,
  getSysList,
  getCompileRiskInfo,
  targetSysUpdate
} from '@/api/list/riskTag'
import riskTagStore from '@/store/riskTag'
import { cloneDeep, isEmpty } from 'lodash'
import { generateRandomString } from '@/untils'
import cn from 'classnames'
import Group from './component/group'
import FilterWord from './component/filterWord'
import butttonStore from '@/store/buttonPermission'

function findParentNames(data, name, type, id) {
  let result = []

  function traverse(item, parentNames) {
    const currentNames = [...parentNames, item.name]

    if (item.name === name && item.type === type && item.labelId === id) {
      result = currentNames
      return true
    }

    if (item.subs && item.subs.length > 0) {
      for (const subItem of item.subs) {
        if (traverse(subItem, currentNames)) {
          return true
        }
      }
    }

    return false
  }

  for (const item of data) {
    if (traverse(item, [])) {
      break
    }
  }

  return result
}

function addCustomObjectById(arr, id, customObject) {
  const result = arr.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        subs: [...item.subs, customObject]
      }
    }
    if (item.subs && item.subs.length > 0) {
      return {
        ...item,
        subs: addCustomObjectById(item.subs, id, customObject)
      }
    }
    return item
  })

  return result
}

function filterDataByStatus(arr, status) {
  return arr.filter((item) => {
    if (item.status !== status) {
      if (item.subs && item.subs.length > 0) {
        item.subs = filterDataByStatus(item.subs, status)
      }
      return true
    }
    return false
  })
}

function RiskTag() {
  const buttonPermissions = butttonStore((state) => state.permissions)
  const [treeData, setTreeData] = useState([])
  const [keyword, setKeyword] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tagClassifyInfo, setTagClassifyInfo] = useState({})
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
  const riskTagState = riskTagStore()
  const groupCollect = riskTagStore((state) => state.groupCollect)
  const tagInfo = riskTagStore((state) => state.tagInfo)
  const groupLen = riskTagStore((state) => state.groupLen)
  const [breadcrumbList, setBreadcrumbList] = useState([])
  const location = useLocation()
  const loading = riskTagStore((state) => state.loading)
  const searchParams = new URLSearchParams(location.search)
  const [sysList, setSysList] = useState([])
  const [modal, contextHolder] = Modal.useModal()
  const fieldNames = {
    title: 'name',
    key: 'id',
    children: 'subs'
  }

  const renderDropdown = ({ type, status, id, rank, topId, name, labelId }) => {
    if (type === 1) {
      let dropdownArr = []
      if (
        buttonPermissions.includes('排除分类增删改查') ||
        buttonPermissions.includes('风险分类增删改查')
      ) {
        dropdownArr = [
          {
            key: '1',
            label: (
              <span onClick={() => addClassify(rank + 1, id, topId)}>
                新增分类
              </span>
            )
          },
          {
            key: '2',
            label: (
              <span onClick={() => addTag(id, rank + 1, topId)}>新增标签</span>
            )
          },
          {
            key: '3',
            label: <span onClick={() => editClassify(id, name)}>编辑分类</span>
          },
          {
            key: '4',
            label: (
              <Popconfirm
                title="删除分类"
                description="确定删除该分类?"
                onConfirm={() => deleteClassify(id)}
              >
                <span>删除分类</span>
              </Popconfirm>
            )
          }
        ]
      }
      return dropdownArr
    } else if (type === 2) {
      const dropdownArr = []
      if (status === 0) {
        if (
          (searchParams.get('type') === '1' &&
            buttonPermissions.includes('风险标签停止/启动')) ||
          (searchParams.get('type') === '2' &&
            buttonPermissions.includes('排除标签停止/启动'))
        ) {
          dropdownArr.push({
            key: '7',
            label: <span onClick={() => openTag(labelId)}>启动标签</span>
          })
        }
      } else {
        if (
          ((searchParams.get('type') === '1' &&
            buttonPermissions.includes('风险标签停止/启动')) ||
            (searchParams.get('type') === '2' &&
              buttonPermissions.includes('排除标签停止/启动'))) &&
          status !== 2
        ) {
          dropdownArr.push({
            key: '8',
            label: <span onClick={() => closeTag(labelId)}>暂停标签</span>
          })
        }
      }
      if (
        (searchParams.get('type') === '1' &&
          buttonPermissions.includes('风险标签删除')) ||
        (searchParams.get('type') === '2' &&
          buttonPermissions.includes('排除标签删除'))
      ) {
        dropdownArr.push({
          key: '6',
          label: (
            <Popconfirm
              title="删除标签"
              description="确定删除该标签?"
              onConfirm={() => deleteTag(labelId)}
            >
              <span>删除标签</span>
            </Popconfirm>
          )
        })
      }
      return dropdownArr
    }
  }

  const resetTreeData = () => {
    return filterDataByStatus(treeData, 9)
  }

  const addTag = (parentId, rank, topId) => {
    const newTree = resetTreeData()
    setTreeData(newTree)
    const snapTreeData = addCustomObjectById(cloneDeep(newTree), parentId, {
      type: 2,
      name: '新建标签',
      rank,
      status: 9,
      id: 'defined',
      labelId: 'defined'
    })
    riskTagState.setTagInfo({
      matchType: searchParams.get('type') * 1,
      targetSystem: [1, 2],
      sysType: 1,
      name: '新建标签',
      rank,
      parentId,
      exprList: [
        {
          expr: null,
          matchLocation: 2,
          inOrder: 1,
          negExprs: [
            {
              key: generateRandomString(),
              negExpr: null,
              negExprMl: 2,
              negExprOrder: 1
            }
          ]
        }
      ],
      opType: 'OR',
      allExcluExpr: {
        expr: null,
        matchLocation: 2,
        inOrder: 1
      },
      ambiguousExpr: {
        expr: null,
        matchLocation: 2,
        inOrder: 1
      },
      topId
    })
    riskTagState.setGroupCollect([{ key: generateRandomString() }])
    setTreeData(snapTreeData)
    setDefaultSelectedKeys(['defined'])
  }

  const addClassify = (rank, parentId, topId) => {
    setTagClassifyInfo({
      rank,
      parentId,
      topId,
      matchType: searchParams.get('type') * 1,
      sysType: 2
    })
    setIsModalOpen(true)
  }

  const editClassify = async (id, name) => {
    setIsModalOpen(true)
    setTagClassifyInfo({ id, name })
  }

  const deleteClassify = async (id) => {
    await deleteLabelClassify(id)
    message.success('删除成功')
    initTagList()
  }

  const deleteTag = async (id) => {
    await deleteLabel(id)
    message.success('删除成功')
    riskTagState.clearTagInfo()
    initTagList()
  }

  const confirmAddClassify = async () => {
    if (tagClassifyInfo.id) {
      await updateLabelClassify(tagClassifyInfo)
      message.success('编辑成功')
    } else {
      await addLabelClassify(tagClassifyInfo)
      message.success('新增成功')
    }
    setTagClassifyInfo({})
    setIsModalOpen(false)
    initTagList()
  }

  const addGroup = () => {
    const snapTagInfo = cloneDeep(tagInfo)
    snapTagInfo.exprList.push({
      expr: null,
      matchLocation: 2,
      inOrder: 1,
      key: generateRandomString(),
      negExprs: [
        {
          key: generateRandomString(),
          negExpr: null,
          negExprMl: 2,
          negExprOrder: 1
        }
      ]
    })
    riskTagState.setTagInfo(snapTagInfo)
    riskTagState.addGroupCollect()
  }

  const getTagDetail = (labelId, id) => {
    if (labelId === 'defined' || !labelId) return
    setTreeData(resetTreeData())
    setDefaultSelectedKeys([id])
    riskTagState.setLoading(true)
    getLableInfo(labelId)
      .then((res) => {
        if (+res.code === 0) {
          const response = cloneDeep(res.data)
          if (!response.exprList.length) {
            response.exprList = [
              {
                expr: null,
                matchLocation: 2,
                inOrder: 1,
                negExprs: [
                  {
                    key: generateRandomString(),
                    negExpr: null,
                    negExprMl: 2,
                    negExprOrder: 1
                  }
                ]
              }
            ]
          }
          if (!response.allExcluExpr) {
            response.allExcluExpr = {
              expr: null,
              matchLocation: 2,
              inOrder: 1
            }
          }
          if (!response.ambiguousExpr) {
            response.ambiguousExpr = {
              expr: null,
              matchLocation: 2,
              inOrder: 1
            }
          }
          response.exprList.forEach((item) => {
            item.key = generateRandomString()
            if (item.negExprs.length) {
              item.negExprs.forEach((subItem) => {
                subItem.key = generateRandomString()
              })
            } else {
              item.negExprs = [
                {
                  key: generateRandomString(),
                  negExpr: null,
                  negExprMl: 2,
                  negExprOrder: 1
                }
              ]
            }
          })
          riskTagState.setTagInfo(response)
          riskTagState.setGroupLen(res.data?.exprList?.length || 1)
          if (res.data?.exprList?.length) {
            riskTagState.setGroupCollect(response.exprList)
          }
        }
        riskTagState.setLoading(false)
      })
      .catch((e) => {
        riskTagState.setLoading(false)
      })
  }

  const confirm = () => {
    const snapTagInfo = cloneDeep(tagInfo)
    let isEmptyExpr = false
    let isEmptyGap = false
    if (!snapTagInfo.name) {
      return message.error('请输入标签名称')
    }
    for (let i = 0; i < snapTagInfo.exprList.length; i++) {
      if (!snapTagInfo.exprList[i].expr) {
        isEmptyExpr = true
        break
      }
      if (
        snapTagInfo.exprList[i].matchLocation === 5 &&
        !snapTagInfo.exprList[i].matchGap
      ) {
        isEmptyGap = true
        break
      }
      if (snapTagInfo.exprList[i]?.negExprs?.length) {
        for (let j = 0; j < snapTagInfo.exprList[i].negExprs.length; j++) {
          if (
            snapTagInfo.exprList[i].negExprs[j].negExprMl === 5 &&
            !snapTagInfo.exprList[i].negExprs[j].matchGap
          ) {
            isEmptyGap = true
            break
          }
        }
      }
    }
    if (
      snapTagInfo.allExcluExpr.matchLocation === 5 &&
      !snapTagInfo.allExcluExpr.matchGap
    ) {
      isEmptyGap = true
    }
    if (
      snapTagInfo.ambiguousExpr.matchLocation === 5 &&
      !snapTagInfo.ambiguousExpr.matchGap
    ) {
      isEmptyGap = true
    }
    if (isEmptyExpr) {
      message.error('请输入关键词表达式')
      return
    }
    if (isEmptyGap) {
      message.error('请输入自定义匹配距离')
      return
    }
    snapTagInfo.exprList.forEach((item) => {
      for (let i = 0; i < item.negExprs.length; i++) {
        if (!item.negExprs[i].negExpr) {
          item.negExprs.splice(i, 1)
          i--
        }
      }
    })
    riskTagState.setLoading(true)
    if (tagInfo.id) {
      updateLabel(snapTagInfo)
        .then((res) => {
          if (res.code === 0) {
            message.success('更新成功')
            initTagList()
          }
          riskTagState.setLoading(false)
        })
        .catch((e) => {
          riskTagState.setLoading(false)
          message.error('更新失败')
        })
    } else {
      addLabel(snapTagInfo)
        .then((res) => {
          if (res.code === 0) {
            message.success('新增成功')
            riskTagState.setTagInfo({ ...snapTagInfo, id: res.data })
            initTagList()
          }
          riskTagState.setLoading(false)
        })
        .catch((e) => {
          riskTagState.setLoading(false)
        })
    }
  }

  const changeRelation = (value) => {
    const snapTagInfo = cloneDeep(tagInfo)
    snapTagInfo.opType = value
    riskTagState.setTagInfo(snapTagInfo)
  }

  const changeSys = (value) => {
    const snapTagInfo = cloneDeep(tagInfo)
    snapTagInfo.targetSystem = value
    riskTagState.setTagInfo(snapTagInfo)
    if (tagInfo.id) {
      targetSysClick(value)
    }
  }

  const initTagList = () => {
    const params = { matchType: searchParams.get('type') }
    if (keyword) {
      params.keyword = keyword
    }
    riskTagState.setLoading(true)
    getLableList(params)
      .then((res) => {
        if (res.data?.length) {
          setTreeData(res.data)
        } else {
          setTreeData([])
        }
        riskTagState.setLoading(false)
      })
      .catch(() => {
        riskTagState.setLoading(false)
      })
  }

  const closeTag = async (id) => {
    await closeLabel(id)
    message.success('暂停成功')
    riskTagState.setTagInfo({ ...tagInfo, status: 0 })
    initTagList()
  }

  const openTag = async (id) => {
    await openLabel(id)
    message.success('开启成功')
    riskTagState.setTagInfo({ ...tagInfo, status: 1 })
    initTagList()
  }

  const changeKeyWord = (e) => {
    setKeyword(e.target.value)
  }

  const compileClick = async () => {
    modal.confirm({
      title: '提示',
      icon: <ExclamationCircleFilled />,
      content: '是否确认开始编译？',
      async onOk() {
        riskTagState.setLoading(true)
        await getCompileRiskInfo(searchParams.get('type')).finally(() => {
          riskTagState.setLoading(false)
        })
        initTagList()
      }
    })
  }

  const targetSysClick = async (value) => {
    await targetSysUpdate({
      id: tagInfo.id,
      targetSystem: value
    })
    message.success('保存成功')
  }

  useEffect(() => {
    const tagNames = findParentNames(treeData, tagInfo.name, 2, tagInfo.id)
    setBreadcrumbList(tagNames)
  }, [treeData, tagInfo])

  useEffect(() => {
    riskTagState.setGroupLen(groupCollect.length)
  }, [groupCollect.length])

  useEffect(() => {
    initTagList()
    riskTagState.clearTagInfo()
  }, [location.search])

  useEffect(() => {
    getSysList().then((res) => {
      if (+res.code === 0) {
        setSysList(
          res.data.map((item) => ({ value: item.id, label: item.sysName }))
        )
      }
    })
  }, [])
  return (
    <div className={moduleStyle.container}>
      {contextHolder}
      {loading ? (
        <Spin
          className={moduleStyle.loading}
          tip="加载中"
          fullscren={true}
          spinning={loading}
        />
      ) : null}
      <div className={moduleStyle.leftWrap}>
        <div className={moduleStyle.treeTitle}>
          <span>标签列表</span>
          {buttonPermissions.includes('排除分类增删改查') ||
          buttonPermissions.includes('风险分类增删改查') ? (
            <Tooltip placement="top" title="新增一级分类">
              <img
                className={cn(moduleStyle.dropdown, moduleStyle.insert)}
                src={'/imgs/dropdown.png'}
                alt="下拉按钮"
                onClick={() => addClassify(0, null, null)}
              />
            </Tooltip>
          ) : null}
        </div>
        <Input
          suffix={<SearchOutlined />}
          className={moduleStyle.searchInput}
          placeholder="请输入标签或文件名称"
          value={keyword}
          onChange={changeKeyWord}
          onPressEnter={initTagList}
        />
        <div className={moduleStyle.treeWrap}>
          <Tree
            showIcon
            blockNode
            treeData={treeData}
            titleHeight={28}
            fieldNames={fieldNames}
            selectedKeys={defaultSelectedKeys}
            titleRender={(item) => (
              <div
                onClick={() => getTagDetail(item.labelId, item.id)}
                className={moduleStyle.treeItem}
              >
                <div>
                  {item.type === 1 ? (
                    <img
                      className={moduleStyle.folder}
                      src={'/imgs/folder-icon.png'}
                      alt="文件夹"
                    />
                  ) : (
                    <span
                      className={cn(moduleStyle.tagIcon, {
                        [moduleStyle.enable]: item.status === 1,
                        [moduleStyle.disabled]: item.status === 0,
                        [moduleStyle.uncompile]: item.status === 2,
                        [moduleStyle.compiling]: item.status === 3
                      })}
                    ></span>
                  )}
                  {item.name}
                </div>
                {item.status !== 9 ? (
                  <Dropdown
                    menu={{
                      items: renderDropdown(item)
                    }}
                    placement="bottom"
                  >
                    <img
                      className={moduleStyle.dropdown}
                      src={'/imgs/dropdown.png'}
                      alt="下拉按钮"
                    />
                  </Dropdown>
                ) : null}
              </div>
            )}
          />
        </div>
      </div>

      {!isEmpty(tagInfo) ? (
        <div className={moduleStyle.rightWrap}>
          <div className={moduleStyle.toolbar}>
            <div className={moduleStyle.breadcrumb}>
              {breadcrumbList.join(' / ')}
            </div>
            <div className={moduleStyle.buttonWrap}>
              {tagInfo.status === 0 &&
              ((tagInfo.matchType === 1 &&
                buttonPermissions.includes('风险标签停止/启动')) ||
                (tagInfo.matchType === 2 &&
                  buttonPermissions.includes('排除标签停止/启动'))) ? (
                <Button type="primary" onClick={() => openTag(tagInfo.id)}>
                  启动
                </Button>
              ) : null}
              {tagInfo.status === 1 &&
              ((tagInfo.matchType === 1 &&
                buttonPermissions.includes('风险标签停止/启动')) ||
                (tagInfo.matchType === 2 &&
                  buttonPermissions.includes('排除标签停止/启动'))) ? (
                <Button type="primary" onClick={() => closeTag(tagInfo.id)}>
                  停止
                </Button>
              ) : null}
              {(tagInfo.matchType === 1 &&
                buttonPermissions.includes('风险标签删除')) ||
              (tagInfo.matchType === 2 &&
                buttonPermissions.includes('排除标签删除')) ? (
                <Button type="primary" onClick={() => deleteTag(tagInfo.id)}>
                  删除
                </Button>
              ) : null}
            </div>
          </div>
          <div className={moduleStyle.rightContent}>
            <div className={moduleStyle.baseInfo}>
              <div className={moduleStyle.title}>基本信息</div>
              <div className={moduleStyle.tagNameWrap}>
                <div>标签名称</div>
                <Input
                  value={tagInfo.name}
                  className={moduleStyle.tagNameInput}
                  placeholder="请输入标签名称"
                  onChange={(e) =>
                    riskTagState.setTagInfo({
                      ...tagInfo,
                      name: e.target.value
                    })
                  }
                />
                <div>目标系统</div>
                <Select
                  defaultValue="AND"
                  style={{ width: 400 }}
                  value={tagInfo.targetSystem}
                  allowClear
                  mode="multiple"
                  className={moduleStyle.tagNameInput}
                  placeholder="请选择系统"
                  options={sysList}
                  onChange={changeSys}
                />
              </div>
            </div>
            <div className={moduleStyle.content}>
              <div className={moduleStyle.top}>
                <div className={moduleStyle.title}>匹配逻辑</div>
                <div className={moduleStyle.phraseRelationship}>
                  <span className={moduleStyle.label}>词组关系</span>
                  <Select
                    defaultValue="AND"
                    style={{ width: 80 }}
                    value={tagInfo.opType}
                    options={[
                      { value: 'AND', label: 'AND' },
                      { value: 'OR', label: 'OR' }
                    ]}
                    onChange={changeRelation}
                  />
                </div>
              </div>
              {groupCollect.map((item, index) => (
                <Group
                  key={item.key}
                  uniqKey={item.key}
                  index={index}
                  groupLen={groupLen}
                />
              ))}

              <div className={moduleStyle.addArea}>
                <div className={moduleStyle.addButton} onClick={addGroup}>
                  <PlusCircleOutlined className={moduleStyle.addIcon} />
                  添加词组
                </div>
              </div>
            </div>

            {searchParams.get('type') === '1' ? (
              <div className={moduleStyle.filterArea}>
                <FilterWord />
              </div>
            ) : null}
          </div>
          {/* 
                <div className={moduleStyle.matchArea}>
                  <div className={moduleStyle.title}>匹配位置</div>
                  <Checkbox.Group options={options}></Checkbox.Group>
                </div> 
            */}
          {searchParams.get('type') === '1' &&
          buttonPermissions.includes('风险标签编辑/创建') ? (
            <div className={moduleStyle.bottomWrap}>
              <Button type="primary" onClick={confirm}>
                保存
              </Button>
            </div>
          ) : null}
          {searchParams.get('type') === '2' &&
          buttonPermissions.includes('排除标签编辑/创建') ? (
            <div className={moduleStyle.bottomWrap}>
              <Button type="primary" onClick={confirm}>
                保存
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={cn(moduleStyle.rightWrap, moduleStyle.emptyData)}>
          请选择标签
        </div>
      )}
      <div className={moduleStyle.compileBg} onClick={compileClick}>
        <img
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: '-1'
          }}
          src="/imgs/compile-bg.svg"
          alt=""
        />
        <img style={{ marginBottom: 4 }} src="/imgs/compile-icon.svg" alt="" />
        <span style={{ marginBottom: 7 }}>编译</span>
      </div>
      <Modal
        title="新增分类"
        open={isModalOpen}
        onOk={confirmAddClassify}
        onCancel={() => setIsModalOpen(false)}
      >
        <Input
          onChange={(e) =>
            setTagClassifyInfo((state) => ({ ...state, name: e.target.value }))
          }
          value={tagClassifyInfo.name}
          placeholder="请输入分类名称"
        />
      </Modal>
    </div>
  )
}

export default RiskTag
