import moduleStyle from './style/index.module.scss'
import { useState, useRef } from 'react'
import { Button } from 'antd'
import Edit from './component/edit'
import List from './component/list'
import Del from './component/del'
import PageHeadTitle from '@/components/page-head-title'
import exportData from '../js/exportData'
function Index() {
  const [editVisble, setEditVisble] = useState(false) //编辑菜单弹窗
  const [delVisble, setDelVisble] = useState(false) //删除菜单弹窗
  const [rowSelection, setRowSelection] = useState([]) //列表选中
  const [initialValues, setInitialValues] = useState({})
  const listRef = useRef()
  const edit = (obj) => {
    if (obj) {
      setInitialValues(obj)
    } else {
      setInitialValues(false)
    }
    setEditVisble(true)
  }

  const refreshList = () => {
    //删除之后刷新列表
    listRef.current.getList()
  }
  return (
    <>
      <PageHeadTitle>角色管理</PageHeadTitle>
      <div className={moduleStyle.roleManagement}>
        <div>
          <Button onClick={() => edit(false)}>新增</Button>
          <Button
            className="fn-ml5"
            onClick={() => edit(rowSelection[0])}
            disabled={rowSelection.length !== 1}
          >
            修改
          </Button>
          <Button
            className="fn-ml5"
            onClick={() => setDelVisble(true)}
            disabled={rowSelection.length < 1}
          >
            删除
          </Button>
          <Button
            className="fn-ml5"
            onClick={() =>
              exportData('角色管理', rowSelection, listRef.current.columns)
            }
            disabled={rowSelection.length < 1}
          >
            导出
          </Button>
          <Edit
            visble={editVisble}
            setVisble={setEditVisble}
            initialValues={initialValues}
            onFinish={refreshList}
          />
          <Del
            visble={delVisble}
            setVisble={setDelVisble}
            rowSelection={rowSelection}
            onFinish={refreshList}
          />
        </div>
        <List ref={listRef} onEdit={edit} setRowSelection={setRowSelection} />
      </div>
    </>
  )
}
export default Index
