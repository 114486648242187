import { useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import routeGuard from './routeGuard'
import routes from './config'
//登录页
import Login from '@/pages/login'
//首页
import HomePage from '@/pages/home'
import AsideLayout from '../layout/asideLayout'
import BasicLayout from '../layout/basicLayout'
import CustomLayout from '../layout/customLayout'
import DocMenu from '../pages/doc/components/docMenu'

//递归匹配路由
const targetRouteFn = (pathname) => {
  let routeObj = false
  const deepRoute = (routeList) => {
    routeList.forEach((item) => {
      if (item.path.replace(/\s*/g, '') === pathname) {
        routeObj = {
          path: item.path,
          name: item.name,
          component: item.component,
          layout: item.layout || ''
        }
      } else if (item.dynamic) {
        // 动态路由判断
        let currentPath = item.path?.split('/')
        currentPath.splice(currentPath.length - 1)
        currentPath = currentPath.join('/')
        let currentPathName = pathname?.split('/')
        currentPathName.splice(currentPathName.length - 1)
        currentPathName = currentPathName.join('/')
        if (currentPath === currentPathName) {
          routeObj = {
            path: item.path,
            name: item.name,
            component: item.component,
            layout: item.layout || ''
          }
        }
      }
      if (item.child) {
        return deepRoute(item.child)
      }
    })
  }
  deepRoute(routes)
  return routeObj
}

//带公共头部 页脚 版权说明 预览组件
const basicLayout = (Component) => {
  return <BasicLayout component={<Component />} />
}

const asideLayout = (Component, Menu) => {
  return <AsideLayout aside={<Menu />} content={<Component />}></AsideLayout>
}

const customLayout = (Component) => {
  return <CustomLayout content={<Component />}></CustomLayout>
}
//重定向
const Redirect = ({ to }) => {
  let navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}

function Index() {
  const isLogin = Cookies.get('access_token')
  const pathname = useLocation().pathname
  //路由守卫函数
  routeGuard(pathname, isLogin)
  //匹配路由组件
  const targetRoute = targetRouteFn(pathname)

  //路由跳转页面回顶部
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    window.scrollTo(0, 0)
  }, [pathname])

  //页面需要登录查看
  if (!isLogin || pathname === '/login') {
    return (
      <Routes>
        <Route path="*" element={<Redirect to="/login" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    )
  }

  //路由不合法 或者 已经登录后跳转登录页
  if (
    !targetRoute ||
    pathname === '/home' ||
    (isLogin && pathname === '/login')
  ) {
    const RouteComponent = basicLayout(HomePage)
    return (
      <Routes>
        <Route path="*" element={<Redirect to="/home" />} />
        <Route path="/home" element={RouteComponent} />
      </Routes>
    )
  }

  if (isLogin) {
    let RouteComponent = ''
    if (targetRoute?.layout === 'AsideLayout') {
      // 边栏导航布局
      RouteComponent = asideLayout(targetRoute.component, DocMenu)
    } else if (targetRoute?.layout === 'CustomLayout') {
      RouteComponent = customLayout(targetRoute.component)
    } else {
      RouteComponent = basicLayout(targetRoute.component, DocMenu)
    }
    return (
      <Routes>
        <Route exact path="development-platform/api/*" element={RouteComponent}>
          <Route path="doc-html/:id" element={RouteComponent} />
          <Route path="docFS/:id" element={RouteComponent} />
        </Route>
        <Route path="*" element={RouteComponent} />
      </Routes>
    )
  }
}

export default Index
