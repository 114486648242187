import Head from 'components/head'
import Foot from 'components/foot'
import CopyRight from 'components/copyRight'
function BasicLayout(props) {
  return (
    <>
      <Head />
      <div style={{ paddingTop: '60px' }}>{props.component}</div>
      <Foot />
      <CopyRight />
    </>
  )
}

export default BasicLayout
