import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moduleStyle from './style/index.module.scss'
import { Form, Input, Button, Checkbox, message } from 'antd'
import { getToken } from '@/api/list/login'
import Cookies from 'js-cookie'
import CopyRight from 'components/copyRight'
const logoPng = require('@/assets/img/logo.png')
const open_eye = require('./img/open_eye.png')
const close_eye = require('./img/close_eye.png')

function Index() {
  let navigate = useNavigate()
  const onFinish = (values) => {
    const { username, password } = values
    let param = {
      username,
      password,
      grant_type: 'password',
      client_id: 'rzmk-mi-password-client',
      client_secret: '$2a$11$veu5Bmel1s98upe'
    }

    getToken(param).then((res) => {
      if (res.code === 0) {
        Cookies.set('access_token', res.data.access_token)
        Cookies.set('refresh_token', res.data.refresh_token)
        Cookies.set('token_time', new Date().getTime())
        localStorage.setItem('username', username)
        localStorage.setItem('password', password)
        if (rememberPassword) {
          localStorage.setItem('rememberPassword', rememberPassword)
        } else {
          localStorage.removeItem('rememberPassword')
        }
        navigate('/home')
      } else {
        message.warn(res.message)
      }
    })
  }

  let username = ''
  let password = ''
  const [rememberPassword, setRemeber] = useState(
    localStorage.getItem('rememberPassword')
  )

  if (rememberPassword) {
    username = localStorage.getItem('username')
    password = localStorage.getItem('password')
  }

  return (
    <div className={moduleStyle.loginWrapper}>
      <div className="head">
        <img src={logoPng} alt="" />
      </div>
      <div className="login-box">
        <div className="tit">账号登录</div>
        <Form name="basic" onFinish={onFinish} autoComplete="off">
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入账号' }]}
            initialValue={username}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
            initialValue={password}
          >
            <Input.Password
              size="large"
              iconRender={(visible) =>
                visible ? (
                  <img src={open_eye} alt="" />
                ) : (
                  <img src={close_eye} alt="" />
                )
              }
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              defaultChecked={rememberPassword}
              onChange={(e) => {
                setRemeber(e.target.checked)
              }}
            >
              <span>记住密码</span>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              className="sub-btn"
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="copy-right">
        <CopyRight className="inner-box" />
      </div>
    </div>
  )
}

export default Index
