import s from './index.module.scss'
import Head from 'components/head'
function AsideLayout(props) {
  return (
    <>
      <Head shadow={true} />
      <div style={{ paddingTop: '60px' }}>
        <div className={s.asideLayout}>
          <div className={s.aside}>{props.aside}</div>
          <div className={s.rightContainer}>
            <div id="docx-context" className={s.rightContent}>
              {props.content}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AsideLayout
