import blockStyle from './tableBlock.module.scss'
import Block from '../block'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'

export default function TableBlock(props) {
  const { blockData } = props

  const { cells, property } = blockData.table
  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)

  // 行数
  const rowsNumber = Math.floor(cells.length / property.column_size)

  // 列数
  const colGroup = []
  for (let i = 0; i < property.column_size; i++) {
    colGroup.push(
      <colgroup key={i} width={property.column_width[i]}></colgroup>
    )
  }

  // 单元格合并后展示与否
  const hideCellMap = Array(property.merge_info.length).fill(false)
  property.merge_info.forEach((info, index) => {
    if (info.row_span > 1 || info.col_span > 1) {
      // 向下合并几格
      const rowScope = info.row_span
      // 向右合并几格
      const colScope = info.col_span

      for (let x = 0; x < colScope; x++) {
        for (let y = 0; y < rowScope; y++) {
          hideCellMap[index + x + y * property.column_size] = true
        }
      }
      hideCellMap[index] = false
    }
  })

  let rows = []
  for (let i = 0; i < rowsNumber; i++) {
    rows.push([])
  }

  let rowIndex = 0
  cells.forEach((id, index) => {
    const td = (
      <td
        hidden={hideCellMap[index]}
        className={blockStyle.cell}
        rowSpan={property.merge_info[index].row_span}
        colSpan={property.merge_info[index].col_span}
        key={id}
        id={id}
      >
        <Block key={id} blockData={blockItemMap[id]} />
      </td>
    )
    rows[rowIndex].push(td)
    if (
      index % property.column_size === property.column_size - 1 &&
      index !== 0
    ) {
      ++rowIndex
    }
  })

  const rowsEle = rows.map((row, index) => {
    return <tr key={index}>{row}</tr>
  })

  const table = (
    <div key={blockData.block_id} id={blockData.block_id}>
      <table className={blockStyle.tableBlock}>
        {colGroup}
        <tbody>{rowsEle}</tbody>
      </table>
    </div>
  )
  //生成表
  return table
}
