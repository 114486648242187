import http from './../axios'

/**
 * 单资源验证风险标签同步接口
 */
export const getVerifyDetail = (params) => {
  return http().post(
    `/media_service/single/data/risk/label/verify/detail`,
    params
  )
}

/**
 * 更新标签
 */
export const updateTag = (params) => {
  return http().post(`/media_service/risk/discovery/label/info/update`, params)
}

/**
 * 获取编译结果
 */
export const getCompileStatus = (params) => {
  return http().get(`/media_service/risk/discovery/label/info/compile/status`, {
    params
  })
}
