import blockStyle from './listBlock.module.scss'
import Block from '../block'
import TextBlock from './textBlock'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'

function ListBlock(props) {
  const { blockData } = props
  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)
  const blockClassName = () => {
    const style = []
    style.push(blockStyle.listBlock)
    if (blockData.block_type_name === 'ordered') style.push(blockStyle.olBlock)
    if (blockData.block_type_name === 'bullet') style.push(blockStyle.ulBlock)
    return style.join(' ')
  }
  // list 的内容，可能是一条文本，也可能是另一个list
  const content = (
    <li className="list-content">
      <TextBlock blockData={blockData}></TextBlock>
    </li>
  )

  const children = blockData.children
    ? blockData.children.map((childId, index) => {
        return <Block key={childId} blockData={blockItemMap[childId]}></Block>
      })
    : null

  const childrenList = children ? (
    <li className={blockStyle.childList}>{children}</li>
  ) : null

  const ol = (
    <ol className={blockClassName()} id={blockData.block_id}>
      {content}
      {childrenList}
    </ol>
  )
  const ul = (
    <ul className={blockClassName()} id={blockData.block_id}>
      {content}
      {childrenList}
    </ul>
  )

  return blockData.block_type_name === 'ordered' ? ol : ul
}
export default ListBlock
