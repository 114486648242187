import moduleStyle from './style/index.module.scss'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import recoilStore from '@/recoilStore'

const addHttp = (path) => {
  return path.includes('http://') || path.includes('https://')
    ? path
    : 'http://' + path
}
const tree = (nodes) => {
  if (!nodes) {
    return null
  }
  return nodes.map((item, index) => (
    <div key={index}>
      <div className={!item.pid ? 'parent-node' : 'child-node'}>
        {item.is_frame ? (
          <a href={addHttp(item.path)} target="_blank" rel="noreferrer">
            {item.label}
          </a>
        ) : item.path !== 'noPath' && item.path !== '' ? (
          <Link to={item.path !== 'noPath' ? item.path : ''}>{item.label}</Link>
        ) : (
          <>{item.label}</>
        )}
      </div>
      {tree(item.children)}
    </div>
  ))
}

function Index() {
  let showMeanList = useRecoilValue(recoilStore.showMeanList)
  const routeItems = JSON.parse(JSON.stringify(showMeanList))
  return (
    <div className={moduleStyle.footerWrapper}>
      <div className="footer-container">
        <div className="tree-box">{tree(routeItems)}</div>
        <div className="code-box">
          <img src={require('./img/code.png')} alt="" />
          <div className="code-tit">公众号</div>
        </div>
      </div>
    </div>
  )
}

export default Index
