import moduleStyle from '../../index.module.scss'
import ArticleComponent from '../article/index'
import TagComponent from '../tag/index'
import RecognitionComponent from '../recognition/index'
const ContentComponent = (props) => {
  /**
   * @name props
   * @desc 父级参数
   * @param {number} menuActive 1-风控ID 2中台ID 3-文本
   * @param {DetailData} detailData 接口返回数据
   */
  /**
   * @name DetailData
   * @desc 接口返回数据
   * @param {string} dataId 数据id
   * @param {array} algoDetailList 标签列表
   * @param {array} dataDetailList 内容列表
   */

  return (
    <div className={moduleStyle.content}>
      <div className={moduleStyle.contentLeft}>
        {/* 右侧顶部视频信息 */}
        {props.menuActive !== 3 && props.detailData ? (
          <ArticleComponent
            detailData={
              props.labelCode
                ? props.detailData.dataDetailList.find(
                    (item) => item.labelCode === props.labelCode
                  )
                : props.defaultDetailData
            }
          ></ArticleComponent>
        ) : (
          ''
        )}
        {/* 右侧底部标签 */}
        <TagComponent
          detailData={props.detailData?.excludeAlgoDetailList || []}
          setLabelCode={props.setLabelCode}
          title="排除标签详情"
        ></TagComponent>
        <TagComponent
          detailData={props.detailData?.hitAlgoDetailList || []}
          setLabelCode={props.setLabelCode}
          title="命中标签详情"
        ></TagComponent>
      </div>
      {/* 左侧内容识别 */}
      <RecognitionComponent
        menuActive={props.menuActive}
        detailData={
          props.labelCode
            ? props.detailData.dataDetailList.find(
                (item) => item.labelCode === props.labelCode
              )
            : props.defaultDetailData
        }
      ></RecognitionComponent>
    </div>
  )
}
export default ContentComponent
