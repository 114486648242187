// 文档解析器
import Block from './block'
// 飞书文档展示器，只要文档数据
function Doc({ blockItems }) {
  if (!blockItems) return <div>文档错误格式</div>
  if (!blockItems.length) return <div>文档为空</div>
  const doc = <Block key={blockItems[0].block_id} blockData={blockItems[0]} />
  return <div className="docx">{doc}</div>
}

export default Doc
