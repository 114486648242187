import moduleStyle from './style/index.module.scss'
import { useNavigate } from 'react-router-dom'

const linkArr = [
  { url: require('./img/center.png'), label: '公告中心', path: '' },
  {
    url: require('./img/dash-board.png'),
    label: 'DASHBOARD看板',
    path: '/dashBoard/serviceData'
  },
  {
    url: require('./img/view-nav.png'),
    label: '可视化导航页',
    path: '/visualNavigation'
  },
  { url: require('./img/big-screen.png'), label: 'BIGSCREEN大屏', path: '' }
]

function Index() {
  const navigate = useNavigate()
  const jump = (path) => {
    if (path) {
      navigate(path)
    }
  }
  return (
    <div className={moduleStyle.navigationWrapperBox}>
      <div className="link-list-box">
        {linkArr.map((item, index) => (
          <div
            className={`link-box`}
            key={index}
            onClick={() => jump(item.path)}
          >
            <div className="item-box">
              <div className="img-box">
                <img src={item.url} alt="" />
              </div>
            </div>
            <div className="label-name">{item.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Index
