import blockStyle from './divider.module.scss'

export default function Divider() {
  const blockClassName = () => {
    const styleArray = []
    styleArray.push(blockStyle.divider)
    return styleArray.join(' ')
  }
  return (
    <div className={blockStyle.dividerContainer}>
      <div className={blockClassName()}></div>
    </div>
  )
}
