// import {useEffect} from "react";
// import { Editor } from '@tinymce/tinymce-react';
import { Table, Typography } from 'antd'
import {
  abilityIndex,
  abilityData,
  abilityAPI,
  abilityAPIData,
  unAbilityAPIData,
  unAbilityAPI
} from './enum.js'
import s from './index.module.scss'

function Api() {
  const { Title } = Typography
  // Editor({init: {readonly: true} })

  const columnsApi = [
    {
      title: '查询功能',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '链接',
      dataIndex: 'link',
      key: 'link',
      render: (link) => {
        return (
          <a target="_blank" href={link} rel="noreferrer">
            {link}
          </a>
        )
      }
    }
  ]

  const apiDataSource = [
    {
      key: 'api1',
      name: '资源查询能力说明文档',
      link: 'https://yfobh10b4d.feishu.cn/docx/DJn9dYFIXoY98TxJqImcWTSZnFc?from=from_copylink'
    }
  ]

  return (
    <div className={s.apiWrap}>
      {/*<div className={s.headBox}>*/}
      {/*    <div className={s.centerTit}>*/}
      {/*        <div className={s.title}>API</div>*/}
      {/*        <Breadcrumb itemRender={itemRender} routes={routes} />*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className={s.container}>
        <br />
        <Title level={2}>一、数据采集对接文档</Title>
        <Title level={3}>1.1 各平台采集能力一览</Title>
        <br />
        <Table
          bordered
          pagination={false}
          columns={abilityIndex}
          dataSource={abilityData}
        />
        <br />
        <Title level={3}>1.2 标准能力接口</Title>
        <br />
        <Table
          pagination={false}
          columns={abilityAPI}
          dataSource={abilityAPIData}
        />
        <br />
        <Title level={3}>1.3 非标准能力接口</Title>
        <br />
        <Table
          pagination={false}
          columns={unAbilityAPI}
          dataSource={unAbilityAPIData}
        />
        {/*----------------------资源查询能力说明文档----------------------*/}
        <br />
        <br />
        <Title level={2}>二、 资源查询能力说明文档</Title>
        <br />
        <Title level={3}>2.1 数据介绍</Title>
        资源数据主要是以短视频平台为主的视频数据，包含抖音、快手、西瓜、微博、哔哩哔哩、youtube、twitter等多个平台的数据。现已有95亿+的数据量，并且以每天5000w+的速度增长。
        <br />
        <br />
        <Title level={3}>2.2 数据包含信息</Title>
        <div>
          资源数据包含了资源的属地信息，ocr信息，asr信息，情绪信息，作者信息，源地址，本地地址，截帧，点赞数，评论数等信息。
        </div>
        <br />
        <Title level={3}>2.2 数据获取方式</Title>
        <div>
          可使用分页多条件进行资源获取，相同条件下最多可支持返回10000条数据。可以使用的查询条件包括:
          关键词，标题，ocr，asr，平台，情绪，粉丝数，点赞数，转发数，发布时间，属地，作者等信息。
          <br />
          正式环境： POST
          http://api.data.people-ai.net/common/riskcontrol/video/pageResourceMultipleConditionsSearch
          <br />
          测试环境： POST
          http://test.api.data.people-ai.net/common/riskcontrol/video/pageResourceMultipleConditionsSearch
        </div>
        <br />
        <Table
          pagination={false}
          dataSource={apiDataSource}
          columns={columnsApi}
        />
        {/*<Input.TextArea autoSize disabled value={demoJson}></Input.TextArea>*/}
        {/*<Title level={3}>2.2 请求示例</Title>*/}
        {/*<textarea type="textarea" readonly="true" className={s.demoCode}>{demoCode}</textarea>*/}
        {/*<div>*/}
        {/*    <Editor*/}
        {/*        id="menu1"*/}
        {/*        initialValue={`<p>${demoCode}</p>`}*/}
        {/*        init={{*/}
        {/*            setup: () => {},*/}
        {/*            height: 600,*/}
        {/*            // selector: 'textarea',*/}
        {/*            readonly: 'true',*/}
        {/*            // language: zhCN,*/}
        {/*            plugins: '',*/}
        {/*            toolbar: [],*/}
        {/*        }}*/}
        {/*    />*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Api
