import http from './../axios'

/**
 * 标签信息
 */
export const getLableInfo = (params) => {
  return http().get(`/media_service/risk/label/verify/label/info`, { params })
}

/**
 * 更新标签
 */
export const updateLabel = (params) => {
  return http().post(`/media_service/risk/label/verify/label/update`, params)
}

/**
 * 标签同步
 */
export const syncLabel = (params) => {
  return http().post(
    `/media_service/risk/label/verify/verify/label/verify/to/product/sync`,
    params
  )
}

/**
 * 表达式验证
 */
export const checkExpression = (params) => {
  return http().post(
    `/media_service/risk/label/verify/label/expression/check`,
    params
  )
}

/**
 * 验证数据列表
 */
export const verifyDataPage = (params) => {
  return http().post(`/media_service/risk/label/verify/data/page`, params)
}

/**
 * 标签验证统计
 */
export const verifyDataStatistics = (params) => {
  return http().get(`/media_service/risk/label/verify/data/statistics`, {
    params
  })
}

/**
 * 验证研判
 */
export const verifyDataExamine = (params) => {
  return http().post(`/media_service/risk/label/verify/data/examine`, params)
}

/**
 * 重新拉取
 */
export const pullData = (params) => {
  return http().post(
    `/media_service/risk/label/verify/verify/label/pull/data`,
    params
  )
}

/**
 * 数据导出
 */
export const downloadExcel = (params) => {
  return http().post(
    `/media_service/risk/label/verify/download/excel`,
    params,
    { responseType: 'blob' }
  )
}

/***
 * 详情
 */
export const getDetail = (params) => {
  return http().get(`/media_service/risk/label/verify/data/detail`, {
    params
  })
}

/**
 * 命中词详情
 */

export const getAlgoDetail = (params) => {
  return http().get(`/media_service/risk/label/verify/algo/detail`, {
    params
  })
}
