import { atom } from 'recoil'

const atomObj = {}

atomObj['showMeanList'] = atom({
  key: 'showMeanList',
  default: []
})

atomObj['userInfo'] = atom({
  key: 'userInfo',
  default: {}
})

// 文档系统，拉取图片列表，同一时间最高5个并发请求
atomObj['docImageRequestTaskList'] = atom({
  key: 'docImageRequestTaskList',
  default: {}
})

atomObj['docBlockItemMap'] = atom({
  key: 'docBlockItemMap',
  default: {}
})

export default atomObj
