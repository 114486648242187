import moduleStyle from './style/index.module.scss'
import ChartBox from './compenent/chartBox'
import * as statistics from '@/api/list/statistics'
//echart配置
const chartBoxList = [
  {
    getData: statistics.getResourceByDay,
    title: '一周采集量统计',
    type: 'bar',
    stack: true,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getResourceBy5m,
    title: '采集量实时记录',
    type: 'line',
    stack: true,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getCrawledAccountRecordByDay,
    title: '账号采集任务统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getCrawledAccountRecordBy5m,
    title: '账号采集任务统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getResourceDirectByDay,
    title: '一周采集量（优先）统计',
    type: 'bar',
    stack: true,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getResourceDirectBy5m,
    title: '采集量（优先）实时记录',
    type: 'line',
    stack: true,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  // {
  //   getData: statistics.getDownloadByDay,
  //   title: "一周下载统计",
  //   type: "bar",
  //   stack: true,
  //   manufacture: true,
  //   hideLabel: false,
  //   featureShow: false,
  //   dataZoom: false,
  // },
  // {
  //   getData: statistics.getDownloadBy5m,
  //   title: "下载实时记录",
  //   type: "line",
  //   stack: true,
  //   manufacture: true,
  //   hideLabel: false,
  //   featureShow: false,
  //   dataZoom: false,
  // },
  // {
  //   getData: statistics.getDownloadCountByDay,
  //   title: "一周封面下载统计",
  //   type: "bar",
  //   stack: false,
  //   manufacture: true,
  //   hideLabel: false,
  //   featureShow: false,
  //   dataZoom: false,
  // },
  // {
  //   getData: statistics.getDownloadCountBy5m,
  //   title: "5分钟封面下载统计",
  //   type: "line",
  //   stack: false,
  //   manufacture: true,
  //   hideLabel: false,
  //   featureShow: false,
  //   dataZoom: true,
  // },
  {
    getData: statistics.getAccountByDay,
    title: '一周账号更新情况',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getAccountBy5m,
    title: '账号实时更新记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getCommentByDay,
    title: '一周评论采集统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getCommentBy5m,
    title: '评论采集实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getEmotionByDay,
    title: '一周感情色彩计算统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getEmotionBy5m,
    title: '感情色彩计算实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getAiForHightPriorityByDay,
    title: '一周高优先级AI算法统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getAiForHightPriorityBy5m,
    title: '高优先级AI算法实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getAiByDay,
    title: '一周AI算法统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getAiBy5m,
    title: 'AI算法统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getCrawledIncidentKeywordByDay,
    title: '事件关键词采集统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getCrawledIncidentKeywordBy5m,
    title: '事件关键词采集统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getDownloadTaskGradeByDay,
    title: '下载任务档位统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getDownloadTaskGradeBy5m,
    title: '下载任务档位统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getDownloadTaskLevelByDay,
    title: '下载任务账号等级统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getDownloadTaskLevelBy5m,
    title: '下载任务账号等级统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getDownloadTaskSuffixByDay,
    title: '下载任务文件类型统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getDownloadTaskSuffixBy5m,
    title: '下载任务文件类型统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getDownloadResCoverByDay,
    title: '封面下载统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getDownloadResCoverBy5m,
    title: '封面下载统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getDownloadResVideoByDay,
    title: '视频下载统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getDownloadResVideoBy5m,
    title: '视频下载统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getDownloadResSourceformByDay,
    title: '外媒视频下载统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getDownloadResSourceformBy5m,
    title: '外媒视频下载统计实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getVideoParseByDay,
    title: '一周转码记录',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  },
  {
    getData: statistics.getVideoParseBy5m,
    title: '转码实时记录',
    type: 'line',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: true
  },
  {
    getData: statistics.getSassNewlyByDay,
    title: '资源新增统计',
    type: 'bar',
    stack: false,
    manufacture: true,
    hideLabel: false,
    featureShow: false,
    dataZoom: false
  }
]
function Index() {
  return (
    <div className={moduleStyle.serviceDataWrapper}>
      {chartBoxList.map((item, index) => (
        <ChartBox key={index} options={item} />
      ))}
    </div>
  )
}
export default Index
