import Block from '../block'
import blockStyle from './gridBlock.module.scss'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'

export default function GridBlock(props) {
  const { blockData } = props
  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)

  const style = {
    gridTemplateColumns: `repeat(${
      blockData[blockData.block_type_name].column_size
    }, 1fr)`,
    columnGap: '5px',
    rowGap: '5px'
  }
  const children = blockData.children.map((id, index) => {
    return <Block key={id} blockData={blockItemMap[id]} />
  })
  return (
    <div className={blockStyle.gridBlock} style={style}>
      {children}
    </div>
  )
}
