import { Button, Modal, Typography, message } from 'antd'
import { useState, useEffect } from 'react'
import { CopyOutlined, LinkOutlined, DownloadOutlined } from '@ant-design/icons'
import moduleStyle from '../index.module.scss'
import { getDetail, getAlgoDetail } from '@/api/list/tagVerify'
import TagVerifyStore from '@/store/tagVerify'
import cn from 'classnames'
import axios from 'axios'
import { platform } from '@/untils/constant'

function copyTextToClipboard(text) {
  const textarea = document.createElement('textarea')
  textarea.value = text
  textarea.style.position = 'fixed'
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

function copyFormattedText(text) {
  const dummyElement = document.createElement('textarea')
  dummyElement.value = text
  document.body.appendChild(dummyElement)
  dummyElement.select()
  document.execCommand('copy')
  document.body.removeChild(dummyElement)
}

function downloadVideo(url, filename) {
  axios({
    url,
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      })

      // 创建下载链接
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename

      // 触发下载
      downloadLink.click()

      // 释放创建的 URL 对象
      URL.revokeObjectURL(downloadLink.href)
    })
    .catch((error) => {
      console.error('Error downloading video:', error)
    })
}

const { Paragraph, Text } = Typography
function Detail({ visible, handleVisible, dataId }) {
  const [open, setOpen] = useState(false)
  const [detailData, setDetailData] = useState({})
  const [algoDetail, setAlgoDetail] = useState([])
  const { lableInfo } = TagVerifyStore()
  const handleCancel = () => {
    handleVisible(false)
  }

  const copyDataId = () => {
    copyFormattedText(detailData.dataId)
    message.success('复制成功')
  }

  const copyVideoUrl = () => {
    if (!detailData.videos?.length) return
    const urls = detailData.videos.map((video) => {
      return video.url
    })
    copyFormattedText(urls.join(','))
    message.success('复制成功')
  }

  const copyVideoInfo = () => {
    const data = {
      标题: detailData.title?.replace(/<[^>]+>/g, ''),
      涉及地区: detailData.contentRegions?.replace(/<[^>]+>/g, ''),
      IP属地: detailData.ipPlace?.replace(/<[^>]+>/g, ''),
      用户注册地: detailData.authorDistrict?.replace(/<[^>]+>/g, ''),
      用户签到地: detailData.district?.replace(/<[^>]+>/g, ''),
      链接: detailData.videos?.map((item) => item.url).join(','),
      时间: detailData.publishTime,
      来源: detailData.siteName?.replace(/<[^>]+>/g, ''),
      作者: detailData.authorName?.replace(/<[^>]+>/g, '')
    }
    const format = `
    标题：${data.标题}
    涉及地区：${data.涉及地区}
    IP属地：${data.IP属地}
    用户注册地：${data.用户注册地}
    用户签到地：${data.用户签到地}
    链接：${data.链接}
    时间：${data.时间}
    来源：${data.来源}
    作者：${data.作者}`
    copyTextToClipboard(format)
    message.success('复制成功')
  }

  const downLoadVideos = () => {
    if (!detailData.videos?.length) return
    detailData.videos.forEach((video) => {
      downloadVideo(video.url, 'video.mp4')
    })
  }

  useEffect(() => {
    setOpen(visible)
  }, [visible])

  useEffect(() => {
    if (visible && dataId) {
      getDetail({ labelCode: lableInfo.code, dataId }).then((res) => {
        if (+res.code === 0) {
          setDetailData(res.data)
        }
      })
      getAlgoDetail({ labelCode: lableInfo.code, dataId }).then((res) => {
        if (+res.code === 0) {
          setAlgoDetail(res.data)
        }
      })
    }
  }, [visible, dataId])

  return (
    <>
      <Modal
        className={moduleStyle.modal}
        open={open}
        title=""
        onCancel={handleCancel}
        footer={null}
        width={1468}
      >
        <div className={moduleStyle.detailContent}>
          <div className={moduleStyle.left}>
            <div className={moduleStyle.buttonWrap}>
              <Button onClick={copyVideoInfo} icon={<CopyOutlined />}>
                复制视频信息
              </Button>
              <Button
                target="_blank"
                href={detailData?.webUrl}
                icon={<LinkOutlined />}
              >
                查看原文
              </Button>
              <Button onClick={copyVideoUrl} icon={<CopyOutlined />}>
                复制视频链接
              </Button>
              <Button onClick={downLoadVideos} icon={<DownloadOutlined />}>
                下载视频
              </Button>
              <Button onClick={copyDataId} icon={<CopyOutlined />}>
                复制资源ID
              </Button>
            </div>
            <div
              className={moduleStyle.title}
              dangerouslySetInnerHTML={{ __html: detailData.title }}
            ></div>
            <div className={moduleStyle.videoInfo}>
              <div className={moduleStyle.top}>
                <div>
                  <img
                    className={moduleStyle.platformIcon}
                    src={`/imgs/${platform[detailData.siteId]}.png`}
                    alt=""
                  />
                  <span
                    dangerouslySetInnerHTML={{ __html: detailData.siteName }}
                  ></span>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: detailData.authorName }}
                ></div>
                <div>{detailData.publishTime}</div>
                <div>ID:{detailData.siteAuthorId || '-'}</div>
              </div>
              <div className={moduleStyle.bottom}>
                <div>
                  涉及地区：
                  <span
                    dangerouslySetInnerHTML={{
                      __html: detailData.contentRegions || '-'
                    }}
                  ></span>
                </div>
                <div>
                  IP属地：
                  <span
                    dangerouslySetInnerHTML={{
                      __html: detailData.ipPlace || '-'
                    }}
                  ></span>
                </div>
                <div>
                  用户注册地：
                  <span
                    dangerouslySetInnerHTML={{
                      __html: detailData.authorDistrict || '-'
                    }}
                  ></span>
                </div>
                <div>
                  用户签到地：
                  <span
                    dangerouslySetInnerHTML={{
                      __html: detailData.district || '-'
                    }}
                  ></span>
                </div>
              </div>
            </div>
            <div className={moduleStyle.content}>
              <div>
                {detailData.videos?.map((video) => (
                  <video
                    src={video.url}
                    controls
                    className={moduleStyle.video}
                  />
                ))}
              </div>
              <div>
                {detailData.pics?.map((item) => (
                  <img
                    className={moduleStyle.image}
                    src={item.url}
                    alt="图片"
                  />
                ))}
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: detailData.content || detailData.title
                }}
              ></div>
            </div>

            <div className={moduleStyle.hitWrap}>
              <div className={moduleStyle.hitTitle}>命中词详情</div>
              {algoDetail?.map((item) => (
                <div className={moduleStyle.hitItem}>
                  <div className={moduleStyle.word}>
                    <img
                      className={moduleStyle.icon}
                      src="/imgs/word.png"
                      alt=""
                    />
                    <Paragraph ellipsis={{ rows: 1 }}>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.word }}
                      ></span>
                    </Paragraph>
                  </div>

                  <div className={moduleStyle.hitField}>
                    <img
                      className={moduleStyle.icon}
                      src="/imgs/position.png"
                      alt=""
                    />
                    <Paragraph ellipsis={{ rows: 1 }}>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.hitField }}
                      ></span>
                    </Paragraph>
                  </div>

                  <div className={moduleStyle.hitPath}>
                    <img
                      className={moduleStyle.icon}
                      src="/imgs/hitpath.png"
                      alt=""
                    />
                    <Paragraph ellipsis={{ rows: 1 }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.hitPath.join(',')
                        }}
                      ></span>
                    </Paragraph>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={moduleStyle.right}>
            <div className={moduleStyle.title}>
              内容识别
              {detailData.emotion === 1 ? (
                <span className={cn(moduleStyle.tag, moduleStyle.positive)}>
                  正面
                </span>
              ) : null}
              {detailData.emotion === 2 ? (
                <span className={cn(moduleStyle.tag, moduleStyle.negative)}>
                  负面
                </span>
              ) : null}
              {detailData.emotion === 3 ? (
                <span className={cn(moduleStyle.tag, moduleStyle.normal)}>
                  中性
                </span>
              ) : null}
            </div>
            <div className={moduleStyle.identifyWrap}>
              <div className={moduleStyle.identifyBox}>
                <div className={moduleStyle.identifyTitle}>OCR</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailData.ocrCoverContent
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Detail
