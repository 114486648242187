import moduleStyle from './style/index.module.scss'
import { CaretRightOutlined } from '@ant-design/icons'

const leftBg = require('./img/left-bg.png')
const rightBg = require('./img/right-bg.png')
function Index() {
  return (
    <div className={moduleStyle.subjectWrapperBox}>
      <div className="big-title">睿媒大数据平台介绍</div>

      <div className="info-box">
        <div className="icon-box">
          <img src={leftBg} alt="" />
        </div>
        <div className="info-top-tit-box">
          <div className="blue-tit">
            睿媒大数据平台是互联网内容安全解决方案平台：
          </div>
          <div className="tit-item">
            <CaretRightOutlined className="blue-ico" />
            <span>
              依靠人民中科在音视频内容智能理解和大数据技术方面的优势开发；
            </span>
          </div>
          <div className="tit-item">
            <CaretRightOutlined className="blue-ico" />
            <span>着重于互联网音视频内容的理解和风险分析；</span>
          </div>
          <div className="tit-item">
            <CaretRightOutlined className="blue-ico" />
            <span>
              集成视频采集、音视频内容理解、AI视频理解、实体关系推理等全流程解决方案；
            </span>
          </div>
        </div>
      </div>

      <div className="info-box">
        <div className="info-bottom-tit-box">
          睿媒大数据平台，通过微服务技术架构，将人民中科的核心技术能力服务化和标准化，形成统一接入和调用规范，合理管理数据、算法、服务等核心功能的使用方式和权限。为产品和项目提供规范、有效、合理和便捷的支持，优化开发步骤、提高开发效率、拓展产品功能。平台的主要模块按照功能划分包括：
          <div className="info-bottom-tit-nav">
            <span>首页介绍</span>
            <span>大数据</span>
            <span>功能展示</span>
            <span>监控预警</span>
            <span>平台服务</span>
            <span>开放平台</span>
          </div>
        </div>
        <div className="icon-box">
          <img src={rightBg} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Index
