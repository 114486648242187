import { TreeSelect } from 'antd'
import { useState, useEffect } from 'react'
import { getBuildAll } from '@/api/list/system'
const deepArr = (list) => {
  const deepFun = (arr) => {
    arr.forEach((item) => {
      item.value = item.id ? item.id : 'value' + Math.random()
      if (item.children) {
        deepFun(item.children)
      }
    })
  }

  deepFun(list)
  return list
}

const App = (props) => {
  const [treeData, setTreeData] = useState()
  const [value, setValue] = useState()

  useEffect(() => {
    let val = props.pid ? props.pid : null
    setValue(val)
  }, [props.pid])

  //刷新上级树
  useEffect(() => {
    if (props.visble) {
      getBuildAll().then((res) => {
        const resData = deepArr(res.data)
        setTreeData(resData)
      })
    }
  }, [props.visble])

  const onChange = (newValue) => {
    setValue(newValue)
    props.setPid(newValue)
  }

  return (
    <TreeSelect
      allowClear
      style={{
        width: '100%'
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto'
      }}
      treeData={treeData}
      placeholder="请选择上级目录"
      treeDefaultExpandAll
      onChange={onChange}
    />
  )
}

export default App
