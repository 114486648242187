import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styles from './style/index.module.scss'
import User from './component/user'
import { useRecoilValue } from 'recoil'
import recoilStore from '@/recoilStore'
const logoPng = require('@/assets/img/logo.png')

const addHttp = (path) => {
  return path.includes('http://') || path.includes('https://')
    ? path
    : 'http://' + path
}
const deepRoute = (arr) => {
  arr.forEach((item) => {
    item.key = item.path !== 'noPath' ? item.path : Math.random()
    item.label = (
      <>
        {item.is_frame ? (
          <a href={addHttp(item.path)} target="_blank" rel="noreferrer">
            {item.label}
          </a>
        ) : (
          <>
            {item.path !== 'noPath' && item.path !== '' ? (
              <Link to={item.path !== 'noPath' ? item.path : ''}>
                <span style={{ color: '#303033' }}>{item.label}</span>
              </Link>
            ) : (
              <span style={{ color: '#303033' }}>{item.label}</span>
            )}
          </>
        )}
        {item.children && item.children.length > 0 && item.type === 0 && (
          <DownOutlined />
        )}
      </>
    )
    if (item.children && item.children.length > 0) {
      deepRoute(item.children, false)
    } else {
      item.children = null
    }
  })
}
function Index(props) {
  const [items, setItems] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  let { pathname } = useLocation()
  const showMeanList = useRecoilValue(recoilStore.showMeanList)

  useMemo(() => {
    const newList = JSON.parse(JSON.stringify(showMeanList))
    if (newList && newList.length) {
      newList.unshift({
        label: '首页',
        path: '/home'
      })
      deepRoute(newList)
      setItems(newList)
      setTimeout(() => {
        setSelectedKeys([pathname])
      })
    }
  }, [showMeanList])
  const [shadow] = useState(props.shadow)
  return (
    <div
      className={styles.header}
      style={{
        boxShadow: shadow ? '0px 2px 6px 0px rgba(0,0,0,0.08)' : 'none'
      }}
    >
      <div className="headerWrapper">
        <div className="headerWrapper-left">
          <div className="logo">
            <Link to="/home">
              <img src={logoPng} alt="" />
            </Link>
          </div>
          <div className="menu-box">
            <Menu
              mode="horizontal"
              items={items}
              selectedKeys={selectedKeys}
            ></Menu>
          </div>
        </div>
        <User />
      </div>
    </div>
  )
}
export default Index
