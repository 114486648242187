export const abilityIndex = [
  {
    title: '能力/平台',
    dataIndex: 'platform',
    key: 'platform'
  },
  {
    title: '抖音',
    dataIndex: 'TikTok',
    key: 'TikTok'
  },
  {
    title: '快手',
    dataIndex: 'nimble-handedPerson',
    key: 'nimble-handedPerson'
  },
  {
    title: '哔哩哔哩',
    dataIndex: 'bilibili',
    key: 'bilibili'
  },
  {
    title: '微博',
    dataIndex: 'Weibo',
    key: 'Weibo'
  },
  {
    title: '小红书',
    dataIndex: 'TikTok',
    key: 'TikTok'
  },
  {
    title: '西瓜',
    dataIndex: 'ixigua',
    key: 'ixigua'
  },
  {
    title: '优酷',
    dataIndex: 'Youku',
    key: 'Youku'
  },
  {
    title: '腾讯',
    dataIndex: 'Tencent',
    key: 'Tencent'
  },
  {
    title: '好看',
    dataIndex: 'Haokan',
    key: 'Haokan'
  },
  {
    title: '搜狐',
    dataIndex: 'Sohu',
    key: 'Sohu'
  },
  {
    title: '微信公众号',
    dataIndex: 'WechatPublicAccount',
    key: 'WechatPublicAccount'
  },
  {
    title: 'Twitter',
    dataIndex: 'Twitter',
    key: 'Twitter'
  },
  {
    title: 'YouTube',
    dataIndex: 'YouTube',
    key: 'YouTube'
  }
]
export const abilityData = [
  {
    key: 'abilityData1',
    platform: '通过账号搜索采集资源',
    TikTok: '√',
    'nimble-handedPerson': '√',
    bilibili: '√',
    Weibo: '√',
    Xiaohongshu: 'false',
    ixigua: '√',
    Youku: '√',
    Tencent: '√',
    Haokan: '√',
    Sohu: '√',
    WechatPublicAccount: 'false',
    Twitter: 'false',
    YouTube: 'false'
  },
  {
    key: 'abilityData2',
    platform: '通过关键词搜索采集资源',
    TikTok: '√',
    'nimble-handedPerson': '√（仅版权）',
    bilibili: '√',
    Weibo: '√',
    Xiaohongshu: '',
    ixigua: '√',
    Youku: '√',
    Tencent: '√',
    Haokan: '√',
    Sohu: '√',
    WechatPublicAccount: '',
    Twitter: '',
    YouTube: ''
  },
  {
    key: 'abilityData3',
    platform: '采集视频评论',
    TikTok: '√',
    'nimble-handedPerson': '√（仅版权）',
    bilibili: '√',
    Weibo: '√',
    Xiaohongshu: '',
    ixigua: '√',
    Youku: '',
    Tencent: '',
    Haokan: '',
    Sohu: '',
    WechatPublicAccount: '',
    Twitter: '',
    YouTube: ''
  },
  {
    key: 'abilityData4',
    platform: '通过关键词搜索采集账号',
    TikTok: '√',
    'nimble-handedPerson': '√',
    bilibili: '√',
    Weibo: '√',
    Xiaohongshu: '',
    ixigua: '√',
    Youku: '',
    Tencent: '',
    Haokan: '',
    Sohu: '',
    WechatPublicAccount: '',
    Twitter: '',
    YouTube: ''
  },
  {
    key: 'abilityData5',
    platform: '采集热榜',
    TikTok: '√',
    'nimble-handedPerson': '',
    bilibili: '',
    Weibo: '√',
    Xiaohongshu: '',
    ixigua: '',
    Youku: '',
    Tencent: '',
    Haokan: '',
    Sohu: '',
    WechatPublicAccount: '',
    Twitter: '',
    YouTube: ''
  },
  {
    key: 'abilityData6',
    platform: '采集导入的资源',
    TikTok: '√',
    'nimble-handedPerson': '√',
    bilibili: '√',
    Weibo: '√',
    Xiaohongshu: '√',
    ixigua: '√',
    Youku: '√',
    Tencent: '√',
    Haokan: '√',
    Sohu: '√',
    WechatPublicAccount: '√',
    Twitter: '√',
    YouTube: '√'
  },
  {
    key: 'abilityData7',
    platform: '通过关键词搜索采集话题',
    TikTok: '',
    'nimble-handedPerson': '',
    bilibili: '',
    Weibo: '',
    Xiaohongshu: '',
    ixigua: '',
    Youku: '',
    Tencent: '',
    Haokan: '',
    Sohu: '',
    WechatPublicAccount: '',
    Twitter: '',
    YouTube: ''
  },
  {
    key: 'abilityData8',
    platform: '通过搜索框联想词汇采集资源',
    TikTok: '',
    'nimble-handedPerson': '',
    bilibili: '',
    Weibo: '',
    Xiaohongshu: '',
    ixigua: '',
    Youku: '',
    Tencent: '',
    Haokan: '',
    Sohu: '',
    WechatPublicAccount: '',
    Twitter: '',
    YouTube: ''
  }
]
// 标准能力接口
export const abilityAPI = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 180
  },
  {
    title: '类型',
    dataIndex: 'status',
    key: 'status',
    width: 70
  },
  {
    title: '链接',
    dataIndex: 'linkTo',
    key: 'linkTo',
    render: (linkTo) => {
      return (
        <a target="_blank" href={linkTo} rel="noreferrer">
          {linkTo}
        </a>
      )
    },
    width: 320
  },
  {
    title: '支持',
    dataIndex: 'support',
    key: 'support',
    width: 450
  }
]
export const abilityAPIData = [
  {
    key: 'abilityAPIData1',
    name: '用户搜索对接协议',
    status: '标准化',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcnEcAJqmxyJDTLDxkDaTFbxe',
    support: '支持：抖音、哔哩哔哩、微博、快手、西瓜、优酷、腾讯、好看、搜狐'
  },
  {
    key: 'abilityAPIData2',
    name: '关键词搜索对接协议',
    status: '标准化',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcn49PnkBEmS4GRBHCyOMFM8g',
    support:
      '抖音、好看、搜狐、哔哩哔哩、优酷、西瓜、微博、腾讯、快手（仅限版权）'
  },
  {
    key: 'abilityAPIData3',
    name: '用户作品采集对接协议',
    status: '标准化',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcnOKkf7KpOASBrSVBQunIoVh',
    support: '支持：抖音、哔哩哔哩、西瓜、微博、快手（仅限版权）'
  },
  {
    key: 'abilityAPIData4',
    name: '视频评论采集对接协议',
    status: '标准化',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcnU0SmgHgXeHbCMj7HpOY4XP',
    support: '支持：抖音、哔哩哔哩、西瓜、微博、快手（仅限版权）'
  },
  {
    key: 'abilityAPIData5',
    name: '数据导入采集接口',
    status: '标准化',
    linkTo: 'https://yfobh10b4d.feishu.cn/docs/doccnuz3d8cssWJAUCzLPxh2ckh',
    support: '支持：抖音、哔哩哔哩、西瓜、微博、快手（仅限版权）'
  }
]

// 非标准能力接口
export const unAbilityAPI = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 180
  },
  {
    title: '类型',
    dataIndex: 'status',
    key: 'status',
    width: 70
  },
  {
    title: '链接',
    dataIndex: 'linkTo',
    key: 'linkTo',
    width: 320,
    render: (linkTo) => {
      return (
        <a target="_blank" href={linkTo} rel="noreferrer">
          {linkTo}
        </a>
      )
    }
  },
  {
    title: '说明',
    dataIndex: 'support',
    key: 'support',
    width: 450
  }
]

export const unAbilityAPIData = [
  {
    key: 'unAbilityAPIData1',
    name: '话题搜索对接协议',
    status: '项目',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcntTo6HlZiRmTWiSJV2o6tmc',
    support: '采集关键词相关话题下的视频数据'
  },
  {
    key: 'unAbilityAPIData2',
    name: '全站采集-审核服务对接协议',
    status: '项目',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcn49PnkBEmS4GRBHCyOMFM8g',
    support: ''
  },
  {
    key: 'unAbilityAPIData3',
    name: '审核平台采集推送协议',
    status: '项目',
    linkTo: 'https://yfobh10b4d.feishu.cn/docs/doccnLn5FwQdQYBeFkQ6wHyXCDg',
    support: ''
  },
  {
    key: 'unAbilityAPIData4',
    name: '巡检工具箱采集数据对接协议',
    status: '项目',
    linkTo: 'https://yfobh10b4d.feishu.cn/docs/doccnLn5FwQdQYBeFkQ6wHyXCDg',
    support:
      '舆情、时政、侵权检测（采集视觉中国）、企业媒体矩阵（采集抖音快手微博公众号网站）、正文提取'
  },
  {
    key: 'unAbilityAPIData5',
    name: '风控系统采集功能',
    status: '项目',
    linkTo: 'https://yfobh10b4d.feishu.cn/docs/doccnTJhMLm0LRDOQ9jwd4s3466',
    support: ''
  },
  {
    key: 'unAbilityAPIData6',
    name: '长城新媒体采集对接协议',
    status: '项目',
    linkTo: 'https://yfobh10b4d.feishu.cn/docx/doxcn9MEXPdZlZm6lCnVYmZ72dh',
    support:
      '账号搜索（抖音、快手、微博）、作品采集（抖音、快手、微博）、全站采集'
  }
]

export const demoJson = {
  projectUserId: '18',
  projectTopics: [
    {
      topicType: 'bcw9tp',
      topicId: '18',
      keywords: ['f6f2gy', 'hbc9qf']
    }
  ],
  title: 'ws3dkz',
  ocr: 'bln148',
  ocrAll: 'vzp0za',
  asr: 'va47dx',
  textConcat: 'd8iofe',
  titlePhrase: '4osi4d',
  ocrPhrase: '8lbcyz',
  ocrAllPhrase: '1ddg5n',
  asrPhrase: '2if5b3',
  textConcatPhrase: 'js7svr',
  aiTypes: [
    {
      type: 171,
      resultScore: 60.54
    }
  ],
  siteIds: ['mypeo2', 'zs4fsg'],
  emotions: [566, 88],
  fansNumMin: 995,
  fansNumMax: 375,
  readNumMin: 231,
  readNumMax: 779,
  repeatNumMin: 307,
  repeatNumMax: 97,
  commentNumMin: 784,
  commentNumMax: 760,
  likeNumMin: 214,
  likeNumMax: 762,
  mediaType: 881,
  publishTimeStart: 'l305jt',
  publishTimeEnd: 'k5n25j',
  authorName: '越彬.熊',
  authorIds: ['jprrwn', 'tbz37q'],
  processStatusMin: 494,
  processStatusMax: 51,
  districtCode: '70796',
  pageNum: 916,
  pageSize: 10,
  publishTimeSort: 'wu82ed',
  likeNumSort: '362s3x',
  fansNumSort: 'yl456e',
  readNumSort: 'ng3clk',
  repeatNumSort: 'b8qkb3',
  commentNumSort: 'nhwa9v',
  excludeMediaType: 'p108ad',
  excludeDistrictCode: '70796',
  excludeAuthorIds: ['4nfy0v', 'dkh3qe'],
  vip: 571,
  excludeSiteIds: ['2jom3u', 'q5fa6w'],
  excludeVip: 930,
  dcAuthorIds: ['5fteg9', '4e6hlu'],
  shouldParam: [
    {
      vip: 977,
      mediaType: 178,
      authorName: '越彬.熊',
      authorId: '18',
      authorIds: ['27gw8i', 'wou2p9'],
      districtCodes: ['iw9bdx', 'tya95e']
    }
  ],
  districtCodes: ['vhixzu', 'gbkdnd'],
  excludeDistrictCodes: ['h8efc3', 'bdv0ix'],
  projectUserIds: ['w14ubw', 'gsgp7g'],
  resourceIds: ['r495l1', 'ct4c8y'],
  dcResourceIds: ['eb8cvg', 'dfhmka']
}

const apiData = {
  abilityIndex,
  abilityData,
  abilityAPI,
  abilityAPIData,
  unAbilityAPI,
  unAbilityAPIData
}
export default apiData
