// eslint-disable-next-line
import moduleStyle from './index.module.scss'
import { useState } from 'react'
import ContentComponent from './components/content/index'
import { Space, Input, message, Spin } from 'antd'
import { getVerifyDetail } from '@/api/list/sourceVerify'
const menuList = [
  {
    name: '风控ID',
    value: 1
  },
  {
    name: '中台ID',
    value: 2
  },
  {
    name: '文本',
    value: 3
  }
]

function SourceVerify() {
  const [menuActive, setMenuActive] = useState(1)
  const [inputValue, setInputValue] = useState()
  const [detailData, setDetailData] = useState()
  const [defaultDetailData, setDefaultDetailData] = useState()
  const [loading, setLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [labelCode, setLabelCode] = useState()
  const menuClick = (menu) => {
    // 切换tab并清空内容，重置输入框
    if (menuActive === menu.value) return
    setInputValue()
    setDetailData()
    setLabelCode()
    setMenuActive(menu.value)
  }
  const verifySubmit = () => {
    // 验证
    if (inputValue) {
      setLoading(true)
      const params = {}
      if (menuActive === 1) {
        params.riskControlId = inputValue
      }
      if (menuActive === 2) {
        params.yingLongId = inputValue
      }
      if (menuActive === 3) {
        params.text = inputValue
      }
      getVerifyDetail(params)
        .then((res) => {
          if (+res.code === 0) {
            setLabelCode()
            setDetailData(res.data)
            if (res.data?.dataDetailList[0]) {
              setDefaultDetailData(res.data.dataDetailList[0])
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      messageApi.info(menuActive === 3 ? '请输入文本内容' : '请输入资源ID')
    }
  }

  return (
    <>
      {contextHolder}
      <div className={moduleStyle.container}>
        <div className={moduleStyle.title}>资源验证</div>
        <Spin spinning={loading}>
          <div className={moduleStyle.menuList}>
            <Space size={10}>
              {menuList.map((mp) => {
                return (
                  <span
                    key={mp.value}
                    className={`${moduleStyle.menuItem} ${
                      mp.value === menuActive ? moduleStyle.menuActive : ''
                    }`}
                    onClick={() => menuClick(mp)}
                  >
                    {mp.name}
                  </span>
                )
              })}
            </Space>

            <Space size={8}>
              <Input
                style={{ width: menuActive === 3 ? 599 : 256 }}
                placeholder={menuActive === 3 ? '文本内容' : '资源ID'}
                className={moduleStyle.input}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
              />

              <div className={moduleStyle.verify} onClick={verifySubmit}>
                验证
              </div>
            </Space>
          </div>
          {detailData ? (
            <ContentComponent
              menuActive={menuActive}
              detailData={detailData}
              defaultDetailData={defaultDetailData}
              labelCode={labelCode}
              setLabelCode={setLabelCode}
            ></ContentComponent>
          ) : (
            <img
              className={moduleStyle.defaultIcon}
              src="/imgs/sourceVerify/default-icon.svg"
              alt=""
            />
          )}
        </Spin>
      </div>
    </>
  )
}

export default SourceVerify
