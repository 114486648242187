// block
export const MAP_BLOCKTYPE_TO_NUMBER = {
  page: 1, // 页面 √
  text: 2, // 文本 √
  heading1: 3, // 标题 √
  heading2: 4, // 标题 √
  heading3: 5, // 标题 √
  heading4: 6, // 标题 √
  heading5: 7, // 标题 √
  heading6: 8, // 标题 √
  heading7: 9, // 标题 √
  heading8: 10, // 标题 √
  heading9: 11, // 标题 √
  bullet: 12, //无序列表 Block √
  ordered: 13, //有序列表 Block √
  code: 14, //代码块 Block √
  quote: 15, //引用 Block
  equation: 16, // 公式
  todo: 17, //待办事项 Block
  bitable: 18, //多维表格 Block
  callout: 19, //高亮块 Block
  chat_card: 20, //会话卡片 Block
  diagram: 21, //流程图 & UML Block
  divider: 22, //分割线 Block
  file: 23, //文件 Block
  grid: 24, //分栏 Block √
  grid_column: 25, //分栏列 Block √
  iframe: 26, //内嵌 Block Block
  image: 27, //图片 Block √
  isv: 28, //开放平台小组件 Block
  mindnote: 29, //思维笔记 Block
  sheet: 30, //电子表格 Block
  table: 31, // 表格 Block √
  table_cell: 32, // 表格单元格 Block √
  view: 33, //视图 Block
  quote_container: 34, //引用容器 Block
  task: 35, //任务 Block
  okr: 36, //OKR Block
  okr_objective: 37, //OKR Objective Block
  okr_key_result: 38, //OKR Key Result Block
  okr_progress: 39, //OKR 进展 Block
  un_support: 999 //未支持 Block √
}
const mapBlockToType = new Map()
Object.keys(MAP_BLOCKTYPE_TO_NUMBER).forEach((key) => {
  mapBlockToType.set(MAP_BLOCKTYPE_TO_NUMBER[key], key)
})
export const MAP_BLOCKNUMBER_TO_TYPE = mapBlockToType

// code language
export const MAP_CODE_LANGUAGE_TO_NUMBER = {
  java: 29,
  typescript: 63,
  python: 49,
  json: 28
}
const mapCodeNumberToLanguage = new Map()
Object.keys(MAP_CODE_LANGUAGE_TO_NUMBER).forEach((key) => {
  mapCodeNumberToLanguage.set(MAP_CODE_LANGUAGE_TO_NUMBER[key], key)
})
export const MAP_CODE_NUMBER_TO_LANGUAGE = mapCodeNumberToLanguage
