import moduleStyle from './style/index.module.scss'
import Banner from '@/components/banner'

const list = [
  {
    label: 'CDH管理',
    ico: require('./img/ico_1.png'),
    dev_url: 'http://192.168.3.26:7180/',
    pro_url: 'http://172.17.218.127:7180/'
  },
  {
    label: 'ElastucSearch-kibana',
    ico: require('./img/ico_2.png'),
    dev_url: 'http://192.168.3.26:5601/',
    pro_url: 'http://172.17.126.171:5601/'
  },
  {
    label: 'kafka-eagle',
    ico: require('./img/ico_3.png'),
    dev_url: 'http://192.168.3.26:8048/',
    pro_url: 'http://172.17.218.128:8048/'
  },
  {
    label: 'springADMIN',
    ico: require('./img/ico_4.png'),
    dev_url: '',
    pro_url: ''
  },
  {
    label: 'GitLab',
    ico: require('./img/ico_5.png'),
    dev_url: 'http://192.168.3.221:81/',
    pro_url: 'http://192.168.3.221:81/'
  }
]

const env_list = ['开发环境', '线上环境']

const Open = ({ item, envItem }) => {
  let url = envItem === '开发环境' ? item.dev_url : item.pro_url
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {' '}
    </a>
  ) : (
    <></>
  )
}
const banner = require('./img/banner.png')
const dec =
  'Hadoop大数据系统使用CDH平台。全文检索使用ElasticSearch7.14.0。消息中间件使用Kafka。'
function Index() {
  return (
    <div className={moduleStyle.visualNavigation}>
      <Banner banner={banner} label="开发工具介绍" dec={dec} />
      <div className="row-wrapper">
        {env_list.map((envItem, envIndex) => (
          <div className="row" key={envIndex}>
            <div className="row-tit">{envItem}</div>
            <div className="row-content">
              {list.map((item, index) => (
                <div className="item-box" key={index}>
                  <Open item={item} envItem={envItem} />
                  <img src={item.ico} alt="" />
                  <span className="label-box">{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Index
