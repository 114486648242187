import { useState } from 'react'
import moduleStyle from './style/index.module.scss'
import { CaretUpOutlined } from '@ant-design/icons'
import TrendAnalysis from './component/trendAnalysis'

const linkArr = [
  {
    ico: require('./img/trend-analysis.png'),
    label: '趋势分析',
    component: TrendAnalysis,
    isShow: true
  },
  {
    ico: require('./img/product-design.png'),
    label: '产品设计',
    isShow: false
  },
  { ico: require('./img/product-dev.png'), label: '项目开发', isShow: false },
  {
    ico: require('./img/product-dock.png'),
    label: '外部项目对接',
    isShow: false
  },
  {
    ico: require('./img/microservice.png'),
    label: '数据中台微服务开发',
    isShow: false
  },
  {
    ico: require('./img/big-data.png'),
    label: '数据中台大数据对接',
    isShow: false
  },
  {
    ico: require('./img/other-service.png'),
    label: '其他数据服务开发',
    isShow: false
  }
]
function Index() {
  const [linkArrState, setLinkArr] = useState(linkArr)
  const [showComponent, setShowComponent] = useState(TrendAnalysis)

  const onMouseEnterFn = (i) => {
    linkArr.forEach((item, index) => {
      if (i === index) {
        item.isShow = true
        setShowComponent(item.component)
      } else {
        item.isShow = false
      }
    })
    setLinkArr([...linkArr])
  }

  return (
    <div className={moduleStyle.functionsWrapperBox}>
      <div className="row">
        <div className="row-tit">平台业务介绍</div>
        <div className="row-content">
          {linkArrState.map((item, index) => (
            <div
              className={`item-box ${item.isShow ? 'checked' : ''}`}
              key={index}
              onMouseEnter={() => onMouseEnterFn(index)}
            >
              <div className="img-box">
                <img src={item.ico} alt="" />
              </div>
              <div className="label-name">{item.label}</div>
              <CaretUpOutlined className="blue-ico" />
            </div>
          ))}
        </div>
      </div>
      <div className="component-box">{showComponent}</div>
    </div>
  )
}

export default Index
