import { useParams } from 'react-router-dom'
function DocHtml() {
  const id = useParams().id
  console.log(2313, useParams())
  const onLoad = () => {
    const docIframe = document.getElementById('docIframe')
    const subWeb = document.frames
      ? document.frames['iframeId'].document
      : docIframe.contentDocument
    if (docIframe != null && subWeb != null) {
      docIframe.style.height = 'auto' //关键这一句，先取消掉之前iframe设置的高度
      console.log(4434444, subWeb.body.clientHeight)
      docIframe.style.height = subWeb.body.scrollHeight + 60 + 'px'
    }
  }
  return (
    <iframe
      title="doc"
      id="docIframe"
      onLoad={onLoad}
      src={process.env.PUBLIC_URL + `/doc/${id}.html`}
      frameBorder="0"
      width="100%"
      style={{ overflow: 'hidden', background: '#ffffff' }}
    ></iframe>
  )
}
export default DocHtml
