import moduleStyle from '../index.module.scss'
import EditTagInfo from './editTagInfo'
import cn from 'classnames'
import { Input, Checkbox, Row, Col, Button, message } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import TagVerifyStore from '@/store/tagVerify'
import { isEmpty } from 'lodash'
import { syncLabel } from '@/api/list/tagVerify'
const expandPng = require('@/assets/img/expand.png')
const matchPosition = [
  {
    name: '标题',
    value: 'title'
  },
  {
    name: '简介',
    value: 'intro'
  },
  {
    name: '封面OCR',
    value: 'ocrCover'
  },
  {
    name: '视频OCR',
    value: 'ocrFrame'
  },
  {
    name: 'ASR',
    value: 'asr'
  },
  {
    name: '作者名称',
    value: 'author'
  },
  {
    name: 'IP属地',
    value: 'ipAdress'
  },
  {
    name: '内容提及地',
    value: 'adressMentioned'
  },
  {
    name: '用户注册地',
    value: 'registeredAddress'
  },
  {
    name: '定位地点',
    value: 'signinAddress'
  }
]

const matchLocation = {
  2: '整句',
  1: '半句',
  0: '紧挨',
  3: '段落',
  4: '不限',
  5: '自定义'
}
const inOrder = {
  1: '有序',
  0: '无序'
}

function LeftCondition() {
  const tagVerifyState = TagVerifyStore()
  const { setLeftConditionShrink, setTagListShrink, lableInfo, setEditModal } =
    tagVerifyState

  const expandMenu = () => {
    setLeftConditionShrink(false)
    setTagListShrink(true)
  }

  const editTagInfo = () => {
    if (isEmpty(lableInfo)) return message.error('请选择标签')
    setEditModal(true)
  }

  const syncLabelFun = () => {
    syncLabel({ sourceLabelId: lableInfo.sourceLabelId })
      .then((res) => {
        if (+res.code === 0) {
          message.success('同步成功')
        }
      })
      .catch((err) => {
        message.error('同步失败')
      })
  }

  return (
    <div className={moduleStyle.leftWrap}>
      {tagVerifyState.tagListShrink && tagVerifyState.leftConditionShrink ? (
        <div className={moduleStyle.float} onClick={expandMenu}>
          <img src={expandPng} alt="expand" />
        </div>
      ) : null}
      <div
        className={cn(moduleStyle.leftCondition, {
          hidden: tagVerifyState.leftConditionShrink
        })}
      >
        <div className={moduleStyle.title}>
          <span>标签逻辑</span>
          <LeftOutlined
            className={moduleStyle.shrink}
            onClick={() => setLeftConditionShrink(true)}
          />
        </div>
        <div className={moduleStyle.scrollWrap}>
          {lableInfo.exprList?.map((item, index) => (
            <div className={moduleStyle.box}>
              <div className={moduleStyle.top}>
                <div className={moduleStyle.conditionTitle}>
                  <div>表达式{index + 1}</div>
                  <div>
                    （{matchLocation[item.matchLocation]}，
                    {inOrder[item.inOrder]}）
                  </div>
                </div>
                <Input
                  value={item.expr}
                  readOnly
                  className={moduleStyle.input}
                />
              </div>
              {item.negExprs?.map((it, j) => (
                <div className={moduleStyle.bottom}>
                  <div className={moduleStyle.conditionTitle}>
                    <div>排除词{j + 1}</div>
                    <div>
                      （{matchLocation[it.negExprMl]}，
                      {inOrder[it.negExprOrder]}）
                    </div>
                  </div>
                  <Input
                    value={it.negExpr}
                    readOnly
                    className={moduleStyle.input}
                  />
                </div>
              ))}
            </div>
          ))}
          {lableInfo.allExcluExpr || lableInfo.ambiguousExpr ? (
            <>
              <div className={moduleStyle.title}>整体过滤</div>
              <div className={moduleStyle.box}>
                <div className={moduleStyle.top}>
                  <div className={moduleStyle.conditionTitle}>
                    <div>排除词</div>
                    <div>
                      （{matchLocation[lableInfo.allExcluExpr?.matchLocation]}，
                      {inOrder[lableInfo.allExcluExpr?.inOrder]}）
                    </div>
                  </div>
                  <Input
                    value={lableInfo.allExcluExpr?.expr}
                    readOnly
                    className={moduleStyle.input}
                  />
                </div>
                <div className={moduleStyle.bottom}>
                  <div className={moduleStyle.conditionTitle}>
                    <div>歧义词</div>
                    <div>
                      （{matchLocation[lableInfo.ambiguousExpr?.matchLocation]}
                      ，{inOrder[lableInfo.ambiguousExpr?.inOrder]}）
                    </div>
                  </div>
                  <Input
                    value={lableInfo.ambiguousExpr?.expr}
                    readOnly
                    className={moduleStyle.input}
                  />
                </div>
              </div>
            </>
          ) : null}

          {lableInfo.locations?.length ? (
            <>
              <div className={moduleStyle.title}>匹配位置</div>
              <div className={cn(moduleStyle.box, moduleStyle.pb0)}>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  value={lableInfo.locations}
                >
                  <Row>
                    {matchPosition.map((item) => (
                      <Col span={8}>
                        <Checkbox value={item.value}>{item.name}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </div>
            </>
          ) : null}
        </div>

        <div className={moduleStyle.buttonWrap}>
          <Button
            className={moduleStyle.handleButton}
            danger
            onClick={editTagInfo}
          >
            编辑
          </Button>
          <Button
            onClick={syncLabelFun}
            className={moduleStyle.handleButton}
            type="primary"
            danger
          >
            同步
          </Button>
        </div>
      </div>
      <EditTagInfo />
    </div>
  )
}

export default LeftCondition
