import style from './index.module.scss'
function Index(props) {
  const { banner, dec, label } = props
  return (
    <div
      className={style.banner}
      style={{ backgroundImage: `url('${banner}')` }}
    >
      <div className="banner-box">
        <div className="banner-title">{label}</div>
        {dec ? <div className="banner-dec">{dec}</div> : ''}
      </div>
    </div>
  )
}

export default Index
