// callout: 19, 高亮块
import blockStyle from './callout.module.scss'
import Block from '../block'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'
import emoji from '@emoji-mart/data'
import { init } from 'emoji-mart'
init({ emoji })
export default function Callout(props) {
  const { blockData } = props
  let emojiID = '+1'
  const ele = blockData[blockData.block_type_name]

  const blockClassName = () => {
    const styleArray = []
    styleArray.push(blockStyle.callout)
    if (ele.background_color) {
      styleArray.push(blockStyle[`background-color-${ele.background_color}`])
    }
    if (ele.border_color) {
      styleArray.push(blockStyle[`border-color-${ele.border_color}`])
    }
    return styleArray.join(' ')
  }

  if (ele.emoji_id) emojiID = ele.emoji_id
  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)
  const children = blockData.children.map((childId, index) => {
    return <Block key={childId} blockData={blockItemMap[childId]}></Block>
  })

  const calloutBlock = (
    <div className={blockClassName()}>
      <div className={blockStyle.emojiContainer}>
        <em-emoji className={blockStyle.emoji} id={emojiID}></em-emoji>
      </div>
      <div className={blockStyle.childrenContainer}>{children}</div>
    </div>
  )
  return calloutBlock
}
