import { useState } from 'react'
import moduleStyle from './index.module.scss'
import Statistics from '../update/statistics'
import imgError from 'components/imgError'
function Index(props) {
  const [data] = useState(props.data)

  return (
    <div className={moduleStyle.comment}>
      <div className="title">评论互动</div>
      <div className="row">
        <img
          className="avatarUrl"
          src={data.commentAuthor.avatarUrl}
          alt=""
          onError={imgError}
        />

        <div className="info-box">
          <div className="author-statistics">
            <span className="authorName">{data.commentAuthor.authorName}</span>
            <div className="statistics">
              <span>
                <Statistics type="read" total={data.readNum} />
              </span>
              <span>
                <Statistics type="repeat" total={data.repeatNum} />
              </span>
              <span>
                <Statistics type="comment" total={data.commentNum} />
              </span>
              <span>
                <Statistics type="like" total={data.likeNum} />
              </span>
            </div>
          </div>

          <div className="content">评论正文 {data.content}</div>
        </div>
      </div>
    </div>
  )
}
export default Index
