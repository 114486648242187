import { useState } from 'react'
import moduleStyle from './index.module.scss'

import Statistics from './statistics'
import platBtn from './img/play.png'

import { timeToMinute } from '@/untils/time'

import imgError from 'components/imgError'

function Index(props) {
  const [data] = useState(props.data)

  return (
    <div className={moduleStyle.update}>
      <div className="title">爬虫采集入库</div>
      <div className="resource">
        <div className="author">
          <img
            style={{ width: '40px' }}
            src={
              data.collect.videoAuthor ? data.collect.videoAuthor.avatarUrl : ''
            }
            onError={imgError}
            alt=""
          />
          <span className="authorName">
            {data.collect.videoAuthor
              ? data.collect.videoAuthor.authorName
              : ''}
          </span>
        </div>

        <div className="resourceCover">
          <div className="resourceCover-img">
            <a
              href={data.collect.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="playBtn" src={platBtn} alt="" />
              <img
                className="cover-img"
                src={data.collect.resourceCoverUrl}
                onError={imgError}
                alt=""
              />
            </a>
            <div className="resource-number">
              <div>视频编号:{data.dcResourceId}</div>
              <div>{timeToMinute(data.collect.duration)}</div>
            </div>
          </div>
          <div className="statistics">
            <span>
              <Statistics type="read" total={data.collect.readNum} />
            </span>
            <span>
              <Statistics type="repeat" total={data.collect.repeatNum} />
            </span>
            <span>
              <Statistics type="comment" total={data.collect.commentNum} />
            </span>
            <span>
              <Statistics type="like" total={data.collect.likeNum} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
