import exportExcel from '@/untils/exportExcel'

const exportData = (fileName, listData, columns) => {
  //转换成
  const render_name = {
    enabled: (val) => (val === 1 ? '开启' : '关闭'),
    userNames: (val) => val && val.length + `   [${val.join(',')}] `,
    isFrame: (val) => (val === 1 ? '是' : '否'),
    hidden: (val) => (val === 0 ? '是' : '否')
  }

  let data = JSON.parse(JSON.stringify(listData))

  data.forEach((item) => {
    for (let i in render_name) {
      if (Object.prototype.hasOwnProperty.apply(item, i)) {
        item[i] = render_name[i](item[i])
      }
    }
  })

  const sheetFilter = []
  const sheetHeader = []
  columns.forEach((item) => {
    sheetFilter.push(item.dataIndex)
    sheetHeader.push(item.title)
  })

  let option = {
    fileName,
    data,
    sheetFilter,
    sheetHeader
  }
  exportExcel(option)
}

export default exportData
