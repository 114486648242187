import moduleStyle from './index.module.scss'
import { Space, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { initPlatform, copyFormattedText, downloadVideo } from '@/utils/common'
const ArticleComponent = (props) => {
  /**
   * @name props
   * @desc 父级参数
   * @param {object} detailData 视频数据
   */
  if (!props.detailData) {
    return
  }
  const copyVideoUrl = () => {
    // 复制视频链接
    if (!props.detailData.videos?.length) return
    const urls = props.detailData.videos.map((video) => {
      return video.url
    })
    copyFormattedText(urls.join(','))
    message.success('复制成功')
  }
  const downLoadVideos = () => {
    // 下载视频
    if (!props.detailData.videos?.length) return
    props.detailData.videos.forEach((video) => {
      downloadVideo(video.url, 'video.mp4')
    })
  }

  const copyVideoInfo = () => {
    // 复制视频信息
    const data = {
      标题: props.detailData.title?.replace(/<[^>]+>/g, ''),
      涉及地区: props.detailData.contentRegions?.replace(/<[^>]+>/g, ''),
      IP属地: props.detailData.ipPlace?.replace(/<[^>]+>/g, ''),
      用户注册地: props.detailData.authorDistrict?.replace(/<[^>]+>/g, ''),
      用户签到地: props.detailData.district?.replace(/<[^>]+>/g, ''),
      链接: props.detailData.videos?.map((item) => item.url).join(','),
      时间: props.detailData.publishTime,
      来源: props.detailData.siteName?.replace(/<[^>]+>/g, ''),
      作者: props.detailData.authorName?.replace(/<[^>]+>/g, '')
    }
    const format = `
    标题：${data.标题}
    涉及地区：${data.涉及地区}
    IP属地：${data.IP属地}
    用户注册地：${data.用户注册地}
    用户签到地：${data.用户签到地}
    链接：${data.链接}
    时间：${data.时间}
    来源：${data.来源}
    作者：${data.作者}`
    copyFormattedText(format)
    message.success('复制成功')
  }
  return (
    <div className={moduleStyle.article}>
      <Space size={12}>
        <div className={moduleStyle.articleButton} onClick={copyVideoInfo}>
          <img src="/imgs/sourceVerify/copy.svg" alt="" />
          <div>复制资源信息</div>
        </div>
        <a
          href={props.detailData?.webUrl}
          className={moduleStyle.articleButton}
          target="_blank"
          rel="noreferrer"
        >
          <img src="/imgs/sourceVerify/link.svg" alt="" />
          <div>查看原文</div>
        </a>
        <div className={moduleStyle.articleButton} onClick={copyVideoUrl}>
          <img src="/imgs/sourceVerify/copy.svg" alt="" />
          <div>复制资源链接</div>
        </div>
        {props.detailData.videos?.length > 0 ? (
          <div className={moduleStyle.articleButton} onClick={downLoadVideos}>
            <DownloadOutlined style={{ fotnSize: 14 }} />
            <div>下载视频</div>
          </div>
        ) : (
          ''
        )}
      </Space>
      <div
        className={moduleStyle.articleTitle}
        dangerouslySetInnerHTML={{ __html: props.detailData.title || '-' }}
      />
      <div className={moduleStyle.author}>
        {initPlatform(
          props.detailData.siteId,
          props.detailData.siteName,
          moduleStyle.platform
        )}
        <span
          dangerouslySetInnerHTML={{ __html: props.detailData.siteName || '-' }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: props.detailData.authorName || '-'
          }}
        />
        <span>{props.detailData.publishTime || '-'}</span>
        <span>数据ID：{props.detailData.siteAuthorId || '-'}</span>
      </div>
      <div
        className={moduleStyle.author}
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          marginBottom: 20
        }}
      >
        <span>
          涉及地区：
          <span
            dangerouslySetInnerHTML={{
              __html: props.detailData.contentRegions || '-'
            }}
          ></span>
        </span>
        <span>
          IP属地：
          <span
            dangerouslySetInnerHTML={{
              __html: props.detailData.ipPlace || '-'
            }}
          ></span>
        </span>
        <span>
          用户注册地：
          <span
            dangerouslySetInnerHTML={{
              __html: props.detailData.authorDistrict || '-'
            }}
          ></span>
        </span>
        <span>
          用户签到地：
          <span
            dangerouslySetInnerHTML={{
              __html: props.detailData.district || '-'
            }}
          ></span>
        </span>
      </div>
      <div>
        {props.detailData.videos?.map((video) => (
          <div>
            <video src={video.url} controls className={moduleStyle.video} />
          </div>
        ))}
      </div>
      <div>
        {props.detailData.pics?.map((item) => (
          <div>
            <img className={moduleStyle.image} src={item.url} alt="图片" />
          </div>
        ))}
      </div>
      <div
        className={moduleStyle.content}
        dangerouslySetInnerHTML={{
          __html: props.detailData.content
        }}
      />
      <div className={moduleStyle.bottom}>
        <div>
          <img src="/imgs/sourceVerify/thumb-up.svg" alt="" />
          {props.detailData.likeNum}
        </div>
        <div>
          <img src="/imgs/sourceVerify/message.svg" alt="" />
          {props.detailData.commentNum}
        </div>
        <div>
          <img src="/imgs/sourceVerify/reply.svg" alt="" />
          {props.detailData.repeatNum}
        </div>
        <div>
          <img src="/imgs/sourceVerify/eye.svg" alt="" />
          {props.detailData.readNum}
        </div>
      </div>
    </div>
  )
}
export default ArticleComponent
