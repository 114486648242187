import moduleStyle from '../index.module.scss'
import { useState, useEffect, useMemo } from 'react'
import {
  CloseOutlined,
  CheckOutlined,
  DownloadOutlined,
  RedoOutlined
} from '@ant-design/icons'
import { Checkbox, Pagination, Select, Typography, Button, message } from 'antd'
import cn from 'classnames'
import Detail from './detail'
import {
  verifyDataPage,
  verifyDataStatistics,
  verifyDataExamine,
  pullData,
  downloadExcel
} from '@/api/list/tagVerify'
import tagVerifyStore from '@/store/tagVerify'
import { cloneDeep } from 'lodash'
import { platform } from '@/untils/constant'
const { Paragraph } = Typography
function List() {
  const [openModal, setOpenModal] = useState(false)
  const [params, setParams] = useState({ pageNum: 1, pageSize: 10 })
  const [statisticsInfo, setStatisticsInfo] = useState({})
  const [refreshPage, setRefreshPage] = useState(+new Date())
  const [selectedId, setSelectedId] = useState([])
  const [currentDataId, setCurrentDataId] = useState(null)
  const {
    lableInfo,
    setListInfo,
    listInfo,
    tagListShrink,
    leftConditionShrink,
    forceUpdateListTime
  } = tagVerifyStore()
  const openDetail = (id) => {
    setOpenModal(true)
    setCurrentDataId(id)
  }

  const changePage = (page, pageSize) => {
    setParams((state) => ({ ...state, pageSize, pageNum: page }))
  }

  const verifyDataExamineFun = async (dataIds, examineStatus) => {
    await verifyDataExamine({
      labelCode: lableInfo.code,
      dataIds,
      examineStatus
    })
    setRefreshPage(+new Date())
  }

  const changeSelect = (value, field) => {
    const newPrams = cloneDeep(params)
    newPrams.pageNum = 1
    if (value === undefined) {
      delete newPrams[field]
    } else {
      newPrams[field] = value
    }
    setParams(newPrams)
  }

  const pullDataFun = (reload) => {
    pullData({ sourceLabelId: lableInfo.sourceLabelId, reload }).then((res) => {
      if (+res.code === 0) {
        setParams((state) => ({ ...state, pageNum: 1 }))
        setRefreshPage(+new Date())
        message.success(res.message)
      }
    })
  }

  const downloadExcelFun = () => {
    if (!selectedId.length) return message.error('请勾选数据')
    const p = { labelCode: lableInfo.code, dataIds: selectedId }
    if (params.hitStatus >= 0) {
      p.hitStatus = params.hitStatus
    }
    if (params.examineStatus >= 0) {
      p.examineStatus = params.examineStatus
    }
    downloadExcel(p).then((response) => {
      // 将响应中的数据转换为 Blob 对象
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      // 创建一个临时的 URL，用于生成下载链接
      const downloadUrl = URL.createObjectURL(blob)

      // 创建一个 <a> 元素，设置下载链接和文件名
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = window.excelFileName

      // 将 <a> 元素添加到页面，并模拟点击以触发下载
      document.body.appendChild(link)
      link.click()

      // 清理临时 URL 和 <a> 元素
      URL.revokeObjectURL(downloadUrl)
      document.body.removeChild(link)
    })
  }

  const checkAll = (e) => {
    if (e.target.checked) {
      if (listInfo.records) {
        setSelectedId(
          listInfo.records.map((item) => {
            return item.dataId
          })
        )
      }
    } else {
      setSelectedId([])
    }
  }

  const onChangeCheck = (e, dataId) => {
    if (e.target.checked) {
      setSelectedId((state) => [...state, dataId])
    } else {
      setSelectedId((state) => state.filter((i) => i !== dataId))
    }
  }

  const indeterminate = useMemo(() => {
    let allcheck = true
    if (listInfo.records?.length) {
      listInfo.records.forEach((list) => {
        if (!selectedId.includes(list.dataId)) {
          allcheck = false
        }
      })
    }
    return selectedId.length && !allcheck
  }, [selectedId, listInfo.records])

  const allStatus = useMemo(() => {
    let allcheck = true
    if (listInfo.records?.length) {
      listInfo.records.forEach((list) => {
        if (!selectedId.includes(list.dataId)) {
          allcheck = false
        }
      })
    } else {
      allcheck = false
    }
    return allcheck
  }, [selectedId, listInfo.records])

  useEffect(() => {
    setParams((state) => ({ ...state, pageNum: 1 }))
  }, [lableInfo.code])

  useEffect(() => {
    if (lableInfo.code) {
      verifyDataPage({ ...params, labelCode: lableInfo.code }).then((res) => {
        if (+res.code === 0) {
          setListInfo(res.data)
          setSelectedId([])
        }
      })
    }
  }, [lableInfo.code, params, refreshPage, forceUpdateListTime])

  useEffect(() => {
    if (lableInfo.code) {
      verifyDataStatistics({ labelCode: lableInfo.code }).then((res) => {
        if (+res.code === 0) {
          setStatisticsInfo(res.data)
        }
      })
    }
  }, [lableInfo.code, refreshPage, forceUpdateListTime])

  useEffect(() => {
    setSelectedId([])
  }, [params])

  useEffect(() => {
    const element = document.getElementById('rightList')
    element.scrollTo({ behavior: 'smooth', top: 0 })
  }, [listInfo.pageNum, listInfo.pageSize])

  return (
    <div
      className={cn(moduleStyle.rightList, {
        [moduleStyle.fullWidth]: tagListShrink && leftConditionShrink
      })}
      id="rightList"
    >
      <div className={moduleStyle.toolBar}>
        {listInfo.total ? (
          <div className={moduleStyle.leftData}>
            <div className={moduleStyle.item}>
              <Checkbox
                checked={allStatus}
                indeterminate={indeterminate}
                onChange={checkAll}
              />
              <span className={moduleStyle.statistics}>
                {statisticsInfo.hitNums}/{statisticsInfo.totalNum}条
              </span>
            </div>
            <div className={moduleStyle.item}>
              命中率：
              <span className={moduleStyle.rate}>
                {(statisticsInfo.hitRate * 100).toFixed(2) || 0}%
              </span>
            </div>
            <div className={moduleStyle.item}>
              准确率：
              <span className={moduleStyle.rate}>
                {(statisticsInfo.correctRate * 100).toFixed(2) || 0}%
              </span>
            </div>
          </div>
        ) : null}

        <div className={moduleStyle.rightBar}>
          <Select
            className={moduleStyle.operator}
            options={[
              { value: 1, label: '命中' },
              { value: 0, label: '未命中' }
            ]}
            style={{ width: 100 }}
            allowClear
            onChange={(value) => changeSelect(value, 'hitStatus')}
            placeholder="请选择"
          />
          <Select
            className={moduleStyle.operator}
            style={{ width: 100 }}
            options={[
              { value: 0, label: '未研判' },
              { value: 11, label: '非准确' },
              { value: 12, label: '准确' }
            ]}
            placeholder="请选择"
            onChange={(value) => changeSelect(value, 'hitStatus')}
            allowClear
          />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            className={moduleStyle.operator}
            onClick={downloadExcelFun}
          />
          <Button
            type="primary"
            icon={<RedoOutlined />}
            className={moduleStyle.operator}
            onClick={() => pullDataFun(true)}
          />
          <Button
            onClick={() => pullDataFun(false)}
            type="primary"
            className={moduleStyle.operator}
          >
            拉取样本
          </Button>
        </div>
      </div>
      <div className={moduleStyle.listWrap}>
        {listInfo.records?.map((item) => (
          <div className={moduleStyle.listItem} key={item.dataId}>
            <Checkbox
              className={moduleStyle.itemCheck}
              onChange={(e) => onChangeCheck(e, item.dataId)}
              checked={selectedId.includes(item.dataId)}
            />
            <div className={moduleStyle.image}>
              <img src={item.coverUrl} alt="封面图" />
            </div>
            <div
              className={cn(moduleStyle.listInfo, {
                [moduleStyle.hasimg]: item.coverUrl
              })}
            >
              <div className={moduleStyle.top}>
                {item.emotion === 1 ? (
                  <span className={cn(moduleStyle.tag, moduleStyle.positive)}>
                    正面
                  </span>
                ) : null}

                {item.emotion === 2 ? (
                  <span className={cn(moduleStyle.tag, moduleStyle.negative)}>
                    负面
                  </span>
                ) : null}

                {item.emotion === 3 ? (
                  <span className={cn(moduleStyle.tag, moduleStyle.normal)}>
                    中性
                  </span>
                ) : null}
                <Paragraph
                  onClick={() => openDetail(item.dataId)}
                  ellipsis={{ rows: 1 }}
                  className={moduleStyle.title}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: item.title || '-' }}
                  ></span>
                </Paragraph>
              </div>
              <Paragraph className={moduleStyle.artical} ellipsis={{ rows: 2 }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.content || item.title || '-'
                  }}
                ></span>
              </Paragraph>
              {item.ocrCoverContent ? (
                <Paragraph ellipsis={{ rows: 1 }} className={moduleStyle.text}>
                  OCR：
                  <span
                    dangerouslySetInnerHTML={{ __html: item.ocrCoverContent }}
                  ></span>
                </Paragraph>
              ) : (
                <div className={cn(moduleStyle.text, moduleStyle.noHit)}>
                  OCR未发现命中
                </div>
              )}

              <div className={moduleStyle.info}>
                <div className={moduleStyle.infoLeft}>
                  <div>
                    <img
                      className={moduleStyle.platformIcon}
                      src={`/imgs/${platform[item.siteId]}.png`}
                      alt=""
                    />
                    <span
                      dangerouslySetInnerHTML={{ __html: item.siteName }}
                    ></span>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.authorName }}
                  ></div>
                  <div>{item.publishTime}</div>
                  <div>
                    ID：
                    <span
                      dangerouslySetInnerHTML={{ __html: item.siteAuthorId }}
                    ></span>
                  </div>
                </div>
                <div className={moduleStyle.infoRight}>
                  {item.examineStatus === 0 ? (
                    <>
                      <div
                        onClick={() => verifyDataExamineFun([item.dataId], 2)}
                        className={cn(moduleStyle.button, moduleStyle.close)}
                      >
                        <CloseOutlined className={moduleStyle.icon} />
                      </div>
                      <div
                        onClick={() => verifyDataExamineFun([item.dataId], 1)}
                        className={cn(moduleStyle.button, moduleStyle.pass)}
                      >
                        <CheckOutlined className={moduleStyle.icon} />
                      </div>
                    </>
                  ) : null}
                  {item.examineStatus === 12 ? (
                    <>
                      <div
                        className={cn(
                          moduleStyle.button,
                          moduleStyle.close,
                          moduleStyle.gray
                        )}
                        onClick={() => verifyDataExamineFun([item.dataId], 2)}
                      >
                        <CloseOutlined className={moduleStyle.icon} />
                      </div>
                      <div
                        className={cn(
                          moduleStyle.button,
                          moduleStyle.pass,
                          moduleStyle.checked
                        )}
                        onClick={() => verifyDataExamineFun([item.dataId], 1)}
                      >
                        <CheckOutlined className={moduleStyle.icon} />
                      </div>
                    </>
                  ) : null}
                  {item.examineStatus === 11 ? (
                    <>
                      <div
                        className={cn(
                          moduleStyle.button,
                          moduleStyle.close,
                          moduleStyle.checked
                        )}
                        onClick={() => verifyDataExamineFun([item.dataId], 2)}
                      >
                        <CloseOutlined className={moduleStyle.icon} />
                      </div>
                      <div
                        className={cn(
                          moduleStyle.button,
                          moduleStyle.pass,
                          moduleStyle.gray
                        )}
                        onClick={() => verifyDataExamineFun([item.dataId], 1)}
                      >
                        <CheckOutlined className={moduleStyle.icon} />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className={moduleStyle.dataInfo}>
                <div>数据ID：{item.dataId}</div>
                <div>命中位置：{item?.hitsFields?.join('、')}</div>
                <div>命中词：{item?.hitsWords?.join('、')}</div>
              </div>
            </div>
          </div>
        ))}
        {listInfo.total === 0 ? (
          <div className={moduleStyle.empty}>
            <img src="/imgs/empty.gif" alt="" />
            <span>暂无数据</span>
          </div>
        ) : null}
      </div>

      {listInfo.total > 0 ? (
        <Pagination
          className={moduleStyle.pagination}
          current={listInfo.pageNum}
          pageSize={listInfo.pageSize}
          defaultCurrent={1}
          total={listInfo.total}
          onChange={changePage}
          showTotal={(total) => `共 ${total} 条`}
        />
      ) : null}

      <Detail
        visible={openModal}
        handleVisible={setOpenModal}
        dataId={currentDataId}
      />
    </div>
  )
}

export default List
