import { useState } from 'react'
import moduleStyle from './index.module.scss'
import imgError from 'components/imgError'

let oss = '//oss-saas-1.oss-cn-beijing.aliyuncs.com/saas/'
function Index(props) {
  const [data] = useState(props.data)
  return (
    <div className={moduleStyle.download}>
      <div className="title">视频下载</div>
      <div className="resource-wrapper">
        <a
          href={data.download.localityDataUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="resourceCover"
            src={oss + data.download.resourceCoverPath}
            referrerPolicy="no-referrer"
            onError={imgError}
            alt=""
          />
        </a>
        <div className="resource-info">
          <div className="row">
            <span className="row-tit">视频大小:</span>
            <span className="row-content">
              {(data.download.videoSize / 1024).toFixed(1)}
              <span> M</span>
            </span>
          </div>

          <div className="row">
            <span className="row-tit">下载耗时:</span>
            <span className="row-content">
              {(data.download.dlEndTime - data.download.dlStartTime).toFixed(1)}
              <span> 秒</span>
            </span>
          </div>

          <div className="row">
            <span className="row-tit">文件类型:</span>
            <span className="row-content">{data.download.suffix}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
