import { MAP_BLOCKTYPE_TO_NUMBER } from './constant'
import TextBlock from './templates/textBlock'
import CodeBlock from './templates/codeBlock'
import ImageBlock from './templates/imageBlock'
import TableBlock from './templates/tableBlock'
import ListBlock from './templates/listBlock'
import PageBlock from './templates/pageBlock'
import TableCellBlock from './templates/tableCellBlock'
import GridBlock from './templates/gridBlock'
import GridColumnBlock from './templates/gridColumnBlock'
import CalloutBlock from './templates/callout'
import DividerBlock from './templates/divider'
import QuoteContainerBlock from './templates/quoteContainerBlock'

export default function Block({ blockData }) {
  if (!blockData) return <div> -BLOCK加载中-</div>
  const { block_type } = blockData
  switch (block_type) {
    case MAP_BLOCKTYPE_TO_NUMBER.page:
      return <PageBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.text:
    case MAP_BLOCKTYPE_TO_NUMBER.heading1:
    case MAP_BLOCKTYPE_TO_NUMBER.heading2:
    case MAP_BLOCKTYPE_TO_NUMBER.heading3:
    case MAP_BLOCKTYPE_TO_NUMBER.heading4:
    case MAP_BLOCKTYPE_TO_NUMBER.heading5:
    case MAP_BLOCKTYPE_TO_NUMBER.heading6:
    case MAP_BLOCKTYPE_TO_NUMBER.heading7:
    case MAP_BLOCKTYPE_TO_NUMBER.heading8:
    case MAP_BLOCKTYPE_TO_NUMBER.heading9:
      return <TextBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.code:
      return <CodeBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.image:
      return <ImageBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.bullet:
    case MAP_BLOCKTYPE_TO_NUMBER.ordered:
      return <ListBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.table:
      return <TableBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.table_cell:
      return <TableCellBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.grid:
      return <GridBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.grid_column:
      return <GridColumnBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.callout:
      return <CalloutBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.divider:
      return <DividerBlock blockData={blockData} />
    case MAP_BLOCKTYPE_TO_NUMBER.quote_container:
      return <QuoteContainerBlock blockData={blockData} />
    default:
      return (
        <div className="block-unsupport" key={blockData.block_id}>
          此BLOCK类型尚未翻译 {blockData.block_type} -{' '}
          {blockData.block_type_name}
        </div>
      )
  }
}
