import { Modal } from 'antd'
import { roleDel } from '@/api/list/system'
function Index(props) {
  const handleCancel = () => {
    props.setVisble(false)
  }

  const handleOk = () => {
    let ids = props.rowSelection.map((item) => {
      return item.id
    })
    roleDel({ ids }).then((res) => {
      if (+res.code === 0) {
        props.onFinish()
        props.setVisble(false)
      }
    })
  }

  return (
    <Modal
      forceRender
      title="删除角色"
      cancelText="取消"
      okText="确认"
      visible={props.visble}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      确认删除选中的 {props.rowSelection.length} 条数据?
    </Modal>
  )
}
export default Index
