import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getBuild, getUserInfo } from '@/api/list/system'
import recoilStore from '@/recoilStore'
import { useSetRecoilState } from 'recoil'
import Cookies from 'js-cookie'
import refreshToken from '@/api/axios/refreshToken'
import butttonStore from '@/store/buttonPermission'
import logout from '@/untils/logout'
import { message } from 'antd'

//验证路由是否存在
const checkRoute = (pathname, routes) => {
  if (pathname === '/resource-search') return true
  // 目前只支持两级地址，三级的取前两级的权限
  let path = pathname.split('/')
  if (path.length > 2) {
    path.splice(3)
    path = path.join('/')
  } else {
    path = pathname
  }
  let routeFlag = path === '/login' ? true : false
  const deepRoute = (routeList) => {
    routeList.forEach((item) => {
      if (item.path) {
        let currentPath = item.path.replace(/\s*/g, '')
        if (currentPath === path) {
          routeFlag = true
        }
      }
      if (item.children) {
        return deepRoute(item.children)
      }
    })
  }
  deepRoute(JSON.parse(JSON.stringify(routes)))
  return routeFlag
}

//过滤掉展示菜单不可见项 和 按钮
function menusFilter(list) {
  return list
    .filter((item) => {
      item.label = item.title
      if (item.children) {
        item.children = menusFilter(item.children)
      }
      return item.hidden === 0 && item.type !== 2
    })
    .map((item) => {
      return {
        is_frame: item.isFrame,
        type: item.type,
        path: item.path || '',
        label: item.label,
        children: item.children,
        pid: item.pid || ''
      }
    })
}

//提前5分钟刷新token
const refresh_token = () => {
  const token_time = Cookies.get('token_time')
  const nowTime = new Date().getTime()
  const logout_fn = () => {
    message.warn('token已过期')
    logout()
  }
  if (
    token_time &&
    nowTime >= token_time + 2 * 60 * 60 * 1000 - 5 * 60 * 1000
  ) {
    refreshToken()
      .then((res) => {
        if (res.code === 0) {
          Cookies.set('access_token', res.data.access_token)
          Cookies.set('refresh_token', res.data.refresh_token)
          Cookies.set('token_time', new Date().getTime())
        } else {
          logout_fn()
        }
      })
      .catch(() => {
        logout_fn()
      })
  }
}
const getTitlesWithType2 = (data) => {
  const titles = []

  function traverse(data) {
    for (const item of data) {
      if (item.type === 2) {
        titles.push(item.title)
      }
      if (item.children && item.children.length > 0) {
        traverse(item.children)
      }
    }
  }

  traverse(data)

  return titles
}

function Index(pathname, isLogin) {
  let navigate = useNavigate()
  const butttonState = butttonStore()
  const setShowMeanList = useSetRecoilState(recoilStore.showMeanList)
  const setUserInfo = useSetRecoilState(recoilStore.userInfo)
  useEffect(() => {
    if (pathname !== '/login' && isLogin) {
      //提前刷新token
      refresh_token()

      //存储权限菜单信息
      getBuild()
        .then((res) => {
          let { code, data } = res
          if (code === 0 && data) {
            // 存储可展示菜单
            const showMeanList = menusFilter(JSON.parse(JSON.stringify(data)))
            setShowMeanList(showMeanList)
            butttonState.setPermission(getTitlesWithType2(data))
          } else {
            setShowMeanList([])
            butttonState.setPermission([])
          }
          //判断访问权限不符合回首页
          if (!checkRoute(pathname, data) || code !== 0) {
            if (pathname !== '/home') {
              // navigate('/home')
            }
          }
        })
        .catch(() => {
          setShowMeanList([])
        })
      //存储用户信息
      getUserInfo().then((res) => {
        if (res.code === 0) {
          setUserInfo(res.data)
        }
      })
    }
  }, [isLogin, navigate, pathname, setShowMeanList, setUserInfo])
}

export default Index
