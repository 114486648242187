// import moduleStyle from "./style/index.module.scss";
import { useState, useEffect } from 'react'
import { Modal, Form, Input, message } from 'antd'
import { roleAdd, roleUpdate } from '@/api/list/system'
import MenuTree from './menuTree'
function Index(props) {
  const [menuIds, setMenuIds] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.initialValues) {
      let initialValues = JSON.parse(JSON.stringify(props.initialValues))
      if (initialValues.menuIds) {
        setMenuIds(initialValues.menuIds)
      }
      form.setFieldsValue({ ...initialValues })
    } else {
      setMenuIds([])
      form.setFieldsValue({
        name: '',
        description: ''
      })
    }
  }, [form, props.visble, props.initialValues])
  const handleCancel = () => {
    form.resetFields()
    props.setVisble(false)
  }

  const handleOk = () => {
    form.validateFields().then((values) => {
      let params = values
      let submitFun = null
      params.menuIds = [...menuIds]
      if (props.initialValues) {
        submitFun = roleUpdate
        params.id = props.initialValues.id
      } else {
        submitFun = roleAdd
      }
      submitFun(params).then((res) => {
        if (+res.code === 0) {
          props.setVisble(false)
          props.onFinish()
        } else {
          message.warning(res.message)
        }
      })
    })
  }

  return (
    <Modal
      width={600}
      forceRender
      title={props.initialValues ? '编辑角色' : '新增角色'}
      visible={props.visble}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        layout={'horizontal'}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="角色名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入角色名称'
            }
          ]}
        >
          <Input placeholder="请输入角色名称" />
        </Form.Item>
        <Form.Item label="描述信息" name="description">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="权限分配"
          name="menuIds"
          rules={[
            {
              required: true,
              validator: () => {
                if (menuIds.length > 0) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error('请勾选权限'))
                }
              }
            }
          ]}
        >
          <MenuTree
            menuIds={menuIds}
            setMenuIds={setMenuIds}
            visble={props.visble}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default Index
