import http from './../axios'

/**
 * 获取资源【采集】统计
 */
export const getResourceByDay = function () {
  return http().get('/media_service/statistics/resource/count/day1')
}

/**
 * 获取资源【采集】动态
 */
export const getResourceBy5m = function () {
  return http().get('/media_service/statistics/resource/count/min5')
}

/**
 * 获取资源【采集-优先】统计
 */
export const getResourceDirectByDay = function () {
  return http().get('/media_service/statistics/resource/count/direct/day1')
}

/**
 * 获取资源【采集-优先】动态
 */
export const getResourceDirectBy5m = function () {
  return http().get('/media_service/statistics/resource/count/direct/min5')
}

/**
 * 获取资源【下载】统计
 */
export const getDownloadByDay = function () {
  return http().get('/media_service/statistics/download/count/day1')
}

/**
 * 获取资源【下载】动态
 */
export const getDownloadBy5m = function () {
  return http().get('/media_service/statistics/download/count/min5')
}

/**
 * 获取资源【转码】统计
 */
export const getVideoParseByDay = function () {
  return http().get('/media_service/statistics/parse/count/day1')
}

/**
 * 获取资源【转码】动态
 */
export const getVideoParseBy5m = function () {
  return http().get('/media_service/statistics/parse/count/min5')
}

/**
 * 获取【账号】统计
 */
export const getAccountByDay = function () {
  return http().get('/media_service/statistics/author/count/day1')
}

/**
 * 获取【账号】动态
 */
export const getAccountBy5m = function () {
  return http().get('/media_service/statistics/author/count/min5')
}

/**
 * 获取【评论】统计
 */
export const getCommentByDay = function () {
  return http().get('/media_service/statistics/comment/count/day1')
}

/**
 * 获取【评论】动态
 */
export const getCommentBy5m = function () {
  return http().get('/media_service/statistics/comment/count/min5')
}

/**
 * 获取【算法-感情色彩】统计
 */
export const getEmotionByDay = function () {
  return http().get('/media_service/statistics/emotion/count/day1')
}

/**
 * 获取【算法-感情色彩】动态
 */
export const getEmotionBy5m = function () {
  return http().get('/media_service/statistics/emotion/count/min5')
}

/**
 * 获取【算法-AI-优先】统计
 */
export const getAiForHightPriorityByDay = function () {
  return http().get('/media_service/statistics/ai/hpriority/count/day1')
}

/**
 * 获取【算法-AI-优先】动态
 */
export const getAiForHightPriorityBy5m = function () {
  return http().get('/media_service/statistics/ai/hpriority/count/min5')
}

/**
 * 获取【算法-AI】统计
 */
export const getAiByDay = function () {
  return http().get('/media_service/statistics/ai/count/day1')
}

/**
 * 获取【算法-AI】动态
 */
export const getAiBy5m = function () {
  return http().get('/media_service/statistics/ai/count/min5')
}

/**
 * 获取【封面下载统计间隔5分钟】动态
 */
export const getDownloadCountBy5m = function () {
  return http().get('/media_service/statistics/cover/download/count/min5')
}

/**
 * 获取【封面下载统计间隔1天】动态
 */
export const getDownloadCountByDay = function () {
  return http().get('/media_service/statistics/cover/download/count/day1')
}

/**
 * 获取【账号采集任务统计间隔1天】动态
 */
export const getCrawledAccountRecordByDay = function () {
  return http().get('/media_service/statistics/crawled/account/record/day/one')
}

/**
 * 获取【账号采集任务统计间隔5分钟】动态
 */
export const getCrawledAccountRecordBy5m = function () {
  return http().get('/media_service/statistics/crawled/account/record/min/five')
}

/**
 * 获取【事件关键词采集统计间隔1天】动态
 */
export const getCrawledIncidentKeywordByDay = function () {
  return http().get(
    '/media_service/statistics/crawled/incident/keyword/record/day/one'
  )
}

/**
 * 获取【事件关键词采集统计间隔5分钟】动态
 */
export const getCrawledIncidentKeywordBy5m = function () {
  return http().get(
    '/media_service/statistics/crawled/incident/keyword/record/min/five'
  )
}

/**
 * 获取【资源新增统计统计间隔一天】动态
 */
export const getSassNewlyByDay = function () {
  return http().get('/media_service/statistics/saas/newly/record/day/one')
}

/**
 * 获取【 下载任务档位统计 间隔一天】动态
 */
export const getDownloadTaskGradeByDay = function () {
  return http().get(
    '/media_service/statistics/download/task/grade/count/day/one'
  )
}

/**
 * 获取【 下载任务档位统计实时记录 间隔5分钟】动态
 */
export const getDownloadTaskGradeBy5m = function () {
  return http().get(
    '/media_service/statistics/download/task/grade/count/min/five'
  )
}

/**
 * 获取【 下载任务账号等级统计 间隔一天】动态
 */
export const getDownloadTaskLevelByDay = function () {
  return http().get(
    '/media_service/statistics/download/task/level/count/day/one'
  )
}

/**
 * 获取【 下载任务账号等级统计实时记录 间隔5分钟】动态
 */
export const getDownloadTaskLevelBy5m = function () {
  return http().get(
    '/media_service/statistics/download/task/level/count/min/five'
  )
}

/**
 * 获取【 下载任务文件类型统计 间隔一天】动态
 */
export const getDownloadTaskSuffixByDay = function () {
  return http().get(
    '/media_service/statistics/download/task/suffix/count/day/one'
  )
}

/**
 * 获取【 下载任务文件类型统计实时记录 间隔5分钟】动态
 */
export const getDownloadTaskSuffixBy5m = function () {
  return http().get(
    '/media_service/statistics/download/task/suffix/count/min/five'
  )
}

/**
 * 获取【 封面下载统计 间隔一天】动态
 */
export const getDownloadResCoverByDay = function () {
  return http().get(
    '/media_service/statistics/download/res/cover/count/day/one'
  )
}

/**
 * 获取【 封面下载统计实时记录 间隔5分钟】动态
 */
export const getDownloadResCoverBy5m = function () {
  return http().get(
    '/media_service/statistics/download/res/cover/count/min/five'
  )
}

/**
 * 获取【 视频下载统计 间隔一天】动态
 */
export const getDownloadResVideoByDay = function () {
  return http().get(
    '/media_service/statistics/download/res/video/count/day/one'
  )
}

/**
 * 获取【 视频下载统计实时记录 间隔5分钟】动态
 */
export const getDownloadResVideoBy5m = function () {
  return http().get(
    '/media_service/statistics/download/res/video/count/min/five'
  )
}

/**
 * 获取【 外媒下载统计 间隔一天】动态
 */
export const getDownloadResSourceformByDay = function () {
  return http().get(
    '/media_service/statistics/download/res/sourceform/count/day/one'
  )
}

/**
 * 获取【 外媒下载统计实时记录 间隔5分钟】动态
 */
export const getDownloadResSourceformBy5m = function () {
  return http().get(
    '/media_service/statistics/download/res/sourceform/count/min/five'
  )
}

/**
 * 获取密度数据统计
 */
export const getMiduEquity = function (params) {
  return http().get('/media_service/statistics/midu/equity', {
    params
  })
}
