// import moduleStyle from "./style/index.module.scss";
import { useState, useEffect } from 'react'
import { Modal, Form, Input, Radio, Select } from 'antd'
import { getRoleList, userAdd, userUpdate } from '@/api/list/system'
function Index(props) {
  const [roleList, setRoleList] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.initialValues) {
      let initialValues = JSON.parse(JSON.stringify(props.initialValues))
      initialValues.enabled = String(initialValues.enabled)
      form.setFieldsValue({ ...initialValues })
    } else {
      form.setFieldsValue({
        username: '',
        phone: '',
        email: '',
        enabled: '1',
        roleIds: [],
        remark: ''
      })
    }
  }, [form, props.visble, props.initialValues])

  useEffect(() => {
    getRoleList({ pageNum: 1, pageSize: 100 }).then((res) => {
      setRoleList(res.data.records)
    })
  }, [])

  const handleCancel = () => {
    form.resetFields()
    props.setVisble(false)
  }

  const handleOk = () => {
    form.validateFields().then((values) => {
      let params = values
      let submitFun = null
      if (props.initialValues) {
        submitFun = userUpdate
        params.id = props.initialValues.id
      } else {
        submitFun = userAdd
      }
      submitFun(params).then((res) => {
        if (+res.code === 0) {
          props.setVisble(false)
          props.onFinish()
        }
      })
    })
  }

  return (
    <Modal
      width={600}
      forceRender
      title={props.initialValues ? '编辑用户' : '新增用户'}
      visible={props.visble}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        layout={'horizontal'}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value !== '') {
                  let reg = /^[d\w~`!@#$%^&*()_+-]+$/
                  if (reg.test(value)) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(
                      new Error('请输入字母、数字、英文符号')
                    )
                  }
                } else {
                  return Promise.reject(new Error('请输入用户名'))
                }
              }
            }
          ]}
        >
          <Input placeholder="请输入用户名" maxLength={30} />
        </Form.Item>
        <Form.Item
          label="电话"
          name="phone"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value !== '') {
                  var tel = /^0\d{2,3}-?\d{7,8}$/
                  var phone =
                    /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/
                  if (value.length === 11) {
                    //手机号码
                    if (phone.test(value)) {
                      return Promise.resolve()
                    }
                  } else if (value.length === 12 && value.indexOf('-') !== -1) {
                    //电话号码
                    if (tel.test(value)) {
                      return Promise.resolve()
                    }
                  }
                  return Promise.reject(new Error('请输入有效的号码'))
                } else {
                  return Promise.reject(new Error('请输入电话'))
                }
              }
            }
          ]}
        >
          <Input placeholder="请输入电话" />
        </Form.Item>
        <Form.Item
          label="邮箱"
          name="email"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value !== '') {
                  if (
                    value.search(
                      /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
                    ) !== -1
                  ) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(new Error('邮箱格式错误'))
                  }
                } else {
                  return Promise.reject(new Error('请输入邮箱'))
                }
              }
            }
          ]}
        >
          <Input placeholder="请输入邮箱" />
        </Form.Item>
        <Form.Item label="状态" name="enabled">
          <Radio.Group>
            <Radio value="1">激活</Radio>
            <Radio value="0">禁用</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="角色"
          name="roleIds"
          rules={[
            {
              required: true,
              message: '请选择角色'
            }
          ]}
        >
          <Select
            allowClear
            mode="multiple"
            placeholder="请选择角色"
            filterOption={(inputValue, option) => {
              return option.key.includes(inputValue)
            }}
          >
            {roleList.map((item) => (
              <Select.Option value={item.id} key={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="描述" name="remark">
          <Input placeholder="请输入描述" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default Index
