import moduleStyle from './style/index.module.scss'

function Index(props) {
  return (
    <div className={moduleStyle.pageHeadTitle}>
      <div className="center-tit">
        <div className="title">
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default Index
