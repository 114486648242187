import { create } from 'zustand'
import { generateRandomString } from '@/untils'
const TagVerifyStore = create((set) => ({
  firstPaintPage: true,
  leftConditionShrink: true,
  tagListShrink: false,
  groupLen: 1,
  groupCollect: [{ key: generateRandomString() }],
  lableInfo: {},
  copyData: {},
  breadcrumbList: [],
  editModal: false,
  listInfo: { pageSize: 10, pageNum: 1, total: 0 },
  forceUpdateListTime: +new Date(),
  setForceUpdateListTime: (time) => set({ forceUpdateListTime: time }),
  setEditModal: (status) => set({ editModal: status }),
  setBreadcrumbList: (array) => set({ breadcrumbList: array }),
  setFirstPaintPage: (status) => set({ firstPaintPage: status }),
  setLeftConditionShrink: (status) => set({ leftConditionShrink: status }),
  setTagListShrink: (status) => set({ tagListShrink: status }),
  setLableInfo: (info) => set({ lableInfo: info }),
  setListInfo: (listData) => set({ listInfo: listData }),
  setCopyData: (info) => set({ copyData: info }),
  setGroupLen: (len) => set(() => ({ groupLen: len })),
  addGroupCollect: () =>
    set((state) => ({
      groupCollect: [...state.groupCollect, { key: generateRandomString() }]
    })),
  setGroupCollect: (params) =>
    set(() => ({
      groupCollect: [...params]
    })),
  deleteGroupCollect: (key) =>
    set((state) => ({
      groupCollect: state.groupCollect.filter((i) => i.key !== key)
    }))
}))

export default TagVerifyStore
