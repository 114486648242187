import http from './../axios'

/**
 * 新增菜单
 */
export const menuAdd = (params) => {
  return http().post(`/user_service/menu/add`, params)
}

/**
 * 编辑菜单
 */
export const menuUpdate = (params) => {
  return http().post(`/user_service/menu/update`, params)
}

/**
 * 删除菜单
 */
export const menuDel = (params) => {
  return http().post(`/user_service/menu/del`, params)
}

/**
 * 菜单列表
 */
export const getMenuList = (params) => {
  return http().post(`/user_service/menu/list`, params)
}

/**
 * 获取树状菜单
 */
export const getBuildAll = (params) => {
  return http().get(`/user_service/menu/build/all`, params)
}

/**
 * 获取用户可展示菜单
 */
export const getBuild = (params) => {
  return http().get(`/user_service/menu/build`, params)
}

/**
 * 获取角色列表
 */
export const getRoleList = (params) => {
  return http().post(`/user_service/role/list`, params)
}

/**
 * 添加角色
 */
export const roleAdd = (params) => {
  return http().post(`/user_service/role/add`, params)
}

/**
 * 更新角色
 */
export const roleUpdate = (params) => {
  return http().post(`/user_service/role/update`, params)
}

/**
 * 删除角色
 */
export const roleDel = (params) => {
  return http().post(`/user_service/role/del`, params)
}

/**
 * 用户信息,角色信息,权限信息接口
 */
export const getUserInfo = (params) => {
  return http().get(`/user_service/user/info`, params)
}

/**
 * 获取用户列表
 */
export const getUserList = (params) => {
  return http().post(`/user_service/user/list`, params)
}

/**
 * 更新用户状态
 */
export const updateUserEnable = (params) => {
  return http().post(`/user_service/user/enable`, params)
}

/**
 * 新增用户
 */
export const userAdd = (params) => {
  return http().post(`/user_service/user/add`, params)
}

/**
 * 更新用户
 */
export const userUpdate = (params) => {
  return http().post(`/user_service/user/update`, params)
}

/**
 * 删除用户
 */
export const userDel = (params) => {
  return http().post(`/user_service/user/del`, params)
}
