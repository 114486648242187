import s from './index.module.scss'
import Head from 'components/head'
function CustomLayout(props) {
  return (
    <>
      <Head shadow={true} />
      <div style={{ paddingTop: '60px' }}>
        <div className={s.layout}>
          <div className={s.container}>
            <div id="docx-context">{props.content}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomLayout
