import { Menu } from 'antd'
import style from './index.module.scss'
import { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

function DocMenu() {
  const [menuList, setMenuList] = useState([])

  const getApiMenu = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_FEISHU_BFF_DOMAIN}/menu-list`)
        .then((resp) => {
          const list = resp.data.map((folder) => {
            return {
              label: folder.name,
              children: folder.children.map((doc) => {
                let docType = ''
                switch (doc.type) {
                  case 'docx':
                  case 'doc':
                  case 'shortcut':
                    docType = 'docFS'
                    break
                  case '':
                    docType = 'doc-html'
                    break
                  default:
                    docType = 'doc-html'
                }
                const path = `/development-platform/api/${docType}/${doc.token}`
                return {
                  label: <Link to={path}>{doc.name}</Link>,
                  key: doc.token
                }
              })
            }
          })
          resolve(list)
        })
    })
  }
  // setMenuList(menus)
  useEffect(() => {
    getApiMenu().then((resp) => {
      // console.log('', resp)
      // resp.forEach((item) => {
      //   item.type = 'group'
      // })
      setMenuList(resp)
    })
  }, [])
  return menuList.length ? (
    <div className={style.smMenuWrap}>
      <Menu
        className={style.smMenu}
        // style={{ width: 162, minHeight: '100%' }}
        defaultSelectedKeys={['1']}
        mode="inline"
        items={menuList}
        inlineIndent="20"
      ></Menu>
    </div>
  ) : (
    <div></div>
  )
}

export default DocMenu
