import axios from 'axios'
import style from './index.module.scss'
import { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import recoilStore from '@/recoilStore'
import { useSetRecoilState } from 'recoil'
import Doc from '@/components/doc/index'
import { MAP_BLOCKNUMBER_TO_TYPE } from '@/components/doc/constant'

// 飞书文档加载器
function DocFeishuLoader() {
  const docId = useParams().id
  let firstLoad = useRef(true)
  const [loading, setLoading] = useState(true)
  const [requesting, setRequesting] = useState(false)
  const [blockList, setBlockList] = useState([])
  const [loadingMessage, setLoadingMessage] = useState('文档加载中')
  const setBlockListMap = useSetRecoilState(recoilStore.docBlockItemMap)

  const loadingElement = (
    <div className={style.loadingMessageContainer}>
      <p>
        {loadingMessage}
        <span className={style.dot}>...</span>
      </p>
      <span>部分文档数据量较大，请耐心等待</span>
    </div>
  )

  const resetLoader = () => {
    setLoading(true)
    setBlockList([])
  }

  // 页面载入时，重置分页参数，获取首屏数据，绑定滚动监听
  useEffect(() => {
    resetLoader()
    getData()
    // 监听分页触发
    firstLoad.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId])

  const getData = async function (token) {
    if (requesting) return
    setRequesting(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FEISHU_BFF_DOMAIN}/documents/${docId}/blocks`,
        {
          params: { page_token: token }
        }
      )
      const oldArray = firstLoad.current ? [] : blockList
      const newArray = oldArray.concat(response?.data?.data)

      const blockItemMap = {}
      newArray.forEach((item, index) => {
        item.block_type_name = MAP_BLOCKNUMBER_TO_TYPE.get(item.block_type)
        blockItemMap[item.block_id] = item
      })
      setBlockListMap(blockItemMap)
      setBlockList(newArray)
      firstLoad.current = false
    } catch (error) {
      setLoadingMessage(error.message)
    } finally {
      setLoading(false)
      setRequesting(false)
    }
  }

  return loading ? loadingElement : <Doc blockItems={blockList} />
}

export default DocFeishuLoader
