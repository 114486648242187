import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import recoilStore from '@/recoilStore'

function Index(props) {
  const { permission, children } = props
  let { roles } = useRecoilValue(recoilStore.userInfo)
  const show = useMemo(() => {
    if (roles) {
      let flag = roles.filter((item) => {
        if (Array.isArray(permission)) {
          return permission.includes(item)
        } else {
          return String(permission) === item
        }
      })
      return flag.length
    } else {
      return false
    }
  }, [permission, roles])

  return <>{show ? children : ''}</>
}
export default Index
