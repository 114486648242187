import style from './index.module.scss'

function SixSided() {
  const list = [
    {
      label: '音频资源',
      path: require('../../img/audio.png')
    },
    {
      label: '账号资源',
      path: require('../../img/user.png')
    },
    {
      label: '平台资源',
      path: require('../../img/platform.png')
    },
    {
      label: '地区地理资源',
      path: require('../../img/geography.png')
    },
    {
      label: '标签体系',
      path: require('../../img/tag.png')
    },
    {
      label: '数据集资源',
      path: require('../../img/data.png')
    }
  ]
  return (
    <div className={style.sixSided}>
      <div className="wrap">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="container">
        {list.map((item) => {
          return (
            <div key={item.label}>
              <div>
                <img src={item.path} alt="" />
                <span>{item.label}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SixSided
