import manufactureMap from './manufactureMap'
export default function initChartOption(params) {
  const { data, type, stack, manufacture, hideLabel, featureShow, dataZoom } =
    params
  const series = []
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      padding: [0, 30],
      type: 'scroll',
      icon: 'circle',
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 15,
      data: data.status.map((stat) =>
        manufacture ? manufactureMap()[stat] : stat
      )
    },
    grid: {
      left: 100,
      bottom: dataZoom ? 80 : 40
    },
    dataZoom: dataZoom
      ? [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ]
      : null,
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        // mark: { show: featureShow },
        magicType: {
          show: featureShow,
          type: ['line', 'bar', 'stack'],
          title: {
            line: '切换为折线图',
            bar: '切换为柱状图',
            stack: '切换为堆叠'
          }
        },
        dataView: { show: featureShow, readOnly: false, title: '表格数据' },
        saveAsImage: { show: featureShow, title: '保存图片' },
        restore: { show: featureShow, title: '还原' }
      }
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: data.timeList,
        axisLabel: {
          color: '#86909C',
          formatter: (params) => {
            let arr = params.split(' ')
            let str = arr[0].replaceAll('-', '/') + '\n' + arr[1]
            return str
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          color: '#86909C'
        }
      }
    ],
    series: series
  }
  if (data && data.status) {
    const colorList = [
      '#DA2428',
      '#E7856E',
      '#E99E56',
      '#ECC256',
      '#d94b4b',
      '#60C8C8',
      '#44B0D4',
      '#7C95DE',
      '#7B7BDD',
      '#DE6DA7'
    ]
    data.status.forEach((item, index) => {
      series.push({
        name:
          manufacture && manufactureMap()[item] ? manufactureMap()[item] : item,
        type: type,
        stack: stack,
        // smooth: true,
        symbol: 'none',
        barGap: 0,
        barWidth: 16,
        label: {
          show: hideLabel,
          position: 'insideBottom',
          distance: 15,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: '{c}  {name|{a}}',
          fontSize: 16,
          rich: {
            name: {}
          }
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: colorList[index]
        },
        data: data.data[index]
      })
    })
  }

  return option
}
