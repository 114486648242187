import axios from 'axios'
import { useEffect, useState } from 'react'
import imageStyle from './imageBlock.module.scss'
import { Image } from 'antd'

export default function ImageBlockTemplate(props) {
  const { blockData } = props
  const [imageBase64, setImageBase64] = useState('')
  const [imageLoaded, setimageLoaded] = useState(false)
  const [imageLoading, setimageLoading] = useState(false)
  const [retryTime] = useState(5000)
  const [retryCount, setRetryCount] = useState(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getImageData()
  })

  async function getImageData() {
    // 自检是否已加载过
    if (imageLoaded || imageLoading) return
    setimageLoading(true)
    const resp = await axios.get(
      `${process.env.REACT_APP_FEISHU_BFF_DOMAIN}/image/${blockData?.image?.token}`
    )
    const { code } = resp.data
    if (code === 200) {
      const buffer = resp.data.data.data
      const base64 = `data:image/png;base64,${toBase64(buffer)}`
      setImageBase64(base64)
      setimageLoaded(true)
      setimageLoading(false)
    } else if (retryCount < 3) {
      setTimeout(() => {
        setRetryCount(retryCount + 1)
        getImageData()
      }, retryTime)
    }
  }

  const blockClassName = () => {
    const styleArray = []
    styleArray.push(imageStyle[`image-block`])
    return styleArray.join(' ')
  }

  function toBase64(arr) {
    //arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
    )
  }
  getImageData()
  const imageBlock = (
    <div
      id={blockData.block_id}
      key={blockData.block_id}
      className={blockClassName()}
    >
      <Image
        className="imagePreviewer"
        key={blockData?.image?.token}
        preview={visible}
        src={imageBase64}
        onClick={() => setVisible(true)}
      ></Image>
    </div>
  )
  return imageBlock
}
