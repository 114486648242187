import style from './index.module.scss'
import { Form, Button, Select, DatePicker, Table } from 'antd'
import dayjs from 'dayjs'
import { getMiduEquity } from '../../api/list/statistics'
import { useEffect, useState } from 'react'
function Statistics(props) {
  const [form] = Form.useForm()
  const [data, setData] = useState(null)
  const onFinish = (values) => {
    console.log('Finish:', values)
    const params = {
      startTimeStr: undefined,
      endTimeStr: undefined,
      projectId: values.projectId
    }

    if (values.date && values.date.length) {
      params.startTimeStr = values.date[0].format('YYYY-MM-DD 00:00:00')
      params.endTimeStr = values.date[1].format('YYYY-MM-DD 23:59:59')
    }

    getList(params)
  }
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().startOf('day')
  }

  const columns = [
    {
      title: '接口名称',
      dataIndex: 'interfaceName',
      key: 'interfaceName',
      render: (value) => value || '--'
    },
    {
      title: '来源',
      dataIndex: 'sourceFrom',
      key: 'sourceFrom',
      render: (value) => value || '--'
    },
    {
      title: '接口URL',
      dataIndex: 'requestPath',
      key: 'requestPath',
      render: (value) => value || '--'
    },
    {
      title: '调用成功次数',
      dataIndex: 'interfaceSuccessNumber',
      key: 'interfaceSuccessNumber',
      render: (value) => value || '--'
    },
    {
      title: '调用失败次数',
      dataIndex: 'interfaceFailNumber',
      key: 'interfaceFailNumber',
      render: (value) => value || '--'
    },
    {
      title: '拉取数据量',
      dataIndex: 'dataCount',
      key: 'dataCount',
      render: (value) => value || '--'
    },
    {
      title: '重复数据量',
      dataIndex: 'dataRepeatCount',
      key: 'dataRepeatCount',
      render: (value) => value || '--'
    }
  ]

  const getList = (params) => {
    getMiduEquity(params).then((res) => {
      setData(res.data)
    })
  }
  useEffect(() => {
    const params = {
      startTimeStr: dayjs()
        .startOf('day')
        .subtract(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endTimeStr: dayjs()
        .endOf('day')
        .subtract(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss'),
      projectId: undefined
    }
    form.setFieldValue('date', [
      dayjs().startOf('day').subtract(1, 'day'),
      dayjs().endOf('day').subtract(1, 'day')
    ])
    getList(params)
  }, [])
  return (
    <div className={style.container}>
      <div className={style.title}>MD数据统计</div>
      <div className={style.filter}>
        <Form
          form={form}
          name="horizontal_login"
          layout="inline"
          onFinish={onFinish}
        >
          <Form.Item name="projectId" label="项目名称">
            <Select style={{ width: 120 }} placeholder="请选择" allowClear>
              <Select.Option value={1}>风控</Select.Option>
              <Select.Option value={2}>2025</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="date" label="时间">
            <DatePicker.RangePicker
              disabledDate={disabledDate}
              allowClear={false}
              inputReadOnly
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={style.overview}>
        <div className={style.subtitle}>统计概览</div>
        <div className={style.flexWrap}>
          <div>
            <div>权益使用总量</div>
            <div>{data ? data.equityTotal : 0}</div>
          </div>
          <div>
            <div>接口调用成功次数</div>
            <div>{data ? data.interfaceSuccessTotal : 0}</div>
          </div>
          <div>
            <div>接口调用失败次数</div>
            <div>{data ? data.interfaceFailTotal : 0}</div>
          </div>
          <div>
            <div>拉取数据总量</div>
            <div>{data ? data.dataTotal : 0}</div>
          </div>
        </div>
      </div>
      <Table
        className={style.table}
        columns={columns}
        dataSource={data ? data.list : []}
        pagination={false}
        rowKey={(row) => row.requestPath}
      />
    </div>
  )
}
export default Statistics
