/* 新开窗口 */
export function windowOpen(href, target, e) {
  let a = document.createElement('a') //创建a标签
  a.href = href //添加 href 属性
  if (target) {
    a.target = '_blank' //添加 target 属性
  }
  a.click()
}

/* 阻止冒泡 */
export function cancelBubble(e) {
  let ev = e || window.event
  if (ev && ev.stopPropagation) {
    //非IE浏览器
    ev.stopPropagation()
  } else {
    //IE浏览器(IE11以下)
    ev.cancelBubble = true
  }
}

/*生成随机数*/

export function generateRandomString() {
  const length = 8 // 随机字符串的长度
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }
  return result
}
