// import { useState } from "react";
import { useEffect } from 'react'
import moduleStyle from './style/index.module.scss'
import { clouderaCDH } from './enum.js'
import SixSided from './component/sixSided'
import { Routes, Route, useNavigate } from 'react-router-dom'

import Cdh from './component/cdh'
import CloderaManager from './component/cloderaManager'
import Streamsets from './component/streamsets'
import Banner from '@/components/banner'
import Ceph from './component/ceph'

const tabList = [
  { name: 'Cdh', active: true, component: Cdh, path: '/bigData/cdh' },
  {
    name: 'Clodera Manager',
    active: false,
    component: CloderaManager,
    path: '/bigData/cloderaManager'
  },
  {
    name: 'Streamsets',
    active: false,
    component: Streamsets,
    path: '/bigData/streamsets'
  },
  { name: 'Ceph', active: false, component: Ceph, path: '/bigData/ceph' }
]

function Redirect({ to }) {
  let navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}
const bg = require('./img/bigDataBanner_bg.png')
function Index() {
  return (
    <div className={moduleStyle.bigDataWrapper}>
      <Banner banner={bg} label="睿媒大数据" />
      <div className="cloudera-cdh-wrapper">
        <div className="cloudera-cdh">
          <div className="left">
            <div>Cloudera CDH</div>
            {clouderaCDH}
          </div>
          <div className="right">
            <SixSided />
          </div>
        </div>
      </div>
      <Routes>
        <Route path="*" element={<Redirect to="/bigData/cdh" />} />
        {tabList.map((item, index) => (
          <Route key={index} path={item.path} element={<item.component />} />
        ))}
      </Routes>
    </div>
  )
}

export default Index
