import { Button, Modal, Checkbox, Select, message } from 'antd'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'
import Group from './group'
import FilterWord from './filterWord'
import TagVerifyStore from '@/store/tagVerify'
import { generateRandomString } from '@/untils'
import { cloneDeep, isEmpty } from 'lodash'
import moduleStyle from '../index.module.scss'
import { useEffect, useState } from 'react'
import { updateLabel, syncLabel } from '@/api/list/tagVerify'

const options = [
  {
    label: '标题',
    value: 'title'
  },
  {
    label: '简介',
    value: 'intro'
  },
  {
    label: '封面OCR',
    value: 'ocrCover'
  },
  {
    label: '视频OCR',
    value: 'ocrFrame'
  },
  {
    label: 'ASR',
    value: 'asr'
  },
  {
    label: '作者名称',
    value: 'author'
  },
  {
    label: 'IP属地',
    value: 'ipAdress'
  },
  {
    label: '内容提及地',
    value: 'adressMentioned'
  },
  {
    label: '用户注册地',
    value: 'registeredAddress'
  },
  {
    label: '定位地点',
    value: 'signinAddress'
  }
]

function EditTagInfo() {
  const {
    groupCollect,
    groupLen,
    lableInfo,
    setLableInfo,
    addGroupCollect,
    setGroupLen,
    editModal,
    setEditModal,
    setCopyData,
    copyData
  } = TagVerifyStore()
  const [updateLoadings, setUpdateLoadings] = useState(false)
  const [syncLoadings, setSyncLoadings] = useState(false)
  const addGroup = () => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo.exprList.push({
      expr: null,
      matchLocation: 2,
      inOrder: 1,
      negExprs: [
        {
          key: generateRandomString(),
          negExpr: null,
          negExprMl: 2,
          negExprOrder: 1
        }
      ]
    })
    setCopyData(snapTagInfo)
    addGroupCollect()
  }

  const changePosition = (checkedValues) => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo.locations = checkedValues
    setCopyData(snapTagInfo)
  }

  const changeRelation = (value) => {
    const snapTagInfo = cloneDeep(copyData)
    snapTagInfo.opType = value
    setCopyData(snapTagInfo)
  }

  const syncLabelFun = () => {
    new Promise((resolve, reject) => {
      const snapTagInfo = cloneDeep(copyData)
      let isEmptyExpr = false
      for (let i = 0; i < snapTagInfo.exprList.length; i++) {
        if (!snapTagInfo.exprList[i].expr) {
          isEmptyExpr = true
          break
        }
      }
      if (isEmptyExpr) {
        message.error('请输入关键词表达式')
        return
      }
      snapTagInfo.exprList.forEach((item) => {
        for (let i = 0; i < item.negExprs.length; i++) {
          if (!item.negExprs[i].negExpr) {
            item.negExprs.splice(i, 1)
            i--
          }
        }
      })
      setSyncLoadings(true)
      updateLabel(snapTagInfo)
        .then((res) => {
          if (res.code === 0) {
            resolve()
            setLableInfo(snapTagInfo)
          }
        })
        .catch((e) => {
          setSyncLoadings(false)
          message.error('更新失败')
          reject()
        })
    }).then(() => {
      syncLabel({ sourceLabelId: lableInfo.sourceLabelId })
        .then((res) => {
          if (+res.code === 0) {
            message.success('同步成功')
            setSyncLoadings(false)
            setEditModal(false)
          }
        })
        .catch((err) => {
          message.error('同步失败')
          setSyncLoadings(false)
        })
    })
  }

  const updateLableFun = () => {
    const snapTagInfo = cloneDeep(copyData)
    let isEmptyExpr = false
    for (let i = 0; i < snapTagInfo.exprList.length; i++) {
      if (!snapTagInfo.exprList[i].expr) {
        isEmptyExpr = true
        break
      }
    }
    if (isEmptyExpr) {
      message.error('请输入关键词表达式')
      return
    }
    snapTagInfo.exprList.forEach((item) => {
      for (let i = 0; i < item.negExprs.length; i++) {
        if (!item.negExprs[i].negExpr) {
          item.negExprs.splice(i, 1)
          i--
        }
      }
    })
    setUpdateLoadings(true)
    updateLabel(snapTagInfo)
      .then((res) => {
        if (res.code === 0) {
          message.success('更新成功')
          setUpdateLoadings(false)
          setEditModal(false)
          setLableInfo(snapTagInfo)
        }
      })
      .catch((e) => {
        setUpdateLoadings(false)
        message.error('更新失败')
      })
  }

  useEffect(() => {
    setGroupLen(groupCollect.length)
  }, [groupCollect.length])

  useEffect(() => {
    setCopyData(lableInfo)
  }, [lableInfo])

  useEffect(() => {
    if (!editModal) {
      setCopyData(lableInfo)
    }
  }, [editModal])

  return (
    <>
      <Modal
        width={1087}
        title={
          <div className={moduleStyle.modalTitle}>
            <span>编辑标签</span>
            <div>
              <span className={moduleStyle.relationTitle}>词组关系</span>
              <Select
                defaultValue="AND"
                style={{ width: 80 }}
                value={copyData.opType}
                options={[
                  { value: 'AND', label: 'AND' },
                  { value: 'OR', label: 'OR' }
                ]}
                onChange={changeRelation}
              />
            </div>
          </div>
        }
        open={editModal}
        closable={false}
        footer={[
          <Button danger onClick={() => setEditModal(false)}>
            取消
          </Button>,
          <Button
            loading={updateLoadings}
            type="primary"
            onClick={updateLableFun}
          >
            保存
          </Button>,
          <Button loading={syncLoadings} type="primary" onClick={syncLabelFun}>
            同步
          </Button>
        ]}
        className={moduleStyle.editModal}
      >
        {groupCollect.map((item, index) => (
          <Group
            key={item.key}
            uniqKey={item.key}
            index={index}
            groupLen={groupLen}
          />
        ))}
        <div className={moduleStyle.addArea}>
          <div className={moduleStyle.addButton} onClick={addGroup}>
            <PlusCircleOutlined className={moduleStyle.addIcon} />
            添加词组
          </div>
        </div>
        <div className={moduleStyle.filterArea}>
          <FilterWord />
        </div>

        <div className={moduleStyle.matchArea}>
          <div className={moduleStyle.title}>匹配位置</div>
          <Checkbox.Group
            onChange={changePosition}
            options={options}
            value={copyData.locations}
          ></Checkbox.Group>
        </div>
      </Modal>
    </>
  )
}

export default EditTagInfo
