import { useState, useEffect, useId } from 'react'
import { Modal } from 'antd'
import moduleStyle from './style/index.module.scss'
import initChartOption from './js/initChartOption'
import * as echarts from 'echarts'

function Index(props) {
  const [dataOptions, setDataOptions] = useState({})
  const id = useId()
  useEffect(() => {
    props.options.getData().then((res) => {
      let propsOptions = props.options
      propsOptions.data = res.data
      setDataOptions(propsOptions)
      let chartDom = document.getElementById(id)
      if (chartDom) {
        const chart = echarts.init(chartDom)
        chart.setOption(initChartOption(propsOptions))
      }
    })
  }, [props.options, id])

  //预览
  const viewChart = () => {
    setVisible(true)
    const timer = setTimeout(() => {
      let chartDom = document.getElementById(`view${id}`)
      const chart = echarts.init(chartDom)
      chart.setOption(initChartOption({ ...dataOptions, featureShow: true }))
      clearTimeout(timer)
    })
  }

  const [visible, setVisible] = useState(false)
  const ViewModal = () => {
    return (
      <Modal
        title={props.options.title}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        width={888}
        footer={null}
      >
        <div
          id={`view${id}`}
          style={{
            height: 520,
            width: 840
          }}
        ></div>
      </Modal>
    )
  }

  return (
    <div className={moduleStyle.chartBox}>
      <div className="chartBox-head">
        <span className="title">{props.options.title}</span>
        <span className="view" onClick={viewChart}>
          查看详情
        </span>
        <ViewModal />
      </div>
      <div
        id={id}
        style={{
          height: 440 - 64
        }}
      ></div>
    </div>
  )
}
export default Index
