import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import moduleStyle from './style/index.module.scss'
import { Button, Table, Popconfirm, Switch, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { getUserList, updateUserEnable } from '@/api/list/system'

import { userDel } from '@/api/list/system'
const App = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getList,
    columns
  }))
  const edit = (record) => {
    props.onEdit(record)
  }

  const delConfirm = (record) => {
    userDel({ ids: [record.id] }).then((res) => {
      if (+res.code === 0) {
        getList()
      }
    })
  }

  const enabledConfirm = (record) => {
    let status = record.enabled === 1 ? 0 : 1
    let query = {
      ids: [record.id],
      status
    }
    updateUserEnable(query).then((res) => {
      if (res.code === 0) {
        list.forEach((item) => {
          if (item.id === record.id) {
            item.enabled = status
          }
        })

        setList([...list])
      }
    })
  }

  const columns = [
    {
      title: '用户名',
      dataIndex: 'username'
    },
    {
      title: '电话',
      dataIndex: 'phone'
    },
    {
      title: '邮箱',
      dataIndex: 'email'
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      render: (val, record) => {
        let title = `此操作将${val === 1 ? '禁用' : '激活'} ${
          record.username
        } ,是否继续？`
        return (
          <Popconfirm
            title={title}
            onConfirm={() => enabledConfirm(record)}
            disabled={record.isAdmin === 1}
          >
            <Switch
              disabled={record.isAdmin === 1}
              checkedChildren="开启"
              unCheckedChildren="关闭"
              checked={val === 1 ? true : false}
            />
          </Popconfirm>
        )
      }
    },
    {
      title: '角色',
      dataIndex: 'roleNames',
      ellipsis: { showTitle: false },
      render: (val) => {
        let titleStr = val.join(' , ')
        return (
          <Tooltip placement="topLeft" title={titleStr}>
            {titleStr}
          </Tooltip>
        )
      }
    },
    {
      title: '描述',
      dataIndex: 'remark'
    },
    {
      title: '创建日期',
      dataIndex: 'createTime'
    },
    {
      title: '操作',
      dataIndex: 'noDataIndex',
      align: 'center',
      render: (val, record) => {
        return (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={record.isAdmin === 1}
            >
              <EditOutlined />
            </Button>
            <span className="fn-ml4">
              <Popconfirm
                disabled={record.isAdmin === 1}
                arrowPointAtCenter
                placement="topRight"
                title={() => (
                  <div className="del-popconfirm">确定删除本条数据吗？</div>
                )}
                onConfirm={() => delConfirm(record)}
              >
                <Button disabled={record.isAdmin === 1}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </span>
          </>
        )
      }
    }
  ]

  const [list, setList] = useState([])
  const getList = () => {
    getUserList({ pageNum: pageNum, pageSize: pageSize }).then((res) => {
      if (res.code === 0) {
        setList(res.data.records)
        setTotal(res.data.total)
        set_selectedRowKeys([])
        props.setRowSelection([])
        if (res.data.records.length === 0 && pageNum > 1) {
          setPageNum(pageNum - 1)
        }
      }
    })
  }
  useEffect(() => {
    getList()
  }, [])

  const [selectedRowKeys, set_selectedRowKeys] = useState([])
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (keys, selectedRows) => {
      set_selectedRowKeys(keys)
      props.setRowSelection(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.isAdmin === 1,
      // Column configuration not to be checked
      name: record.isAdmin
    })
  }

  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const paginationChange = (page, pageSize) => {
    setPageNum(page)
    setPageSize(pageSize)
  }

  useEffect(() => {
    getList()
  }, [pageNum, pageSize])

  return (
    <div className={moduleStyle.tableWrap}>
      <Table
        rowKey="id"
        dataSource={list}
        columns={columns}
        rowSelection={{ ...rowSelection }}
        pagination={{
          total,
          showSizeChanger: true,
          onChange: paginationChange
        }}
        rowClassName={(record) => {
          return record.isAdmin === 1 ? 'disabled' : ''
        }}
      />
    </div>
  )
})

export default App
