import moduleStyle from './style/index.module.scss'
import Navigation from './component/navigation'
import Subject from './component/subject'
import Functions from './component/functions'

function Index() {
  return (
    <div className={moduleStyle.wrapperBox}>
      {/*<Banner />*/}
      <div className="banner">
        <div className="banner-box">
          <div className="banner-title">睿媒数据平台正式上线</div>
          <div className="banner-btn">了解我们</div>
        </div>
      </div>
      <Navigation />
      <Subject />
      <Functions />
    </div>
  )
}

export default Index
