import moduleStyle from './style/index.module.scss'
import './style/index.scss'

const list = [
  {
    title: '媒体资源',
    countList: [
      { name: '快手', count: '14.6' },
      { name: '抖音', count: '19.8' }
    ],
    image: require('./img/media-source.png'),
    background: '#F5F5F5'
  },
  {
    title: '账号资源',
    countList: [
      { name: '快手', count: '17.3' },
      { name: '抖音', count: '14.4' }
    ],
    image: require('./img/user-source.png'),
    background: '#EAEBEC'
  },
  {
    title: '知识图谱',
    countList: [
      { name: '快手', count: '30.2' },
      { name: '快手', count: '18.9' }
    ],
    image: require('./img/media-source.png'),
    background: '#F5F5F5'
  }
]
function Index() {
  return (
    <div className={moduleStyle.count}>
      {list.map((item, index) => (
        <div
          className="count-item"
          key={index}
          style={{ background: item.background }}
        >
          <img src={item.image} alt="" />
          <span className="title">{item.title}</span>
          <div className="count-box">
            {item.countList.map((countItem, countIndex) => (
              <div className="count" key={countIndex}>
                <span className="count-title">{countItem.name}</span>
                <span className="count-num">
                  <span>{countItem.count}</span>{' '}
                  <span className="unit">亿</span>
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export default Index
