import moduleStyle from '../index.module.scss'
import { Input, Tree } from 'antd'
import { SearchOutlined, LeftOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import { getLableList } from '@/api/list/riskTag'
import cn from 'classnames'
import TagVerifyStore from '@/store/tagVerify'
import { getLableInfo } from '@/api/list/tagVerify'
import { generateRandomString } from '@/untils'
import { cloneDeep } from 'lodash'
const fieldNames = {
  title: 'name',
  key: 'id',
  children: 'subs'
}

function findParentNames(data, name, type, id) {
  let result = []

  function traverse(item, parentNames) {
    const currentNames = [...parentNames, item.name]

    if (item.name === name && item.type === type && item.labelId === id) {
      result = currentNames
      return true
    }

    if (item.subs && item.subs.length > 0) {
      for (const subItem of item.subs) {
        if (traverse(subItem, currentNames)) {
          return true
        }
      }
    }

    return false
  }

  for (const item of data) {
    if (traverse(item, [])) {
      break
    }
  }

  return result
}
function TagList() {
  const [treeData, setTreeData] = useState([])
  const [keyword, setKeyword] = useState(null)
  const [selectedNode, setSelectedNode] = useState(null)
  const tagVerifyState = TagVerifyStore()
  const {
    setTagListShrink,
    setLableInfo,
    setGroupLen,
    setGroupCollect,
    lableInfo,
    setBreadcrumbList,
    setFirstPaintPage,
    setCopyData,
    setForceUpdateListTime
  } = tagVerifyState
  const changeKeyWord = (e) => {
    setKeyword(e.target.value)
  }
  const [currentLabelId, setCurrentLabelId] = useState(null)
  const initTagList = () => {
    const params = { matchType: 1 }
    if (keyword) {
      params.keyword = keyword
    }
    getLableList(params)
      .then((res) => {
        if (res.data?.length) {
          setTreeData(res.data)
        } else {
          setTreeData([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTagDetail = (sourceId, id) => {
    setCurrentLabelId(sourceId)
    getLableInfo({ sourceId, id }).then((res) => {
      if (+res.code === 0) {
        const response = cloneDeep(res.data)
        if (!response.exprList.length) {
          response.exprList = [
            {
              expr: null,
              matchLocation: 2,
              inOrder: 1,
              negExprs: [
                {
                  key: generateRandomString(),
                  negExpr: null,
                  negExprMl: 2,
                  negExprOrder: 1
                }
              ]
            }
          ]
        }
        if (!response.allExcluExpr) {
          response.allExcluExpr = {
            expr: null,
            matchLocation: 2,
            inOrder: 1
          }
        }
        if (!response.ambiguousExpr) {
          response.ambiguousExpr = {
            expr: null,
            matchLocation: 2,
            inOrder: 1
          }
        }
        response.exprList.forEach((item) => {
          item.key = generateRandomString()
          if (item.negExprs.length) {
            item.negExprs.forEach((subItem) => {
              subItem.key = generateRandomString()
            })
          } else {
            item.negExprs = [
              {
                key: generateRandomString(),
                negExpr: null,
                negExprMl: 2,
                negExprOrder: 1
              }
            ]
          }
        })
        setLableInfo(response)
        setCopyData(response)
        setGroupLen(response?.exprList?.length || 1)
        if (response?.exprList?.length) {
          setGroupCollect(response.exprList)
        }
      }
    })
    setFirstPaintPage(false)
  }

  const handleNodeClick = (selectedKeys, { node }) => {
    if (node.id === selectedNode) {
      setForceUpdateListTime(+new Date())
    }
    setSelectedNode(node.id)
  }

  useEffect(() => {
    initTagList()
  }, [])

  useEffect(() => {
    const tagNames = findParentNames(
      treeData,
      lableInfo.name,
      2,
      currentLabelId
    )
    setBreadcrumbList(tagNames)
  }, [treeData, currentLabelId, lableInfo.name])

  return (
    <div className={moduleStyle.leftWrap}>
      {tagVerifyState.tagListShrink && tagVerifyState.leftConditionShrink ? (
        <div
          className={cn(moduleStyle.float, moduleStyle.tagList)}
          onClick={() => setTagListShrink(false)}
        >
          <img src="/imgs/listExpand.svg" alt="expand" />
        </div>
      ) : null}
      <div
        className={cn(moduleStyle.tagListWrap, {
          hidden: tagVerifyState.tagListShrink
        })}
      >
        <div className={moduleStyle.treeTitle}>
          <span>标签列表</span>
          {!tagVerifyState.firstPaintPage ? (
            <LeftOutlined
              className={moduleStyle.shrink}
              onClick={() => setTagListShrink(true)}
            />
          ) : null}
        </div>
        <Input
          suffix={<SearchOutlined />}
          className={moduleStyle.searchInput}
          placeholder="请输入标签或文件名称"
          value={keyword}
          onChange={changeKeyWord}
          onPressEnter={initTagList}
        />
        <div className={moduleStyle.treeWrap}>
          <Tree
            showIcon
            blockNode
            selectedKeys={selectedNode ? [selectedNode] : []}
            onSelect={handleNodeClick}
            treeData={treeData}
            titleHeight={28}
            fieldNames={fieldNames}
            titleRender={(item) => (
              <div
                onClick={() => getTagDetail(item.labelId, item.id)}
                className={moduleStyle.treeItem}
              >
                <div>
                  {item.type === 1 ? (
                    <img
                      className={moduleStyle.folder}
                      src={'/imgs/folder-icon.png'}
                      alt="文件夹"
                    />
                  ) : (
                    <span
                      className={cn(moduleStyle.tagIcon, {
                        [moduleStyle.enable]: item.status === 1,
                        [moduleStyle.disabled]: item.status === 9
                      })}
                    ></span>
                  )}
                  {item.name}
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default TagList
