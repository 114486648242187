import { Link } from 'react-router-dom'
import { Dropdown, Button } from 'antd'
import styles from './index.module.scss'
import Cookies from 'js-cookie'

import { useRecoilValue } from 'recoil'
import recoilStore from '@/recoilStore'
import logout from '@/untils/logout'
const userPng = require('../../img/user.png')

function Index() {
  const recoilStore_userInfo = useRecoilValue(recoilStore.userInfo)
  let isLogin = Cookies.get('access_token')

  const items = [
    {
      key: 'logout',
      label: (
        <div className={styles.overlay}>
          <div className="row" onClick={logout}>
            退出登录
          </div>
        </div>
      )
    }
  ]
  return (
    <div className={styles.userWrapper}>
      <span className="login">
        {isLogin ? (
          <Dropdown menu={{ items }} placement="bottom" arrow>
            <div>
              <span className="userName">
                {recoilStore_userInfo && recoilStore_userInfo.userInfo
                  ? recoilStore_userInfo.userInfo.username
                  : ''}
              </span>
              <img src={userPng} alt="" />
            </div>
          </Dropdown>
        ) : (
          <Link to="/login">
            <Button type="primary" className="login-btn">
              登 录
            </Button>
          </Link>
        )}
      </span>
      {/* <span className="register">注册</span> */}
    </div>
  )
}
export default Index
