import { useState } from 'react'
import moduleStyle from './index.module.scss'
import imgError from 'components/imgError'

let oss = '//oss-saas-1.oss-cn-beijing.aliyuncs.com/saas/'
const initImgList = (arr) => {
  if (arr.length > 5) {
    let len = arr.length
    const indexArr = [
      0,
      Math.ceil(len / 4),
      Math.ceil(len / 2),
      Math.ceil((len * 3) / 4),
      len - 1
    ]
    let newArr = indexArr.map((i) => {
      return arr[i]
    })
    return newArr
  } else {
    return arr
  }
}

function Index(props) {
  const [data] = useState(props.data.trans)
  const picsList = initImgList(data.pics)
  return (
    <div className={moduleStyle.trans}>
      <div className="title">视频转码</div>

      <div className="img-list-wrapper">
        {picsList.map((item, index) => (
          <div key={index}>
            <img
              src={oss + item}
              onError={imgError}
              referrerPolicy="no-referrer"
              alt=""
            />
          </div>
        ))}
        <div className="img-info">
          <div className="row">
            <span className="row-tit">图片大小:</span>
            {data.width} * {data.height}
          </div>
          <div className="row">
            <span className="row-tit">总帧数:</span>
            {data.pics.length}
          </div>
          <div className="row">
            <span className="row-tit">转码状态:</span>
            {data.tsStatus}%
          </div>
          <div className="row">
            <span className="row-tit">音频文件:</span>
            {data.audios}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
