import moment from 'moment'
/* 视频秒数转换时分钟00:00:00时分秒格式 */
export function timeToMinute(times) {
  times = times / 1000
  let t
  if (times > -1) {
    let hour = Math.floor(times / 3600)
    let min = Math.floor(times / 60) % 60
    let sec = times % 60
    if (hour < 10) {
      t = '0' + hour + ':'
    } else {
      t = hour + ':'
    }

    if (min < 10) {
      t += '0'
    }
    t += min + ':'
    if (sec < 10) {
      t += '0'
    }
    t += sec.toFixed(2)
  }
  t = t.substring(0, t.length - 3)
  return t
}

/* yyyy-mm-dd hh:mm:ss 转换毫秒值 */
export function timeToMillisecond(str) {
  if (str) {
    let arr = str.split(/[- : /]/)
    let millisecond = Date.parse(
      new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    )
    return millisecond
  } else {
    return null
  }
}

export function formatTime(t) {
  return moment(parseInt(t)).format('YYYY-MM-DD HH:mm:ss')
}
