import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moduleStyle from './style/index.module.scss'

import { Table } from 'antd'
import { getCrawlTracingList } from '@/api/list/importDataTracking'

import { formatTime, timeToMinute } from '@/untils/time'
const columns = [
  {
    width: 70,
    title: 'id',
    dataIndex: 'id'
  },
  {
    width: 70,
    title: '审核员',
    dataIndex: 'assessor'
  },
  {
    width: 70,
    title: 'uuid',
    dataIndex: 'uuid'
  },
  {
    width: 60,
    title: '平台',
    dataIndex: 'siteName'
  },
  {
    width: 300,
    title: 'url',
    dataIndex: 'url',
    render: (val) => <span>{val ? val : '-'}</span>
  },
  // {
  //   title: "infoid",
  //   dataIndex: "infoid",
  // },
  // {
  //   title: "resourceId",
  //   dataIndex: "resourceId",
  //   render: (val) => (val ? val : "-"),
  // },
  {
    width: 300,
    title: '标题',
    dataIndex: 'title',
    render: (val, record) => {
      const { importState } = record
      return (
        <span className={'line-3 ' + (!importState ? 'gray' : '')}>
          {val ? val : '-'}
        </span>
      )
    }
  },
  {
    width: 80,
    title: '发布时间',
    dataIndex: 'pubTimeLong',
    render: (val) => (val ? formatTime(val) : '-')
  },
  {
    width: 80,
    title: '导入开始时间',
    dataIndex: 'ctimeLong',
    render: (val) => (val ? formatTime(val) : '-')
  },
  {
    width: 80,
    title: '采集时间',
    dataIndex: 'lastTimeLong',
    render: (val) => (val ? formatTime(val) : '-')
  },
  {
    width: 90,
    title: '采集用时',
    dataIndex: 'noData',
    render: (val, record) => {
      let lastTime = record.lastTimeLong
        ? record.lastTimeLong
        : new Date().getTime()
      let classNameStr =
        lastTime - record.ctimeLong >= 120 * 1000 ? 'redWord' : ''
      return (
        <span className={classNameStr}>
          {record.ctimeLong && lastTime - record.ctimeLong >= 0
            ? timeToMinute(lastTime - record.ctimeLong)
            : '-'}
        </span>
      )
    }
  }
]

function Index() {
  const [list, setList] = useState([])
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)

  let navigate = useNavigate()
  const rowClick = (record) => {
    navigate('/manufactured-import-tracing/detail', { state: { ...record } })
  }
  const table_change = (pagination) => {
    setCurrent(pagination.current)
  }

  const getList = () => {
    getCrawlTracingList({ page: current, size: 100 }).then((res) => {
      const { code, data } = res
      if (code === 0 && data) {
        let newData = data.list.map((item) => {
          return {
            key: Math.random(),
            ...item
          }
        })
        setList([...newData])
        setTotal(data.total)
      }
    })
  }
  useEffect(() => {
    getList()
  }, [current])

  return (
    <div className={moduleStyle.importDataTrackingWrap}>
      <div className="head-box">
        <div className="center-tit">
          <div className="title">
            <div>导入数据追踪</div>
          </div>
        </div>
      </div>

      <div className="table_wrap">
        <div className="total-box">
          共 <span>{total}</span> 条数据
        </div>
        <Table
          pagination={{ total: total, pageSize: 100, showSizeChanger: false }}
          scroll={{ x: 1400, y: 500 }}
          columns={columns}
          dataSource={list}
          onRow={(record) => {
            return {
              onClick: (event) => rowClick(record)
            }
          }}
          onChange={table_change}
        />
      </div>
    </div>
  )
}

export default Index
