import axios from 'axios'
import Cookies from 'js-cookie'
const instance = axios.create({
  baseURL: '/Api',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  timeout: 60000
})
instance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    const { status, data } = response
    if (+status === 200 && data.code === 0) {
      return Promise.resolve(data)
    } else {
      return Promise.reject(data)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

const refreshToken = () => {
  const refresh_token = Cookies.get('refresh_token')
  let param = {
    grant_type: 'refresh_token',
    client_id: 'rzmk-mi-password-client',
    client_secret: '$2a$11$veu5Bmel1s98upe',
    refresh_token
  }
  return instance.post('/auth_service/custom/oauth/token', param)
}

export default refreshToken
