import { create } from 'zustand'
import { generateRandomString } from '@/untils'
const riskTagStore = create((set) => ({
  loading: true,
  groupLen: 1,
  groupCollect: [{ key: generateRandomString() }],
  tagInfo: {},
  setGroupLen: (len) => set(() => ({ groupLen: len })),
  addGroupCollect: () =>
    set((state) => ({
      groupCollect: [...state.groupCollect, { key: generateRandomString() }]
    })),
  setGroupCollect: (params) =>
    set(() => ({
      groupCollect: [...params]
    })),
  deleteGroupCollect: (key) =>
    set((state) => ({
      groupCollect: state.groupCollect.filter((i) => i.key !== key)
    })),
  setTagInfo: (info) => set(() => ({ tagInfo: info })),
  clearTagInfo: () => set({ tagInfo: {} }),
  setLoading: (status) => set({ loading: status })
}))

export default riskTagStore
