import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import moduleStyle from './style/index.module.scss'
import { Button, Table, Popconfirm, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { getRoleList } from '@/api/list/system'

import { roleDel } from '@/api/list/system'
const App = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getList,
    columns
  }))
  const edit = (record) => {
    props.onEdit(record)
  }

  const delConfirm = (record) => {
    roleDel({ ids: [record.id] }).then((res) => {
      if (+res.code === 0) {
        getList()
      }
    })
  }

  const columns = [
    {
      title: '角色名称',
      dataIndex: 'name'
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: true
    },
    {
      title: '包含人数',
      dataIndex: 'userNames',
      render: (val) => {
        const titleStr = val.join(' , ')
        return <Tooltip title={titleStr}>{val.length}</Tooltip>
      }
    },
    {
      title: '创建人',
      dataIndex: 'createBy'
    },
    {
      title: '创建日期',
      dataIndex: 'createTime'
    },
    {
      title: '操作',
      dataIndex: 'noDataIndex',
      align: 'center',
      render: (val, record) => {
        return (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={record.name === '超级管理员'}
            >
              <EditOutlined />
            </Button>
            <span className="fn-ml4">
              <Popconfirm
                disabled={record.name === '超级管理员'}
                arrowPointAtCenter
                placement="topRight"
                title={() => (
                  <div className="del-popconfirm">确定删除本条数据吗？</div>
                )}
                onConfirm={() => delConfirm(record)}
              >
                <Button disabled={record.name === '超级管理员'}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </span>
          </>
        )
      }
    }
  ]

  const [list, setList] = useState([])
  const getList = () => {
    getRoleList({ pageNum: pageNum, pageSize: pageSize }).then((res) => {
      if (res.code === 0) {
        setList(res.data.records)
        setTotal(res.data.total)
        set_selectedRowKeys([])
        props.setRowSelection([])
        if (res.data.records.length === 0 && pageNum > 1) {
          setPageNum(pageNum - 1)
        }
      }
    })
  }
  useEffect(() => {
    getList()
  }, [])

  const [selectedRowKeys, set_selectedRowKeys] = useState([])
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (keys, selectedRows) => {
      set_selectedRowKeys(keys)
      props.setRowSelection(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === '超级管理员',
      // Column configuration not to be checked
      name: record.name
    })
  }
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const paginationChange = (page, pageSize) => {
    setPageNum(page)
    setPageSize(pageSize)
  }

  useEffect(() => {
    getList()
  }, [pageNum, pageSize])

  return (
    <div className={moduleStyle.tableWrap}>
      <Table
        rowKey="id"
        dataSource={list}
        columns={columns}
        rowSelection={{ ...rowSelection }}
        pagination={{
          total,
          showSizeChanger: true,
          onChange: paginationChange
        }}
        rowClassName={(record) => {
          return record.name === '超级管理员' ? 'disabled' : ''
        }}
      />
    </div>
  )
})

export default App
