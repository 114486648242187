import s from './index.module.scss'
import { useState, useEffect } from 'react'
import { Breadcrumb, Menu, Input, Button, message } from 'antd'
import Icon from '@ant-design/icons'
import { getCollectKeyword, postKeywordsCollect } from '@/api/list/collect'
const { TextArea } = Input
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  }
}
const items = [
  getItem(
    '关键词采集',
    '1',
    <Icon
      component={() => (
        <img
          src={process.env.PUBLIC_URL + '/imgs/keywords-icon.png'}
          alt="Custom Icon"
        />
      )}
    />
  )
]
function CrawlerCollect() {
  const [params, setParams] = useState({ districtKeys: [], riskKeys: [] })
  const itemRender = function (route) {
    return <span>{route.breadcrumbName}</span>
  }
  const routes = [
    {
      path: '',
      breadcrumbName: '爬虫采集'
    },
    {
      path: '',
      breadcrumbName: '关键词采集'
    }
  ]
  const update = async () => {
    await postKeywordsCollect({ ...params })
    message.success('更新成功')
  }
  const districtKeysChange = (e) => {
    const { value } = e.target
    if (value?.trim()?.length) {
      const districtKeys = value.split(/,|，/) || []
      setParams((state) => {
        return {
          ...state,
          districtKeys
        }
      })
    }
  }
  const riskKeysChange = (e) => {
    const { value } = e.target
    if (value?.trim()?.length) {
      const riskKeys = value.split(/,|，/) || []
      setParams((state) => {
        return {
          ...state,
          riskKeys
        }
      })
    }
  }
  useEffect(() => {
    getCollectKeyword().then((res) => {
      if (res.data) {
        setParams(res.data)
      }
    })
  }, [])
  return (
    <div className={s.crawlerCollectWrap}>
      <div className={s.line}></div>
      <div className={s.headBox}>
        <div className={s.left}>
          <div>
            <div className={s.title}>爬虫采集</div>
            <Menu
              style={{
                width: 200
              }}
              className={s.myMenu}
              defaultSelectedKeys={['1']}
              mode="vertical"
              theme="light"
              items={items}
            />
          </div>
        </div>
        <div className={s.right}>
          <div className={s.breadcrumb}>
            <Breadcrumb itemRender={itemRender} routes={routes} />
          </div>
          <div className={s.content}>
            <div className={s.contentTitle}>关键词采集</div>
            <div className={s.info}>
              针对抖音、快手、微博、B站等短视频平台进行关键词采集，地域词与风险词之间为or的关系，命中两组词之间的任意一组均会被采集
            </div>
            <div className={s.block}>
              <div className={s.item}>地域词</div>
              <TextArea
                rows={4}
                placeholder="请输入地域词，以逗号作为两词之间的分隔符号"
                onChange={districtKeysChange}
                value={params.districtKeys?.join(',')}
              />
            </div>
            <div className={s.block}>
              <div className={s.item}>风险词</div>
              <TextArea
                rows={4}
                placeholder="请输入风险词，以逗号作为两词之间的分隔符号"
                value={params.riskKeys?.join(',')}
                onChange={riskKeysChange}
              />
            </div>
            <div className={s.buttonWrap}>
              <Button type="primary" danger onClick={() => update()}>
                确定
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CrawlerCollect
