import http from './../axios'

/**
 * 标签分类列表
 */
export const getLableList = (params) => {
  return http().get(`/media_service/risk/discovery/label/rank/list`, { params })
}

/**
 * 标签信息
 */
export const getLableInfo = (id) => {
  return http().get(`/media_service/risk/discovery/label/info/info/${id}`)
}

/**
 * 新增标签
 */
export const addLabel = (params) => {
  return http().post(`/media_service/risk/discovery/label/info/add`, params)
}

/**
 * 编辑标签
 */
export const updateLabel = (params) => {
  return http().post(`/media_service/risk/discovery/label/info/update`, params)
}

/**
 * 删除标签
 */
export const deleteLabel = (id) => {
  return http().post(`/media_service/risk/discovery/label/info/delete/${id}`)
}

/**
 * 停止标签
 */
export const closeLabel = (id) => {
  return http().post(`/media_service/risk/discovery/label/info/close/${id}`)
}

/**
 * 开启标签
 */
export const openLabel = (id) => {
  return http().post(`/media_service/risk/discovery/label/info/open/${id}`)
}

/**
 * 新增分类
 */
export const addLabelClassify = (params) => {
  return http().post(`/media_service/risk/discovery/label/rank/add`, params)
}

/**
 * 删除分类
 */
export const deleteLabelClassify = (id) => {
  return http().post(`/media_service/risk/discovery/label/rank/delete/${id}`)
}

/**
 * 更新分类标签
 */
export const updateLabelClassify = (params) => {
  return http().post(`/media_service/risk/discovery/label/rank/update`, params)
}

/**
 * 表达式验证
 */
export const checkExpression = (params) => {
  return http().post(
    `/media_service/risk/discovery/label/info/expression/check`,
    params
  )
}

/**
 * 系统列表
 */
export const getSysList = () => {
  return http().get(`/media_service/risk/discovery/label/info/target/sys/list`)
}

/**
 * 编译
 */
export const getCompileRiskInfo = (matchType) => {
  return http().get(
    `/media_service/risk/discovery/label/info/compileRiskInfo/${matchType}`
  )
}

/**
 * 标签更新
 */
export const targetSysUpdate = (params) => {
  return http().post(
    `/media_service/risk/discovery/label/info/target/sys/update`,
    params
  )
}
