import moduleStyle from './index.module.scss'

const RecognitionComponent = (props) => {
  /**
   * @name props
   * @desc 父级参数
   * @param {number} menuActive 1-风控ID 2中台ID 3-文本
   * @param {object} detailData 视频数据
   */
  if (!props.detailData) {
    return
  }
  return (
    <div className={moduleStyle.recognition}>
      <div className={moduleStyle.title}>
        内容识别{' '}
        <span>
          {props.detailData.emotion
            ? ['', '正面', '负面', '中性'][props.detailData.emotion]
            : ''}
        </span>
      </div>
      <div className={moduleStyle.content}>
        <div className={moduleStyle.contentTag}>原文</div>
        <div
          className={moduleStyle.contentText}
          dangerouslySetInnerHTML={{
            __html: props.detailData.textConcat || '-'
          }}
        />
      </div>
      {props.menuActive !== 3 ? (
        <div className={moduleStyle.content}>
          <div className={moduleStyle.contentTag}>OCR</div>
          <div
            className={moduleStyle.contentText}
            dangerouslySetInnerHTML={{
              __html: props.detailData.ocrParagraphStr || '-'
            }}
          />
        </div>
      ) : (
        ''
      )}
      {props.menuActive !== 3 ? (
        <div className={moduleStyle.content}>
          <div className={moduleStyle.contentTag}>封面OCR</div>
          <div
            className={moduleStyle.contentText}
            dangerouslySetInnerHTML={{
              __html: props.detailData.ocrCoverContent || '-'
            }}
          />
        </div>
      ) : (
        ''
      )}
      {props.menuActive !== 3 ? (
        <div className={moduleStyle.content}>
          <div className={moduleStyle.contentTag}>ASR</div>
          <div
            className={moduleStyle.contentText}
            dangerouslySetInnerHTML={{
              __html: props.detailData.asr || '-'
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
export default RecognitionComponent
