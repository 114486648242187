import ExportJsonExcel from 'js-export-excel'

function exportExcel(props) {
  /* 
    fileName 导出文件名
    data 导出数据
    sheetFilter 导出数据字段
    sheetHeader 导出列名称
  */
  const { fileName, data, sheetFilter, sheetHeader } = props

  const sheetData = data.map((item) => {
    let obj = {}
    sheetFilter.forEach((el) => {
      obj[el] = item[el] || ''
    })
    return obj
  })

  let option = {
    fileName,
    datas: [
      {
        sheetName: 'sheet',
        sheetData,
        sheetFilter,
        sheetHeader
      }
    ]
  }

  let toExcel = new ExportJsonExcel(option)
  toExcel.saveExcel()
}

export default exportExcel
