import { useState } from 'react'
import moduleStyle from '../../index.module.scss'
import classnames from 'classnames'
import EditTagInfo from '../editTagInfo'
import sourceVerifyStore from '@/store/sourceVerify'
import { getLableInfo } from '@/api/list/riskTag'
import { generateRandomString } from '@/untils'
import { cloneDeep } from 'lodash'
import { Tooltip } from 'antd'
const TagComponent = ({ detailData, setLabelCode, title }) => {
  const tagVerifyState = sourceVerifyStore()

  const { activeCode, setActiveCode, setEditModal } = tagVerifyState

  const checkLabel = (labelCode) => {
    setActiveCode(labelCode)
    setLabelCode(labelCode)
  }
  const editTagInfo = (labelId) => {
    if (!labelId) return
    getLableInfo(labelId)
      .then((res) => {
        if (+res.code === 0) {
          const response = cloneDeep(res.data)
          if (!response.exprList.length) {
            response.exprList = [
              {
                expr: null,
                matchLocation: 2,
                inOrder: 1,
                negExprs: [
                  {
                    key: generateRandomString(),
                    negExpr: null,
                    negExprMl: 2,
                    negExprOrder: 1
                  }
                ]
              }
            ]
          }
          if (!response.allExcluExpr) {
            response.allExcluExpr = {
              expr: null,
              matchLocation: 2,
              inOrder: 1
            }
          }
          if (!response.ambiguousExpr) {
            response.ambiguousExpr = {
              expr: null,
              matchLocation: 2,
              inOrder: 1
            }
          }
          response.exprList.forEach((item) => {
            item.key = generateRandomString()
            if (item.negExprs.length) {
              item.negExprs.forEach((subItem) => {
                subItem.key = generateRandomString()
              })
            } else {
              item.negExprs = [
                {
                  key: generateRandomString(),
                  negExpr: null,
                  negExprMl: 2,
                  negExprOrder: 1
                }
              ]
            }
          })
          tagVerifyState.setLableInfo(response)
          tagVerifyState.setGroupLen(res.data?.exprList?.length || 1)
          if (res.data?.exprList?.length) {
            tagVerifyState.setGroupCollect(response.exprList)
          }
        }
        tagVerifyState.setLoading(false)
      })
      .catch((e) => {
        tagVerifyState.setLoading(false)
      })
    setEditModal(true)
  }
  return (
    <div className={moduleStyle.tagWrap}>
      <EditTagInfo />
      <div className={moduleStyle.tagTitle}>{title}</div>
      {detailData.map((item) => (
        <div
          onClick={() => checkLabel(item.labelCode)}
          className={classnames(moduleStyle.row, {
            [moduleStyle.active]: item.labelCode === activeCode
          })}
        >
          <div
            className={moduleStyle.editButton}
            onClick={() => editTagInfo(item.labelId)}
          >
            <img src="/imgs/sourceVerify/edit-icon.svg" alt="" />
            编辑
          </div>
          <div className={moduleStyle.name}>
            <img src="/imgs/sourceVerify/connection-point.svg" alt="" />
            <Tooltip placement="topLeft" title={item.labelName}>
              <span> {item.labelName}</span>
            </Tooltip>
          </div>
          <div className={moduleStyle.wordDetail}>
            {item.wordDetail.map((iv) => (
              <div className={moduleStyle.detailItem}>
                <div className={moduleStyle.word}>
                  <img src="/imgs/sourceVerify/words.svg" alt="" />
                  <Tooltip placement="topLeft" title={iv.word}>
                    <span> {iv.word}</span>
                  </Tooltip>
                </div>
                <div className={moduleStyle.hitField}>
                  <img src="/imgs/sourceVerify/local-point.svg" alt="" />
                  <Tooltip placement="topLeft" title={iv.hitField}>
                    <span> {iv.hitField}</span>
                  </Tooltip>
                </div>
                <div className={moduleStyle.hitPath}>
                  <img src="/imgs/sourceVerify/hit-path.svg" alt="" />
                  <Tooltip placement="topLeft" title={iv.hitPath}>
                    <span> {iv.hitPath}</span>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export default TagComponent
