import Cookies from 'js-cookie'

function index() {
  if (window.location.href.indexOf('/login') < 1) {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    Cookies.remove('token_time')
    window.location.href = '/login'
  }
}
export default index
