import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moduleStyle from './style/index.module.scss'
import { formatTime } from '@/untils/time'
import * as allGetFn from '@/api/list/importDataTracking'
import { Table } from 'antd'
const columns = [
  {
    width: 100,
    title: '阶段',
    dataIndex: 'title',
    render: (val) => {
      return (
        <span className={val.indexOf('验证') > -1 ? 'green' : ''}>{val}</span>
      )
    }
  },
  {
    width: 80,
    title: '时间',
    dataIndex: 'initTime'
  },
  {
    width: 600,
    title: '数据',
    dataIndex: 'dataStr',
    render: (val) => render_obj(val)
  }
]

const render_obj = (obj) => {
  const hightLight = [
    'resourceId',
    'dcResourceId',
    'videoId',
    'authorId',
    'siteId'
  ]
  const { data, nameList } = obj
  if (data) {
    let newData = {}
    if (nameList) {
      nameList.forEach((item) => {
        newData[item] = data[item]
      })
    } else {
      newData = data
    }

    return (
      <>
        {Object.keys(newData).map((item) => (
          <div
            className={
              'dataStr-box ' + (hightLight.includes(item) ? 'blod' : '')
            }
            key={item}
          >
            <span>{item}: </span>
            <span>{newData[item]}</span>
          </div>
        ))}
      </>
    )
  }
}

function Index() {
  const { state } = useLocation()
  const [list, setList] = useState([])
  const setNewData = (title, data, nameList, initTime) => {
    list.push({
      key: Math.random(),
      title,
      initTime: data[initTime] ? formatTime(data[initTime]) : '',
      dataStr: { data, nameList }
    })
    setList([...list])
  }
  async function getData() {
    await allGetFn
      .getCrawlTask({
        url: state.url,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = ['id', 'name', 'uuid', 'url', 'infoid', 'ctime']
          setNewData('采集任务', res.data, nameList, 'ctime')
        }
      })

    let importState = false
    await allGetFn
      .getCrawlResult({
        url: state.url,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'id',
            'authorId',
            'author',
            'duration',
            'url',
            'title',
            'likeNum',
            'repeatNum',
            'readNum',
            'commentNum',
            'pubTime',
            'lastTime',
            'level',
            'webUrl',
            'resourceId',
            'ctime',
            'coverUrl',
            'avatarUrl'
          ]
          if (res.data.importState) {
            setNewData('采集结果', res.data, nameList, 'lastTime')
            importState = true
          } else {
            setNewData('采集结果', { title: res.data.title })
          }
        }
      })
    if (!importState) {
      return
    }

    let resourceId = ''
    await allGetFn
      .getLoadToDbTask({
        resourceId: state.resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'resourceId',
            'uuid',
            'videoId',
            'sourceFrom',
            'infoId',
            'dateTime',
            'status',
            'errorDescribe'
          ]
          resourceId = res.data.resourceId /* 重新定义resourceId */
          setNewData('资源入库任务', res.data, nameList, 'dateTime')
        }
      })

    await allGetFn
      .getLoadToDbResult({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'resourceId',
            'videoId',
            'dateTime',
            'status',
            'errorDescribe'
          ]
          setNewData('资源入库结果', res.data, nameList, 'dateTime')
        }
      })

    await allGetFn
      .getProcessVerificationLoadtodb({
        resourceId: resourceId,
        uuid: state.uuid
      })
      .then((res) => {
        if (res.data) {
          setNewData('入库验证', res.data, '', 'createDate')
        }
      })

    let incidentId = ''
    await allGetFn
      .getIncidentBindingResult({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'resourceId',
            'infoId',
            'dateTime',
            'status',
            'desc',
            'errorDescribe',
            'incidentId'
          ]
          incidentId = res.data.incidentId
          setNewData('事件绑定结果', res.data, nameList, 'dateTime')
        }
      })

    if (incidentId) {
      await allGetFn
        .getProcessVerificationIncidentBinding({
          resourceId: resourceId,
          uuid: state.uuid,
          incidentId
        })
        .then((res) => {
          if (res.data) {
            let nameList = [
              'id',
              'keywordList',
              'titleKeywordList',
              'infoId',
              'incidentList'
            ]
            setNewData('事件绑定验证', res.data, nameList)
          }
        })
    }

    await allGetFn
      .getVideoDownloadPush({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'resourceId',
            'uuid',
            'videoId',
            'authorId',
            'sourceFrom',
            'infoId',
            'dateTime',
            'status',
            'errorDescribe',
            'createTime'
          ]
          setNewData('推送下载', res.data, nameList, 'dateTime')
        }
      })

    await allGetFn
      .getVideoDownloadTask({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'id',
            'verion',
            'level',
            'suffix',
            'url',
            'frameInterval',
            'dataId',
            'createTime',
            'noproxy',
            'ctime'
          ]
          setNewData('下载任务', res.data, nameList, 'createTime')
        }
      })

    await allGetFn
      .getVideoDownloadResult({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'id',
            'verion',
            'level',
            'suffix',
            'uuid',
            'failMessage',
            'downloadStatus',
            'dlEndTime',
            'resourcePath',
            'sourceUrl',
            'resourceFileName',
            'resourceSize',
            'dlStartTime',
            'ctime'
          ]

          setNewData('下载结果', res.data, nameList, 'dlEndTime')
        }
      })

    await allGetFn
      .getVideoParseTask({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'id',
            'resourceFileName',
            'createTime',
            'isDownload',
            'grade',
            'topic',
            'resourceSize',
            'noproxy',
            'author_id',
            'gradeName',
            'gradeExplain',
            'dlStartTime'
          ]
          setNewData('转码任务', res.data, nameList, 'createTime')
        }
      })

    await allGetFn
      .getVideoParseResult({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'id',
            'thumbPath',
            'duration',
            'height',
            'piclist',
            'videoParseStatus',
            'grade',
            'width',
            'audios',
            'gradeName',
            'gradeExplain',
            'sourceStatus',
            'status',
            'tsTime',
            'updateDate'
          ]
          setNewData('转码结果', res.data, nameList, 'tsTime')
        }
      })

    await allGetFn
      .getVideoAlgorithmTask({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'resourceId',
            'uuid',
            'videoId',
            'authorId',
            'sourceFrom',
            'infoId',
            'dateTime',
            'status',
            'errorDescribe',
            'createTime'
          ]
          setNewData('视频推算法任务', res.data, nameList, 'dateTime')
        }
      })

    await allGetFn
      .getProcessVerificationVideoProcess({
        resourceId: resourceId,
        uuid: state.uuid
      })
      .then((res) => {
        if (res.data) {
          setNewData('下载转码验证', res.data)
        }
      })

    await allGetFn
      .getUpdateTagTask({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          let nameList = [
            'resourceId',
            'uuid',
            'videoId',
            'authorId',
            'sourceFrom',
            'infoId',
            'dateTime',
            'status',
            'errorDescribe',
            'createTime'
          ]
          setNewData('算法结果返回', res.data, nameList, 'dateTime')
        }
      })

    await allGetFn
      .getUpdateTagResult({
        resourceId: resourceId,
        uuid: state.uuid,
        infoId: state.infoid
      })
      .then((res) => {
        if (res.data) {
          setNewData('标签入库', res.data)
        }
      })

    await allGetFn
      .getSynchronize({
        resourceId: resourceId,
        uuid: state.uuid
      })
      .then((res) => {
        if (res.data) {
          setNewData('数据同步验证', res.data)
        }
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className={moduleStyle.importDataTrackingDetailWrap}>
      <div className="table_wrap">
        <Table
          scroll={{ x: '100%' }}
          pagination={false}
          columns={columns}
          dataSource={list}
        />
      </div>
    </div>
  )
}

export default Index
