import http from './../axios'

/**
 * 获取列表
 */
export const getCrawlTracingList = (query) => {
  return http().get(
    `/media_service/resource/tracing/manufactured-import/crawl/crawl-tracing-list`,
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 获取导入采集【任务】记录
 */
export const getCrawlTask = function (query) {
  return http().get(
    `/media_service/resource/tracing/manufactured-import/crawl/task`,
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 获取导入采集【结果】记录
 */
export const getCrawlResult = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/crawl/result',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 资源入库追踪
 */
export const getLoadToDbTask = function (query) {
  return http().get(
    '/media_service/tracing/manufactured-import/load-to-db/task',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 资源入库追踪结果
 */
export const getLoadToDbResult = function (query) {
  return http().get(
    '/media_service/tracing/manufactured-import/load-to-db/result',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 验证入库（入库结果完成后验证）
 */
export const getProcessVerificationLoadtodb = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/process-verification/load-to-db',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 资源绑定事件追踪
 */
export const getIncidentBindingResult = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/incident-binding/result',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 验证事件绑定（事件绑定结果之后验证）
 */
export const getProcessVerificationIncidentBinding = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/process-verification/incident-binding',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 推送下载
 */
export const getVideoDownloadPush = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/video-download/push',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 下载任务
 */
export const getVideoDownloadTask = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/video-download/task',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 下载结果
 */
export const getVideoDownloadResult = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/video-download/result',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 视频转码
 */
export const getVideoParseTask = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/video-parse/task',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 视频转码结果
 */
export const getVideoParseResult = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/video-parse/result',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 视频推算法追踪
 */
export const getVideoAlgorithmTask = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/video-algorithm/task',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 验证下载转码
 */
export const getProcessVerificationVideoProcess = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/process-verification/video-process',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 数据打标签追踪
 */
export const getUpdateTagTask = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/update-tag/task',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 数据打标签追踪结果
 */
export const getUpdateTagResult = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/update-tag/result',
    {
      params: {
        ...query
      }
    }
  )
}

/**
 * 验证数据同步（最后一步打标完成后验证）
 */
export const getSynchronize = function (query) {
  return http().get(
    '/media_service/resource/tracing/manufactured-import/process-verification/synchronize',
    {
      params: {
        ...query
      }
    }
  )
}
