import http from './../axios'

/**
 * 关键词采集替换
 */
export const postKeywordsCollect = (params) => {
  return http().post(`/media_service/keyword/collect/replace/keyword`, params)
}

/**
 * 获取采集关键词
 */
export const getCollectKeyword = () => {
  return http().get(`/media_service/keyword/collect/keyword/list`)
}
