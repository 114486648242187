const defaultImg = require('./img/default.png')
function Index(e) {
  let src = e.target.src
  if (src === defaultImg) {
    e.target.onerror = null
    return
  }
  e.target.src = defaultImg
}

export default Index
