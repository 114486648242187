import moduleStyle from './index.module.scss'
import LeftCondition from './component/leftCondition'
import RightList from './component/rightList'
import TagList from './component/tagList'
import cn from 'classnames'
import TagVerifyStore from '@/store/tagVerify'

function TagVerify() {
  const tagVerifyState = TagVerifyStore()
  const { breadcrumbList, firstPaintPage } = tagVerifyState
  return (
    <div className={moduleStyle.container}>
      <div className={moduleStyle.breadcrumb}>
        <div className={moduleStyle.title}>标签数据验证</div>
        {breadcrumbList.length ? (
          <div>
            标签名称：
            <span>{breadcrumbList.join(' / ')}</span>
          </div>
        ) : null}
      </div>
      <div className={moduleStyle.content}>
        {!firstPaintPage ? <LeftCondition /> : null}
        <TagList />
        {firstPaintPage ? (
          <div className={cn(moduleStyle.emptyData)}>请选择标签</div>
        ) : null}
        {!firstPaintPage ? <RightList /> : null}
      </div>
    </div>
  )
}

export default TagVerify
