//登录页
import Login from '@/pages/login'
//首页
import HomePage from '@/pages/home'
//dsashBoard看板
import DashBoard from '@/pages/dashBoard'
//大数据
import BigData from '@/pages/bigData'
//可视化导航
import VisualNavigation from '@/pages/visualNavigation'
//导入数据追踪
import ImportDataTracking from '@/pages/monitoring-early-warning/manufactured-import-tracing'
//导入数据追踪-详情
import ImportDataTrackingDetail from '@/pages/monitoring-early-warning/manufactured-import-tracing/detail'
//菜单管理
import MenusManagement from '@/pages/systemManagement/menusManagement'
//角色管理
import RoleManagement from '@/pages/systemManagement/roleManagement'
//用户管理
import UserManagement from '@/pages/systemManagement/userManagement'
// 爬虫采集
// import CrawlerCollect from  '@/pages/functionalShow/crawlerCollect';
// 资源下载
import ResourceSearch from '@/pages/functionalShow/resourceSearch'
import CrawlerCollect from '@/pages/functionalShow/crawlerCollect'
// API
import Api from '@/pages/developmentPlatform/api'
import resourceCollectionDoc from './doc'

//风险标签
import RiskTag from '@/pages/riskTag'
//标签验证
import TagVerify from '@/pages/tagVerify'
// 资源验证
import SourceVerify from '@/pages/sourceVerify'
// 数据统计
import Statistics from '@/pages/statistics'

const routes = [
  { path: '/login', name: '登录', component: Login },
  {
    path: '/home',
    name: '首页',
    component: HomePage
  },
  {
    path: '/risk-tag',
    name: '风险标签',
    component: RiskTag,
    layout: 'CustomLayout'
  },
  {
    path: '/tag-verify',
    name: '标签验证',
    component: TagVerify,
    layout: 'CustomLayout'
  },
  {
    path: '/source-verify',
    name: '资源验证',
    component: SourceVerify,
    layout: 'CustomLayout'
  },
  {
    path: '/statistics',
    name: '数据统计',
    component: Statistics,
    layout: 'CustomLayout'
  },
  {
    path: '/dashBoard/serviceData',
    name: 'DashBoard看板',
    component: DashBoard,
    child: [
      {
        path: '/dashBoard/serviceData',
        name: '查看服务数据',
        component: DashBoard
      },
      {
        path: '/dashBoard/resourceTrack',
        name: '查看资源追踪',
        component: DashBoard
      },
      {
        path: '/dashBoard/accountTrack',
        name: '查看账号追踪',
        component: DashBoard
      }
    ]
  },
  {
    path: '/bigData',
    name: '大数据',
    component: BigData,
    child: [
      {
        path: '/bigData/cdh',
        name: 'cdh',
        component: BigData
      },
      {
        path: '/bigData/cloderaManager',
        name: 'cloderaManager',
        component: BigData
      },
      {
        path: '/bigData/streamsets',
        name: 'streamsets',
        component: BigData
      },
      {
        path: '/bigData/ceph',
        name: 'ceph',
        component: BigData
      }
    ]
  },
  // {
  //   path: "/home",
  //   name: "功能展示",
  //   component: BigData,
  //   child: [
  //     {
  //       path: "/functional-show/crawler-collect",
  //       name: "爬虫采集",
  //       component: CrawlerCollect,
  //     }
  //   ],
  // },
  {
    path: '/functional-show/crawler-collect',
    name: '爬虫采集',
    component: CrawlerCollect,
    layout: 'CustomLayout',
    child: [...resourceCollectionDoc]
  },
  {
    path: '/development-platform/api',
    name: 'api',
    component: Api,
    layout: 'AsideLayout',
    child: resourceCollectionDoc
  },
  {
    path: '/resource-search',
    name: 'resourceSearch',
    component: ResourceSearch
  },
  {
    path: '/visualNavigation',
    name: '可视化导航',
    component: VisualNavigation
  },
  {
    path: '/manufactured-import-tracing',
    name: '导入数据追踪',
    component: ImportDataTracking,
    child: [
      {
        path: '/manufactured-import-tracing/detail',
        name: '导入数据追踪详情',
        component: ImportDataTrackingDetail
      }
    ]
  },
  {
    path: '/systemManagement/menusManagement',
    name: '菜单管理',
    component: MenusManagement
  },
  {
    path: '/systemManagement/roleManagement',
    name: '角色管理',
    component: RoleManagement
  },
  {
    path: '/systemManagement/userManagement',
    name: '用户管理',
    component: UserManagement
  }
]

export default routes
