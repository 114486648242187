import Player from 'xgplayer'
import HlsPlugin from 'xgplayer-hls'
import FlvPlugin from 'xgplayer-flv'
import Mp4Plugin from 'xgplayer-mp4'
import React, { useState, useRef } from 'react'
import { Input, Button, Radio, List, message, Row, Col, Typography } from 'antd'
import axios from 'axios'
import { platforms, resourceSearchRespKeyToTitle } from '@/constant'
import './search.scss'

const SearchComponent = () => {
  const player = useRef(null)
  const [url, setUrl] = useState(
    'https://www.douyin.com/discover?modal_id=7298309862943968523'
  )
  const [radioValue, setRadioValue] = useState(10000)
  const [searchResults, setSearchResults] = useState([])
  const [isBtnLoading, setBtnLoading] = useState(false)
  const [, setAvgTime] = useState('')
  const [btnText, setBtnText] = useState('搜索')
  const [videoList, setVideoList] = useState([])

  const handleSearch = async () => {
    try {
      setBtnLoading(true)
      setBtnText(`搜索中`)
      const response = await axios.get(
        `${process.env.REACT_APP_BFF_DOMAIN}/resource-search`,
        {
          params: {
            url,
            siteId: radioValue
          }
        }
      )

      if (response.data.data.code === 200) {
        const resp = response.data.data.data[0]
        const listReslut = []
        Object.keys(resourceSearchRespKeyToTitle).forEach((key) => {
          if (
            Object.hasOwnProperty.call(resp, key) &&
            !Array.isArray(resp[key]) &&
            resp[key]
          ) {
            listReslut.push({
              title: resourceSearchRespKeyToTitle[key].name,
              desc: resp[key] ? resp[key] : '--'
            })
          }
        })
        setSearchResults(listReslut)
        if (resp.videos && resp.videos.length) {
          setVideoList(resp.videos)
        }
      } else {
        message.error({
          type: 'error',
          content: response.data.data.msg
        })
      }
    } catch (error) {
      message.error({
        type: 'error',
        content: `查询失败: ${error}`
      })
    } finally {
      setBtnLoading(false)
      setBtnText('搜 索')
    }
  }

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
    const platform = platforms.find((p) => p.siteId === e.target.value)
    setAvgTime(platform.acquisitionTime)
  }

  const radios = platforms.map((platform) => {
    return (
      <Radio value={platform.siteId} key={platform.siteId}>
        {platform.name}
      </Radio>
    )
  })

  const videos = videoList
    .filter((video) => video.url !== null)
    .map((item) => {
      return (
        <video src={item.url} key={item.videoId} controls width="200px"></video>
      )
    })
  const initPlayer = (url) => {
    const options = {
      url,
      plugin: FlvPlugin
    }

    if (url.includes('.mp4')) {
      options.plugin = Mp4Plugin
    }

    if (url.includes('.m3u8')) {
      options.plugin = HlsPlugin
    }

    if (player !== null) {
      player.current.destroy()
      player.current = null
    }
    player.current = new Player({
      id: 'videoPlayer',
      url: url,
      autoplay: false,
      plugins: [options.plugin],
      cssFullscreen: false,
      pip: true,
      mini: false,
      download: true,
      playbackRate: []
    })
  }
  console.log(initPlayer)

  const getListItem = (item) => {
    return (
      <>
        <Typography.Text strong>{item.title}:</Typography.Text> {item.desc}
      </>
    )
  }

  return (
    <div className="page-search">
      <div>
        <Row gutter={16} justify="center">
          <Col span={18}>
            <Input
              className="page-search_input"
              size="large"
              placeholder="请输入要搜寻的视频链接"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Button
              className="page-search_btn"
              block
              size="large"
              type="primary"
              loading={isBtnLoading}
              onClick={handleSearch}
            >
              {btnText}
            </Button>
          </Col>
        </Row>

        <Row gutter={16} justify="center" style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Radio.Group value={radioValue} onChange={handleRadioChange}>
              {radios}
            </Radio.Group>
          </Col>
        </Row>

        <Row gutter={16} justify="center" style={{ marginTop: '16px' }}>
          <Col span={24}>
            <List
              dataSource={searchResults}
              renderItem={(item) => <List.Item>{getListItem(item)}</List.Item>}
            />
          </Col>
        </Row>

        <Row gutter={16} justify="center" style={{ marginTop: '16px' }}>
          <Col span={24}>{videos}</Col>
        </Row>
      </div>
    </div>
  )
}

export default SearchComponent
