import axios from 'axios'
import Cookies from 'js-cookie'
import { message } from 'antd'
import logout from '@/untils/logout'

const http = (
  headers = {
    'Content-Type': 'application/json'
  }
) => {
  const access_token = Cookies.get('access_token')
  headers.Authorization = access_token ? 'Bearer ' + access_token : ''
  const instance = axios.create({
    baseURL: '/Api',
    headers: headers,
    timeout: 600000
  })
  instance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      const { status, data, headers } = response
      if (+status === 200) {
        if (headers['content-disposition']) {
          window.excelFileName = headers['content-disposition'].match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          )[1]
        } else {
          window.excelFileName = null
        }
        if (data.code !== 0 && data.message) {
          message.error(data.message)
          return Promise.reject(data)
        }
        return Promise.resolve(data)
      } else if (+status === 401) {
        message.error(data.message)
        logout()
      } else {
        message.error(data.message)
        return Promise.reject(data)
      }
    },
    (error) => {
      if (error?.response?.status === 401) {
        message.warning('未登录')
        logout()
      } else {
        message.warning(error?.response?.data?.message || '系统异常')
        return Promise.reject(error.response.data)
      }
    }
  )
  return instance
}

export default http
