import blockStyle from './pageBlock.module.scss'
import Block from '../block'
import recoilStore from '@/recoilStore'
import { useRecoilValue } from 'recoil'

function PageBlock(props) {
  const { blockData } = props
  const blockItemMap = useRecoilValue(recoilStore.docBlockItemMap)
  const blockClassName = () => {
    const styleArray = []
    styleArray.push(blockStyle['page-block'])
    return styleArray.join(' ')
  }

  const elements = blockData[blockData.block_type_name].elements
  const header = elements.map((element, index) => {
    return (
      <span className={blockStyle.header} key={index}>
        {element?.text_run?.content}
      </span>
    )
  })

  const virtualChildren = blockData.children.map((childId) => {
    return (
      <div
        id={`virtual-child-render-${childId}`}
        key={`v_${childId}`}
        className="virtual-child-render"
        data-child-id={`virtual-child-render-${childId}`}
      >
        <Block key={childId} blockData={blockItemMap[childId]}></Block>
      </div>
    )
  })

  return (
    <div id={blockData.block_id} className={blockClassName()}>
      <div className={blockStyle['header-container']}>{header}</div>
      <div className="page-block-children">{virtualChildren}</div>
    </div>
  )
}
export default PageBlock
