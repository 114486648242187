import moduleStyle from './index.module.scss'
import { Input, Select, Button, message, Tooltip, InputNumber } from 'antd'
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  ReloadOutlined
} from '@ant-design/icons'
import riskTagStore from '@/store/riskTag'
import { checkExpression } from '@/api/list/riskTag'
import { generateRandomString } from '@/untils'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import cn from 'classnames'

function Group({ index, groupLen, uniqKey }) {
  const riskTagState = riskTagStore()
  const tagInfo = riskTagStore((state) => state.tagInfo)
  const [validateExpression, setValidateExpression] = useState({})

  const addNegExprs = (groupIndex) => {
    const snapTagInfo = cloneDeep(tagInfo)
    if (snapTagInfo?.exprList?.[groupIndex]?.negExprs?.length) {
      snapTagInfo.exprList[groupIndex].negExprs.push({
        key: generateRandomString(),
        negExpr: null,
        negExprMl: 2,
        negExprOrder: 1
      })
    }
    riskTagState.setTagInfo(snapTagInfo)
  }

  const deleteGroup = (unikey, key) => {
    const snapTagInfo = cloneDeep(tagInfo)
    snapTagInfo.exprList = snapTagInfo.exprList.filter(
      (item) => item.key !== key
    )
    riskTagState.deleteGroupCollect(unikey)
    riskTagState.setTagInfo(snapTagInfo)
  }

  const delNegExprs = (groupIndex, negExprsIndex) => {
    const snapTagInfo = cloneDeep(tagInfo)
    if (snapTagInfo?.exprList?.[groupIndex]?.negExprs?.length) {
      snapTagInfo.exprList[groupIndex].negExprs.splice(negExprsIndex, 1)
    }
    riskTagState.setTagInfo(snapTagInfo)
  }

  const changeMatchLocation = (value, i) => {
    const snapTagInfo = cloneDeep(tagInfo)
    if (i >= 0) {
      //排除词
      snapTagInfo.exprList[index].negExprs[i].negExprMl = value
      if (value !== 5) {
        snapTagInfo.exprList[index].negExprs[i].matchGap = null
      }
    } else {
      //关键词
      snapTagInfo.exprList[index].matchLocation = value
      if (value !== 5) {
        snapTagInfo.exprList[index].matchGap = null
      }
    }
    riskTagState.setTagInfo(snapTagInfo)
  }

  const changeGap = (value, i) => {
    const snapTagInfo = cloneDeep(tagInfo)
    if (i >= 0) {
      //排除词
      snapTagInfo.exprList[index].negExprs[i].matchGap = value
    } else {
      //关键词
      snapTagInfo.exprList[index].matchGap = value
    }
    riskTagState.setTagInfo(snapTagInfo)
  }

  const changeOrder = (value, i) => {
    const snapTagInfo = cloneDeep(tagInfo)
    if (i >= 0) {
      snapTagInfo.exprList[index].negExprs[i].negExprOrder = value
    } else {
      snapTagInfo.exprList[index].inOrder = value
    }

    riskTagState.setTagInfo(snapTagInfo)
  }

  const changeKeyWord = (e, i, key) => {
    const snapTagInfo = cloneDeep(tagInfo)
    if (i >= 0) {
      snapTagInfo.exprList[index].negExprs[i].negExpr = e.target.value
    } else {
      snapTagInfo.exprList[index].expr = e.target.value
    }
    riskTagState.setTagInfo(snapTagInfo)
    setValidateExpression((state) => ({ ...state, [key]: undefined }))
  }

  const validateExpressionFun = (key, expr) => {
    if (!expr) {
      message.warning('请输入表达式')
      return
    }
    checkExpression({ text: expr }).then((res) => {
      if (res.data.isValid === 1) {
        setValidateExpression((state) => ({ ...state, [key]: true }))
      } else {
        setValidateExpression((state) => ({ ...state, [key]: false }))
      }
    })
  }

  return (
    <>
      <div className={moduleStyle.group}>
        <div className={moduleStyle.groupTop}>
          <div>
            词组{index + 1}
            <span className={moduleStyle.questionTip}>
              <Tooltip
                placement="top"
                title="逻辑表达式支持”and“、”or“、”（）“ 例如：（北京 and 天津）or （上海 and 重庆）"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          </div>
          <div className={moduleStyle.operation}>
            {groupLen > 1 ? (
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() =>
                  deleteGroup(uniqKey, tagInfo?.exprList?.[index]?.key)
                }
              ></Button>
            ) : null}
            {/* {isFole ? (
              <Button type="primary" icon={<downOutlined />}></Button>
            ) : (
              <Button type="primary" icon={<UpOutlined />}></Button>
            )} */}
          </div>
        </div>
        <div className={moduleStyle.foldWrap}>
          <div className={moduleStyle.keywords}>
            <div className={moduleStyle.blockTitle}>
              <div>
                关键词
                <span className={moduleStyle.questionTip}>
                  <Tooltip
                    placement="top"
                    title="逻辑表达式支持”and“、”or“、”（）“ 例如：（北京 and 天津）or （上海 and 重庆）"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              </div>
              <div className={moduleStyle.filterArea}>
                <div className={moduleStyle.filterItem}>
                  <div className={moduleStyle.filterTitle}>匹配位置</div>
                  <Select
                    defaultValue={2}
                    style={{ width: 100 }}
                    value={tagInfo?.exprList?.[index]?.matchLocation}
                    options={[
                      { value: 2, label: '整句' },
                      { value: 1, label: '半句' },
                      { value: 0, label: '紧挨' },
                      { value: 3, label: '段落' },
                      { value: 4, label: '不限' },
                      { value: 5, label: '自定义' }
                    ]}
                    onChange={changeMatchLocation}
                  />
                </div>

                {tagInfo?.exprList?.[index]?.matchLocation === 5 ? (
                  <div className={moduleStyle.filterItem}>
                    <InputNumber
                      value={tagInfo?.exprList?.[index]?.matchGap}
                      min={1}
                      placeholder="自定义距离"
                      onChange={changeGap}
                    />
                  </div>
                ) : null}

                <div className={moduleStyle.filterItem}>
                  <div className={moduleStyle.filterTitle}>匹配顺序</div>
                  <Select
                    defaultValue={1}
                    options={[
                      { value: 1, label: '有序' },
                      { value: 0, label: '无序' }
                    ]}
                    value={tagInfo?.exprList?.[index]?.inOrder}
                    onChange={changeOrder}
                  />
                </div>
              </div>
            </div>
            <div className={moduleStyle.inputArea}>
              <Input.TextArea
                rows={5}
                placeholder="请输入关键词"
                value={tagInfo?.exprList?.[index]?.expr}
                onChange={(e) => changeKeyWord(e, undefined, uniqKey)}
              ></Input.TextArea>

              {validateExpression[uniqKey] === undefined ? (
                <div
                  onClick={() =>
                    validateExpressionFun(
                      uniqKey,
                      tagInfo?.exprList?.[index]?.expr
                    )
                  }
                  className={moduleStyle.validateExpression}
                >
                  验证表达式
                </div>
              ) : null}
              {validateExpression[uniqKey] === true ? (
                <div
                  onClick={() =>
                    validateExpressionFun(
                      uniqKey,
                      tagInfo?.exprList?.[index]?.expr
                    )
                  }
                  className={cn(
                    moduleStyle.validateExpression,
                    moduleStyle.correct
                  )}
                >
                  未发现错误
                  <ReloadOutlined />
                </div>
              ) : null}
              {validateExpression[uniqKey] === false ? (
                <div
                  onClick={() =>
                    validateExpressionFun(
                      uniqKey,
                      tagInfo?.exprList?.[index]?.expr
                    )
                  }
                  className={cn(
                    moduleStyle.validateExpression,
                    moduleStyle.wrong
                  )}
                >
                  表达式错误
                  <ReloadOutlined />
                </div>
              ) : null}
            </div>
          </div>
          {tagInfo?.exprList?.[index]?.negExprs?.map((item, i) => (
            <div className={moduleStyle.excludeWords} key={item.key}>
              <div className={moduleStyle.blockTitle}>
                <div className={moduleStyle.excludeTitleWrap}>
                  <div className={moduleStyle.excludeTitle}>
                    排除词{i + 1}
                    <span className={moduleStyle.questionTip}>
                      <Tooltip
                        placement="top"
                        title="逻辑表达式支持”and“、”or“、”（）“ 例如：（北京 and 天津）or （上海 and 重庆）"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  </div>
                  <Button
                    style={{ marginRight: 10 }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => addNegExprs(index, i)}
                  ></Button>
                  {tagInfo?.exprList?.[index]?.negExprs?.length > 1 ? (
                    <Button
                      type="primary"
                      onClick={() => delNegExprs(index, i)}
                      icon={<DeleteOutlined />}
                    ></Button>
                  ) : null}
                </div>
                <div className={moduleStyle.filterArea}>
                  <div className={moduleStyle.filterItem}>
                    <div className={moduleStyle.filterTitle}>匹配位置</div>
                    <Select
                      defaultValue={2}
                      style={{ width: 100 }}
                      value={
                        tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExprMl
                      }
                      options={[
                        { value: 2, label: '整句' },
                        { value: 1, label: '半句' },
                        { value: 0, label: '紧挨' },
                        { value: 3, label: '段落' },
                        { value: 4, label: '不限' },
                        { value: 5, label: '自定义' }
                      ]}
                      onChange={(value) => changeMatchLocation(value, i)}
                    />
                  </div>
                  {tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExprMl ===
                  5 ? (
                    <div className={moduleStyle.filterItem}>
                      <InputNumber
                        placeholder="自定义距离"
                        value={
                          tagInfo?.exprList?.[index]?.negExprs?.[i]?.matchGap
                        }
                        onChange={(value) => changeGap(value, i)}
                        min={1}
                      />
                    </div>
                  ) : null}

                  <div className={moduleStyle.filterItem}>
                    <div className={moduleStyle.filterTitle}>匹配顺序</div>
                    <Select
                      defaultValue={1}
                      value={
                        tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExprOrder
                      }
                      options={[
                        { value: 1, label: '有序' },
                        { value: 0, label: '无序' }
                      ]}
                      onChange={(value) => changeOrder(value, i)}
                    />
                  </div>
                </div>
              </div>
              <div className={moduleStyle.inputArea}>
                <Input.TextArea
                  rows={5}
                  placeholder="请输入排除词表达式"
                  value={tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExpr}
                  onChange={(e) => changeKeyWord(e, i, item.key)}
                ></Input.TextArea>
                {validateExpression[item.key] === undefined ? (
                  <div
                    onClick={() =>
                      validateExpressionFun(
                        item.key,
                        tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExpr
                      )
                    }
                    className={moduleStyle.validateExpression}
                  >
                    验证表达式
                  </div>
                ) : null}
                {validateExpression[item.key] === true ? (
                  <div
                    onClick={() =>
                      validateExpressionFun(
                        item.key,
                        tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExpr
                      )
                    }
                    className={cn(
                      moduleStyle.validateExpression,
                      moduleStyle.correct
                    )}
                  >
                    未发现错误
                    <ReloadOutlined />
                  </div>
                ) : null}
                {validateExpression[item.key] === false ? (
                  <div
                    onClick={() =>
                      validateExpressionFun(
                        item.key,
                        tagInfo?.exprList?.[index]?.negExprs?.[i]?.negExpr
                      )
                    }
                    className={cn(
                      moduleStyle.validateExpression,
                      moduleStyle.wrong
                    )}
                  >
                    表达式错误
                    <ReloadOutlined />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Group
