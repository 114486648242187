export const platform = {
  10000: 'douyin',
  10001: 'xigua',
  10012: 'haokan',
  10017: 'weibo',
  10030: 'toutiao',
  10024: 'toutiao',
  10025: 'kuaishou',
  10103: 'bilibili',
  10029: 'xiaohongshu'
}
